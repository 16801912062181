import React from 'react'
import { Button } from 'react-bootstrap'

export const CancelButton = ({text='Cancel', variant='secondary', icon, size='sm', onClick, className='', style}) => {
  return (
    <Button variant={variant} onClick={onClick} size={size} className={className} style={style}>
      {icon} {text}
    </Button>
  )
}

// import React from 'react'
// import { Button } from 'react-bootstrap'

// export const CancelButton = ({text, onClick}) => 
//   <Button variant="secondary" onClick={onClick}>
//     {text || 'Cancel'}
//   </Button>
