import React, { useState, useEffect, useContext } from 'react'
import { Modal, Form, Alert, Row, Col } from 'react-bootstrap'
import { TrimTo, TextInput, CancelButton, SubmitButton, LinkButton } from '../widgets'
import { toast } from 'react-toastify'
import { apiPost, apiPut } from '../../libs/apiLib'
import { useNavigate } from 'react-router-dom'
import { GlobalContext } from '../helpers'
import { FaExternalLinkAlt, FaMinusSquare, FaPaste, FaSortAlphaDown } from 'react-icons/fa'

export const EditWatchlist = ({ watchlist }) => {
    const navigate = useNavigate()
    const { gState } = useContext(GlobalContext)
    const [state, setState] = useState({
        ...watchlist, symbols: watchlist.tickers?.filter(x => x).join(' '),
        error: ''
    })

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.quantity, state.price, state.fee])

    const onChange = ({ target }) => {
        let { name, type, value } = target
        if (type === 'number') value = +value
        setState({ ...state, [name]: value, error: '' });
    }

    const validate = () => {
        return true
    }

    const onSubmit = async () => {
        setState({ ...state, working: true })
        let { _id, name, category, symbols, sourceUrl, description } = state
        let watchlist = { name, category, tickers: symbols.trim().split(/\s+|\s*,\s*|\s*\+\s*/), sourceUrl, description, userId: gState.loginUser?._id }
        let result = _id ? (await apiPut('watchlist', _id, watchlist)) : (await apiPost('watchlist', watchlist))
        if (result && !result.error) {
            toast('Watchlist updated successfully')
            setState({ ...state, working: false })
            navigate(-1);
        }
        else {
            setState({ ...state, error: result?.error || 'Server error', working: false })
        }
    }

    const onClose = () => {
        navigate(-1)
    }

    const onSource = () => {
        window.open(state.sourceUrl)
    }

    const onDedup = () => {
        var symbols = [...new Set(state.symbols.trim().split(/\s+|\+|,|;/))].join(' ')
        setState({...state, symbols})
    }

    const onSort = () => {
        var symbols = [...state.symbols.trim().split(/\s+|\+|,|;/)].sort().join(' ')
        setState({...state, symbols})
    }

    const onPaste = async (e) => {
        e.preventDefault()
        let text = await navigator.clipboard.readText()
        if (!text) return
        let table = []
        let lines = text.split(/\r?\n/)
        lines.forEach(x => {
            table.push(x.split('\t'))
        })
        let n = 0
        let skip = 0
        if (table.length > 0 && table[0].includes('Symbol')) {
            n = table[0].findIndex(x => x === 'Symbol')
            skip = 1
        }
        let symbols = state.symbols?.trim().split(/\s+|\s*,\s*|\s*\+\s*/) || []
        table.forEach((x, i) => {
            if (i >= skip) {
                symbols.push(x[n])
            }
        })
        setState({...state, symbols: [...new Set(symbols)].filter(x => x).join(' ')})
    }

    return (
        <Modal show={true} backdrop="static" size="xl" keyboard={false}>
            <Form>
                <Modal.Header>
                    <Modal.Title>{watchlist._id ? 'Edit' : 'Add'} watchlist</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {state.error && <Alert variant="danger"><TrimTo text={state.error} to={200} /></Alert>}

                    <Row>
                        <Col md={6}><TextInput header="Name" name="name" value={state.name} onChange={onChange} /></Col>
                        <Col md={6}><TextInput header="Category" name="category" value={state.category} onChange={onChange} /></Col>
                    </Row>
                    {state.sourceUrl?.startsWith('http') && <LinkButton icon={<FaExternalLinkAlt />} onClick={onSource} className="float-end" />}
                    <TextInput header="Source url" name="sourceUrl" value={state.sourceUrl} onChange={onChange} />
                    <TextInput as="textarea" rows={3} header="Description" name="description" value={state.description} onChange={onChange} />
                    <div className="float-end">
                        <LinkButton icon={<FaSortAlphaDown />} text="Sort" onClick={onSort} />
                        <LinkButton icon={<FaMinusSquare />} text="Dedup" onClick={onDedup} />
                        <LinkButton icon={<FaPaste />} text="Paste table with symbols" onClick={onPaste} className="pe-0" />
                    </div>
                    <TextInput as="textarea" rows={6} header="Symbols" name="symbols" value={state.symbols} onChange={onChange} />
                </Modal.Body>
                <Modal.Footer>
                    <CancelButton onClick={onClose} />
                    <SubmitButton disabled={!validate()} working={state.working} onClick={onSubmit} className="ms-2" />
                </Modal.Footer>
            </Form>
        </Modal>
    );
}
