import React, { useState } from 'react'
import { useInterval, LinkButton, Checkbox, MyPopover, Pin } from '../widgets'
import { differenceInSeconds } from 'date-fns'
import { FaBars, FaSync } from 'react-icons/fa'
// import { useTabActive } from '../widgets'

let _autoRefreshStart = new Date()
export const AutoRefresh = ({ id, working, icon, doRefresh, dropdown, variant = 'outline-primary' }) => {
    const prefix = 'AutoRefresh_' + id
    // const isTabActive = useTabActive()
    const [state, setState] = useState({
        pinRefresh: JSON.parse(localStorage.getItem(`${prefix}_pinRefresh`)),
        autoRefresh: JSON.parse(localStorage.getItem(`${prefix}_autoRefresh`)),
        autoRefreshInterval: JSON.parse(localStorage.getItem(`${prefix}_autoRefreshInterval`) || 600),
        autoRefreshIn: '',
    })

    useInterval(() => {
        const dt = new Date()
        const day = dt.getDay()
        const hour = dt.getHours()
        if (day === 0 || day === 6 || hour < 8 || hour >= 17) {
            if (state.autoRefresh) setState({ ...state, autoRefresh: false })
        } 
        else if (!state.autoRefresh) {
            if (id === "myHoldings") {
                setState({...state, autoRefresh: true})
                onRefresh()
            }
        }
        else if (!working) {
            const secLeft = state.autoRefreshInterval - differenceInSeconds(new Date(), _autoRefreshStart)
            const min = Math.floor(secLeft / 60)
            const sec = secLeft % 60
            const autoRefreshIn = min > 0 ? (min + ':' + sec) : sec
            if (secLeft < 1) {
                onRefresh()
            }
            else setState({ ...state, autoRefreshIn })
        }
    }, 1000)

    const onRefresh = () => {
        _autoRefreshStart = new Date()
        doRefresh()
    }

    const onSet = (name, value) => {
        localStorage.setItem(`${prefix}_${name}`, JSON.stringify(value))
        setState({ ...state, [name]: value })
    }

    const onToggle = (name) => {
        localStorage.setItem(`${prefix}_${name}`, JSON.stringify(!state[name]))
        setState({ ...state, [name]: !state[name] })
    }

    const onAutoRefresh = () => {
        if (!state.autoRefresh) onRefresh()
        localStorage.setItem(`${prefix}_autoRefresh`, JSON.stringify(!state.autoRefresh))
        setState({ ...state, autoRefresh: !state.autoRefresh })
    }

    const { autoRefresh, autoRefreshInterval, autoRefreshIn } = state
    return (
        <>
            {state.pinRefresh && <LinkButton variant={variant} onClick={onRefresh} icon={<FaSync className={working ? 'spinner' : ''} />} text={autoRefresh && autoRefreshIn} className="ms-2" />}
            <MyPopover icon={icon || <FaBars />} variant={variant} className="ms-2 me-0">
                {/* {!embedded && <LinkButton variant="outline-primary" text="Reload" onClick={e => window.location.reload()} className="float-end me-0" />} */}
                <Pin pinned={state.pinRefresh} onClick={e => onToggle('pinRefresh')}><LinkButton variant="primary" icon={<FaSync className={working ? 'spinner' : ''} />} text={!autoRefreshIn ? 'Refresh quotes' : 'Refresh in ' + autoRefreshIn} onClick={onRefresh} /></Pin>
                <div className="nowrap my-2">
                    <Checkbox label="Auto refresh every" checked={!!autoRefresh} onCheck={onAutoRefresh} />
                    <div className="ps-4">
                    <Checkbox type="radio" label="1m" checked={autoRefreshInterval === 60} onCheck={e => onSet('autoRefreshInterval', 60)} />
                    <Checkbox type="radio" label="5m" checked={autoRefreshInterval === 60*5} onCheck={e => onSet('autoRefreshInterval', 60*5)} />
                    <Checkbox type="radio" label="10m" checked={autoRefreshInterval === 60*10} onCheck={e => onSet('autoRefreshInterval', 60*10)} />
                    </div>
                    {/* <div className="ps-4">
                        <span className="me-2">every</span>
                        <Checkbox type="radio" label="1 m" checked={autoRefreshInterval === 60} onCheck={e => onSet('autoRefreshInterval', 60)} />
                        <Checkbox type="radio" label="10 m" checked={autoRefreshInterval === 600} onCheck={e => onSet('autoRefreshInterval', 600)} />
                    </div> */}
                </div>
                {dropdown}
            </MyPopover>
        </>
    )
}
