import { useState, Fragment } from 'react'
import { toCurrency, toPercent, toNum } from "../../libs/utilLib"
import { LinkButton, ThSort, Ticker } from "../widgets"
import { orderBy } from 'lodash'

export const ReportTrades = ({ items }) => {
    const prefix = 'ReportTrades'
    const [state, setState] = useState({
        sortBy: JSON.parse(localStorage.getItem(`${prefix}_sortBy`)) || 'dateSold',
        sortDir: JSON.parse(localStorage.getItem(`${prefix}_sortDir`)) || 'asc',
    })

    const onSort = (sortBy) => {
        const sortDir = sortBy === state.sortBy ? (state.sortDir === 'desc' ? 'asc' : 'desc') : 'asc'
        localStorage.setItem(`${prefix}_sortBy`, JSON.stringify(sortBy))
        localStorage.setItem(`${prefix}_sortDir`, JSON.stringify(sortDir))
        setState({ ...state, sortBy, sortDir })
    }

    if (!items?.length) return null
    const { sortBy, sortDir } = state
    if (sortBy && sortDir) items = orderBy(items, [sortBy], [sortDir])
    return (
        <Fragment>
            <tr>
                <th>#</th>
                <ThSort id="qty" text="Shares sold" sortBy={sortBy} sortDir={sortDir} onSort={onSort}/>
                <ThSort id="symbol" text="Symbol" sortBy={sortBy} sortDir={sortDir} onSort={onSort} />
                <ThSort id="dateAcquired" text="Date acquired" sortBy={sortBy} sortDir={sortDir} onSort={onSort} />
                <ThSort id="dateSold" text="Date sold" sortBy={sortBy} sortDir={sortDir} onSort={onSort} />
                <ThSort id="proceeds" text="Proceeds" sortBy={sortBy} sortDir={sortDir} onSort={onSort} className="text-end" />
                <ThSort id="cost" text="Cost" sortBy={sortBy} sortDir={sortDir} onSort={onSort} className="text-end" />
                <ThSort id="buyPrice" text="Buy price" sortBy={sortBy} sortDir={sortDir} onSort={onSort} className="text-end" />
                <ThSort id="sellPrice" text="Sell price" sortBy={sortBy} sortDir={sortDir} onSort={onSort} className="text-end" />
                <ThSort id="holdDays" text="Hold days" sortBy={sortBy} sortDir={sortDir} onSort={onSort} className="text-end" />
                <ThSort id="winPercent" text="Gain %" sortBy={sortBy} sortDir={sortDir} onSort={onSort} className="text-end" />
                <ThSort id="winAmount" text="Gain $" sortBy={sortBy} sortDir={sortDir} onSort={onSort} className="text-end" />
            </tr>
            {items.map((z, k) => <tr key={k}>
                <td width="25"><LinkButton text={k + 1} to={`/chart/${z.symbol}`} target="_blank" className="p-0" /></td>
                <td>{toNum(z.qty, 2, true)} {z.sumCount > 1 && <>[{z.sumCount} trades]</>}</td>
                <td><Ticker symbol={z.symbol}/></td>
                <td>{z.dateAcquired}</td>
                <td>{z.dateSold}</td>
                <td className="text-end">{toNum(z.proceeds) || '0'}</td>
                <td className="text-end">{toNum(z.cost) || '0'}</td>
                <td className="text-end">{toNum(z.buyPrice)}</td>
                <td className="text-end">{toNum(z.sellPrice)}</td>
                <td className="text-end">{toNum(z.holdDays, 0)}</td>
                <td className={`text-end ${z.winAmount < 0 ? 'red' : ''}`}>{toPercent(z.winPercent, 1) || '$0'}</td>
                <td className={`text-end ${z.winAmount < 0 ? 'red' : ''}`}>{toCurrency(z.winAmount, 2, 2) || '$0'}</td>
            </tr>)}
        </Fragment>
    )
}