import React, { useState, useRef } from 'react'
import { Popover, Overlay, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { LinkButton } from '.'

export const MyPopover = ({ button, variant = "outline-secondary", icon, header = "", placement = "bottom", tooltip, children, disabled, className, style, btnStyle }) => {
    const [show, setShow] = useState(false)
    const [target, setTarget] = useState(null);
    const ref = useRef(null);

    const startClick = (e) => {
        e.preventDefault()
        e.stopPropagation()
        if (disabled) return
        setShow(true)
        setTarget(e.target)
    }

    const endClick = () => {
        setShow(false)
    }

    const renderTooltip = <Tooltip id="button-tooltip" className={!tooltip && "d-none"}>{tooltip}</Tooltip>

    return (
        <span ref={ref} style={{ color: 'black' }}>
            <OverlayTrigger placement="auto" flip overlay={renderTooltip}>
                <span onClickCapture={startClick} className="c-pointer">
                    {button || <LinkButton variant={variant} size="sm" className={className} style={btnStyle}>{icon}</LinkButton>}
                </span>
            </OverlayTrigger>
            <Overlay placement={placement} show={show} target={target} container={ref.current} rootClose rootCloseEvent="click" onHide={endClick}>
                <Popover id="mypopover" style={{ backgroundColor: 'azure', margin: '0!important', zIndex:10000 }} className="d-inline-block">
                    {header && <Popover.Header as="h3">{header}</Popover.Header>}
                    <Popover.Body onClick={endClick} className="nowrap" style={style}>
                        {children}
                    </Popover.Body>
                </Popover>
            </Overlay>
        </span>
    )
}

// import React from 'react'
// import { Popover, OverlayTrigger } from 'react-bootstrap'
// import { LinkButton } from '.'

// export const MyPopover = ({button, icon, content, title, children, className}) => {
//     const popover = (
//         <Popover id="popover">
//             <Popover.Title>{title}</Popover.Title>
//             <Popover.Content>
//                 {content || children}
//             </Popover.Content>
//         </Popover>
//     )

//     return (
//         <OverlayTrigger trigger="click" placement="bottom" overlay={popover}>
//             {button || <LinkButton variant="outline-secondary" size="sm" className={className}>{icon}</LinkButton>}
//         </OverlayTrigger>
//     )
// }
