import { useState, useEffect, useContext } from 'react'
import { withAuthenticator } from '@aws-amplify/ui-react'
import { useParams } from 'react-router-dom'
import { apiGetOne } from '../../libs/apiLib'
import { Loading, Unauthorized } from '../widgets'
import { Transactions } from '.'
import { Container } from 'react-bootstrap'
import { GlobalContext } from '../helpers'

const TransactionsPage = () => {
  const { userId, id: accountId } = useParams()
  const { gState } = useContext(GlobalContext)
  const [state, setState] = useState({
    loading: true,
    user: null,
    account: null
  })

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchData = async () => {    
    let user = null
    let account = null
    if (userId) {
      user = await apiGetOne({ model: 'user', filter: { _id: userId } })
    } else {
      account = await apiGetOne({ model: 'account', filter: { _id: accountId } })
    }
    setState({ ...state, user, account, loading: false })
    gState.onSet('hideTopbar', true)
  }

  if (state.loading) return <Loading /> 
  document.title = `Transactions [${userId ? state.user.username : state.account.name}] - ${global.appName}`
  if (!gState.isAdmin() && gState.loginUser?._id !== (userId || state.account.userId)) return <Unauthorized />
  return (
    <Container fluid>
      <Transactions user={state.user} account={state.account}/>
    </Container>
  )
}

export default withAuthenticator(TransactionsPage)
