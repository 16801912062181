import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { DivHtml, LinkButton, TextInput } from '../widgets'

export const AccountsReportModal = ({ report, size = 'xl', onOK, onCancel }) => {
    const [state, setState] = useState({
        to: report.to,
        message: ''
    })

    const onChange = ({ target }) => {
        let value = target.type === 'number' ? +target.value : target.value
        setState({ ...state, [target.name]: value, error: '' })
    }

    const html = (state.message ? `<p>${state.message}</p>` : '') + report.html
    return (
        <Modal show={true} size={size} onHide={onCancel}>
            <Modal.Header closeButton>
                <Modal.Title>{report.subject}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <TextInput header="Send report to emails:" name="to" value={state.to} onChange={onChange}/>
                <TextInput header="Additional message:" name="message" value={state.message} onChange={onChange}/>
                <h4 className="mt-3">Report to be sent</h4>
                <DivHtml html={html} className="mb-2"/>
            </Modal.Body>

            <Modal.Footer>
                <LinkButton variant="secondary" onClick={onCancel} text="Cancel" />
                <LinkButton variant="primary" onClick={e => onOK({...report, to: state.to, html})} text="Send now" style={{ minWidth: 70 }} />
            </Modal.Footer>
        </Modal>
    )
}
