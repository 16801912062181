import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { EditAccount } from '.'
import { Loading } from '../widgets'
import { apiGetOne } from '../../libs/apiLib'

export const EditAccountPage = () => {
  const { id: accountId } = useParams()
  const [state, setState] = useState({
    loading: true,
    account: null
  })

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchData = async () => {
    const account = await apiGetOne({ model: 'account', filter: { _id: accountId } })
    setState({ ...state, account, loading: false })
  }

  if (state.loading) return <Loading/>

  return (
    <>
      <EditAccount account={state.account}/>
    </>
  );
}
