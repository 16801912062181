import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { apiGetList, apiPost } from '../../libs/apiLib'
import { LinkButton, Loading, MyPopover, TextInput } from '../widgets'
import { Chart } from '.'
import { addMonths, format } from 'date-fns'
import { FaDownload, FaSync } from 'react-icons/fa'
import { isLastWorkDay } from '../../libs/utilLib'
import { toast } from 'react-toastify'


export const ChartPage = () => {
    const { ticker } = useParams()
    const [state, setState] = useState({
        loading: true,
        items: null,
        toDate: format(new Date(), 'yyyy-MM-dd'),
        fromDate: format(addMonths(new Date(), -15), 'yyyy-MM-dd')
    })

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ticker])

    const fetchData = async (checkLast = true) => {
        const start = format(addMonths(new Date(), -15), 'yyyy-MM-dd')
        const { items } = await apiGetList({ model: 'hist', filter: { symbol: ticker, date: { $gte: start } }, sort: { date: 1 } })
        // console.log(items)
        if (!items?.length || (checkLast && !isLastWorkDay(items[items.length - 1].date))) {
            await onDownload(items?.[items?.length - 1]?.date, state.toDate, items)
        } else setState({ ...state, items, loading: false, downloading: false })
    }

    const onDownload = async (fromDate, toDate, items) => {
        setState({ ...state, downloading: true })
        fromDate = fromDate || state.fromDate
        toDate = toDate || state.toDate
        // console.log(fromDate, toDate)
        const resp = await apiPost('fetch/hists', { symbol: ticker, fromDate, toDate })
        toast(`${resp.items?.length || 0} quotes downloaded.`)
        // console.log(items)
        items = items || state.items || []
        resp.items?.forEach(x => {
            if (!items?.find(y => y._id === x._id)) items.push(x)
        })
        // console.log(items)
        if (items?.length) setState({ ...state, items, loading: false, downloading: false })
        else //fetchData(false)
            window.location.reload()
    }

    const onChange = ({ target }) => {
        let { name, value } = target
        setState({ ...state, [name]: value })
    }

    if (state.loading) return <Loading />
    document.title = `Chart [${ticker}] - ${global.appName}`
    // console.log(state.items)
    return (
        <div className="mx-2 p-relative">
            <Chart symbol={ticker} hists={state.items} />
            <div className="p-absolute" style={{ top: 5, right: 220 }}>
                <MyPopover icon={<FaDownload />} style={{ minWidth: 200 }}>
                    <TextInput name="fromDate" value={state.fromDate} header="From date" onChange={onChange} />
                    <TextInput name="toDate" value={state.toDate} header="To date" onChange={onChange} />
                    <div className='mt-3'><LinkButton variant='primary' text="Download" icon={<FaSync className={state.downloading ? 'spinner' : ''} />} onClick={e => onDownload()} /></div>
                </MyPopover>
            </div>
            {/* {state.items?.length > 0 ?
                <Chart symbol={ticker} hists={state.items} onRefresh={fetchData} /> :
                <Container>
                    <NoData text={`No historical data available for ${ticker} - Download now.`} />
                    <Row className="mt-4">
                        <Col>
                            <TextInput name="fromDate" value={state.fromDate} header="From date" onChange={onChange} />
                        </Col>
                        <Col>
                            <TextInput name="toDate" value={state.toDate} header="To date" onChange={onChange} />
                        </Col>
                    </Row>
                    <div className='mt-4 text-center'><LinkButton variant='primary' text="Download" icon={<FaSync className={state.downloading ? 'spinner' : ''}/>} onClick={onDownload} /></div>
                </Container>
            } */}
        </div>
    )
}
