import { useState, useEffect, useContext } from 'react'
import { withAuthenticator } from '@aws-amplify/ui-react'
import { apiPost } from '../../libs/apiLib'
import { Loading, Unauthorized } from '../widgets'
import { useParams, useSearchParams } from 'react-router-dom'
import { TaxReport } from '.'
// import { prepTaxReport } from '../../libs/dataLib'
import { GlobalContext } from '../helpers'
// import { cloneDeep } from 'lodash'

const TaxReportPage = () => {
    const { id: userId } = useParams()
    // const [userId, accountId] = id.split(':')
    const [searchParams] = useSearchParams()
    const accountIds = searchParams.get('aid')
    const { gState } = useContext(GlobalContext)
    const [state, setState] = useState({
        items: null
    })

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const fetchData = async () => {
        if (!gState.isAdmin() && gState.loginUser._id !== userId) return
        const {items} = await apiPost('report/tax', { userId, accountIds })
        setState({ ...state, items })
        gState.onSet('hideTopbar', true)
    }

    if (!gState.isAdmin() && gState.loginUser?._id !== userId) return <Unauthorized />
    if (!state.items) return <Loading />
    document.title = `Tax report for ${gState.loginUser.username} - ` + global.appName
    return (
        <TaxReport username={gState.loginUser.username} accountIds={accountIds} items={state.items} />
    )
}

export default withAuthenticator(TaxReportPage)
