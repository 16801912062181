import { useState } from 'react'
import { Row, Col, ButtonGroup } from 'react-bootstrap'
import { FaCaretDown, FaArrowDown, FaArrowUp, FaTimes } from 'react-icons/fa'
import { MyPopover, LinkButton, Checkbox } from '../widgets'
import { orderBy } from 'lodash'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { columnOptions, pieOptions } from '../../libs/chartLib'

export const OrdersChart = ({ items, onClose }) => {
    const prefix = 'OrdersChart'
    const [state, setState] = useState({
        showTop: JSON.parse(localStorage.getItem(`${prefix}_showTop`)) || 20,
        height: JSON.parse(localStorage.getItem(`${prefix}_height`)) || 300,
        sortByValue: JSON.parse(localStorage.getItem(`${prefix}_sortByValue`)),
        showBar: JSON.parse(localStorage.getItem(`${prefix}_showBar`) || true),
        showPie: JSON.parse(localStorage.getItem(`${prefix}_showPie`) || true),
        categoryColumn: 'symbol',
        seriesColumn: 'amount'
    })

    const onToggle = (name) => {
        localStorage.setItem(`${prefix}_${name}`, JSON.stringify(!state[name]))
        setState({ ...state, [name]: !state[name] })
    }

    const onResize = (size) => {
        let { height } = state
        if (height === null || height === undefined) height = 400
        height = Math.max(height + size, 0)
        localStorage.setItem(`${prefix}_height`, JSON.stringify(height))
        setState({ ...state, height })
    }

    const getColumnOptions = () => {
        let items1 = items
        if (state.sortByValue) items1 = orderBy(items1, [state.seriesColumn], ['desc'])
        let items2 = items1.slice(0, state.showTop)
        let categories = items2.map(x => x[state.categoryColumn])
        let series = [
            { data: items2.map(x => Math.abs(x[state.seriesColumn])) },
        ]
        return { categories, series }
    }
    const getPieOptions = () => {
        let items1 = orderBy(items, [state.seriesColumn], ['desc'])
        let series = [{ data: items1.map(x => ({ name: x[state.categoryColumn], y: Math.abs(+x[state.seriesColumn]) })) }]
        return { series }
    }

    if (!items) return null
    return (
        <div>
            <div>
                <div className="float-end">
                    <ButtonGroup>
                        <LinkButton variant="outline-secondary" icon={<FaArrowDown />} onClick={e => onResize(200)} className="" />
                        <LinkButton variant="outline-secondary" icon={<FaArrowUp />} onClick={e => onResize(-200)} className="" />
                    </ButtonGroup>
                    <LinkButton variant="outline-secondary" icon={<FaTimes />} onClick={onClose} className="pr-0 ms-2" />
                </div>
                <h4 className="d-inline-block me-2">Distribution charts</h4>
                <MyPopover button={<FaCaretDown />}>
                    <b className="me-2">Chart:</b>
                    <Checkbox label="Bar" checked={state.showBar} onCheck={e => onToggle('showBar')} className="d-inline-block" />
                    <Checkbox label="Pie" checked={state.showPie} onCheck={e => onToggle('showPie')} className="d-inline-block" />
                    <hr className="my-1" />
                    <Checkbox label="Sorted" checked={!!state.sortByValue} onCheck={e => onToggle('sortByValue')} />
                </MyPopover>
            </div>

            <div>
                <Row>
                    {state.showBar && <Col md={state.showPie ? 8 : 12}>
                        <HighchartsReact highcharts={Highcharts} options={columnOptions({ ...getColumnOptions(), height: state.height })} />
                    </Col>}
                    {state.showPie && <Col md={state.showBar ? 4 : 12}>
                        <HighchartsReact highcharts={Highcharts} options={pieOptions({ ...getPieOptions(), height: state.height })} />
                    </Col>}
                </Row>
            </div>
        </div>
    )
}