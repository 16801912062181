import React from 'react'
// import { useHistory } from 'react-router-dom'
// import { GlobalContext } from '../helpers'
import { EditAccount } from '.'

export const AddAccountPage = () => {
  // const history = useHistory()
  // const { addToast } = useToasts()
  // const { gState } = useContext(GlobalContext)
  const account = { 
    name: '', 
    description: '',
  }
  // const [addAccount, { loading: saving, error: saveError }] = useMutation(Add_Account)

  // const onClose = () => {
  //   history.goBack();
  // }

  // const onSubmit = async ({name, type, bankName, clientId, accountNo, mask, balance, refAmount, remarks, description, status, createdAt = new Date()}) => {
  //   let account = {name, type, bankName, clientId, accountNo, mask, balance, refAmount: +((refAmount||'0').replace(/\$|,/g, '')), remarks, description, status, createdAt, userId: gState.loginUser.id}
  //   addAccount({ variables: {account}}).then(({data, error}) => {
  //     if (!error) {
  //       addToast('Account added successfully', { appearance: 'success', autoDismiss: true })
  //       global.refreshOnGoBack = true
  //       history.goBack();
  //     }
  //   })
  // }

  return (
    <>
      <EditAccount account={account}/>
    </>
  );
}
