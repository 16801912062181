import React from 'react'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export const LinkButton = ({variant="link", size="sm", to, as, text, icon, iconEnd, disabled, children, onClick, title, target, className = 'me-2', style}) => {
    if (variant === 'link' || to) as = Link
    return (
        <Button variant={variant} size={size} as={as} to={to} title={title} disabled={disabled} target={target} onClick={onClick} className={className} style={style}>
            {icon} {text || children} {iconEnd}
        </Button>
    )
}