import { find } from 'lodash'
import { transactionColumns } from './consts'

export const convertTsvToTransactions = (source) => {
    if (!source) return {}
    let columns = []
    let transactions = [], errors = [], warnings = []
    let lines = source.replace(/\r/g, '').split('\n')
    let head = lines[0]
    let start = 1
    if (!head.includes('Type') && !head.includes('Date')) {
      head = 'Date	Type	Symbol	Quantity	Price  Fee Extra Amount  Balance  Description'
      start = 0
    }
    let headers = head.split('\t').filter(x => (x||'').trim()).map(x => x.replace(/ /g, ''))
    for (let i = 0; i < headers.length; i++) {
      let x = headers[i]
      let column = find(transactionColumns, y => y.name.toLowerCase() === x.toLowerCase())
      if (!column) {
        warnings.push(`Column "${x}" is not a valid column for importing and it will be ignored.`)
      } else columns.push(column)
    }   
    for (let i = start; i < lines.length; i++) {
      let ss = lines[i].trim().split('\t')
      if (!ss.find(y => y)) continue
      let tran = {}
      for (let j = 0; j < ss.length; j++) {
        if (j >= headers.length) {
          let msg = 'Extra columns detected and will be ignored - all columns must have a header.'
          if (!warnings.find(x => x === msg)) warnings.push(msg)
          break
        }
        let name = headers[j].trim().toLowerCase()
        let value = (ss[j]||'').trim()
 
        let column = transactionColumns.find(z => z.name.toLowerCase() === name)
        if (!column) continue // invalid column
        else if (column.type === 'number') value = +value
        
        if (name === 'idx' && !value) value = i
  
        // let msg = `row ${i} col ${headers[j]}:`
 
        switch (name) {
          case 'type': 
            if (value.toUpperCase() === 'BUY' || value === 'BOUGHT' || value.toUpperCase() === 'REINVEST') value = 'Bought'
            else if (value.toUpperCase() === 'SELL' || value === 'SOLD') value = 'Sold'
            break
          default: break
        }
        tran[column ? column.id : name] = value
      }
      transactions.push(tran)
    }
    transactions.forEach(x => x.date = new Date(x.date).toISOString().substring(0, 10))
    if (transactions.length > 1 && transactions[0].date > transactions[transactions.length-1].date) transactions.reverse();
    return {transactions: fmtTransactions(transactions), columns, errors, warnings}
  }
  
  export const fmtTransactions = (transactions) => {
    const rr = ['date', 'type', 'symbol', 'quantity', 'price', 'fee', 'extra', 'amount', 'description']
    // const qq = ['pepper', 'vegetarian', 'vegan', 'glutenfree', 'organic', 'disallowExtra', 'unavailable', 'hidden']
    return transactions.filter(x => !x._error).map((x, i) => {
      x.idx = i
      if (x._price) {
        x.rates = x._price.toString().split(/;/).map(y => {
          let rate = {}
          if (!y.includes(',')) rate.price = +(y.replace(/\$/g, '')||"0")
          else {
            y.split(',').forEach((z, k) => {
              if (z.includes(':')) {
                let [name, value] = z.split(':').map(x => x.trim())
                name = name.toLowerCase()
                if (name === 'price' || name === 'special') rate[name] = +(value.replace(/$/g, '')||"0")
                else if (name === 'discount') rate['special'] = +(value.replace(/%/g, '')||"0")
                else rate[name] = value
              } 
              else if (k === 0) rate.unit = z
              else if (k === 1) rate.price = +z.replace(/\$/g, '')
              // else if (k === 2) rate.special = +z.replace(/\$/g, '')
              rate.kind = y.includes('category:') ? 'side' : ''
            })
          }
          Object.keys(rate).map(k => {
            if (!rr.find(p => p === k)) {
              x._warning = (x._warning || '') + `Invalid field "${k}" in rate at row ${i+1} will be ignored.`
              delete rate[k]
            }
            return null
          })
          return rate
        })
        if (!/,|;/.test(x._price.toString())) x._price = +(x._price.toString().replace(/\$/g, '')||'0') 
      }
      // qq.forEach(y => {
      //   if (typeof(x[y]) === 'string') {
      //     let v = (x[y]||'').toLowerCase()
      //     x[y] = v === 'true' || v === 'yes' || v === '1'
      //   }
      // })
      return x
    })
  }
  