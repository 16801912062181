import { useState } from 'react'
import { FaChevronDown, FaChevronUp, FaCopy } from "react-icons/fa"
import { toCurrency } from "../../libs/utilLib"
import { LinkButton, Checkbox } from "../widgets"
import { orderBy } from 'lodash'
import { ReportTrades } from './ReportTrades'
import { getTradesGrouppedBySymbol } from '../../libs/dataLib'
import { toast } from 'react-toastify'

export const ReportTradesByBank = ({ header, items }) => {
    const [state, setState] = useState({
        expanded: false,
        summarized: false,
    })

    const onToggle = (e, name) => {
        e.preventDefault()
        setState({...state, [name]: !state[name]})
    }

    const onCopy = (e, items, accountName) => {
        e.preventDefault()
        let str = '#\tShares sold\tSymbol\tDate acquired\tDate sold\tProceeds\tCost basis\tCode\tAdjustment\tGain or loss\tBuy price\tSell price\tHold days\tGain %\tGain $\tBank\n'
        items.forEach((x, i) => {
            str += `${i+1}\t${x.qty}\t${x.symbol}\t${x.dateAcquired}\t${x.dateSold}\t${x.proceeds}\t${x.cost}\t\t\t\t${x.buyPrice}\t${x.sellPrice}\t${x.holdDays}\t${x.winPercent}\t${x.winAmount}\t${accountName.replace(/ Trading| Hui/g, '')}\n`
        })
        if (navigator.clipboard.writeText(str)) toast(`Table copied into clipboard.`)
    }

    if (!items?.length) return null
    const { sortBy, sortDir } = state
    const total = items?.reduce((t, x) => t += x.winAmount || 0, 0)
    let items1 = state.summarized ? getTradesGrouppedBySymbol(items) : items
    if (sortBy && sortDir) items1 = orderBy(items1, [sortBy], [sortDir])
    return (
        <>
            <tr>
                <td colSpan={5}>
                    <span className="bold">Capital gain - {header}</span> [{items1.length}] 
                    <LinkButton icon={state.expanded ? <FaChevronUp /> : <FaChevronDown />} onClick={e => onToggle(e, 'expanded')} />
                    {state.expanded && <LinkButton icon={<FaCopy />} text="Copy table" onClick={e=>onCopy(e, items1, header)} />}
                </td>
                <td colSpan={20} className="text-end">
                    {state.expanded && <Checkbox type="switch" label="Group by symbol" checked={state.summarized} onCheck={e => onToggle(e, 'summarized')}/>}
                    <span className={`bold ${total < 0 ? 'red' : ''}`}>{toCurrency(total, 0, 0) || '$0'}</span>
                </td>
            </tr>
            {state.expanded && <ReportTrades items={items1}/>}
        </>
    )
}