import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

export const SparkAreaChart = ({ dates, values, width, height }) => {
    let positives = {name: 'Positive', color: 'blue', data: []}
    let negatives = {name: 'Nagative', color: 'red', data: []}
    let date
    for (let i = 0; i < Math.max(values.length, 78); i++) {
        if (i < values.length) {
            let date = new Date(+dates[i])
            let value = values[i]
            if (value >= 0) {
                positives.data.push([date, value])
                negatives.data.push([date, null])
            }
            else {
                negatives.data.push([date, value])
                positives.data.push([date, null])
            }
            date = dates[i]
        }
        else {
            date += 5 * 60 * 1000
            positives.data.push(new Date(date), null)
        }
    }
    const options = {
        credits: { enabled: false }, accessibility: { enabled: false },
        chart: { type: 'area', width, height, margin: [0, 5, 0, 5], backgroundColor: null, borderWidth: 0, style: { overflow: 'visible' }, skipClone: true },
        title: { text: '' }, legend: { enabled: false }, tooltip: { enabled: false },
        xAxis: { type: 'datetime', allowDecimals: false, startOnTick: false, endOnTick: false, tickPositions: [], lineWidth: 0, gridLineWidth: 0, labels: { enabled: false }, title: { text: '' }, crosshair: false },
        yAxis: { labels: { enabled: false }, title: { text: '' }, crosshair: false, startOnTick: false, endOnTick: false, tickPositions: [], lineWidth: 0, gridLineWidth: 0, plotLines: [{ value: 0, width: 1 }]},
        plotOptions: { series: { animation: false, lineWidth: 1, shadow: false, states: { hover: {lineWidth: 1} }, marker: { radius: 1, states: { hover: { radius: 2}}}, fillOpacity: 0.25 } },
        series: [positives, negatives]
    }
    // console.log(options)
    return (
        <HighchartsReact highcharts={Highcharts} options={options}/>
    )
}