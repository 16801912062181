import { Fragment, useEffect, useState, useContext } from 'react'
import { Table, Row, Col } from 'react-bootstrap'
import { FaEllipsisH, FaEllipsisV, FaPlus, FaEdit, FaTrash, FaChartBar, FaCaretDown, FaCopy } from 'react-icons/fa'
import { DateTime, MyPopover, Checkbox, SearchBox, Pin, LinkButton, ThSort, TrimTo, ConfirmButton, AutoRefresh } from '../widgets'
import { accountsView as setting } from '../../libs/consts'
import { copyTable, doSearch, fmtTo } from '../../libs/utilLib'
import { genAccountsReport, getSummary } from '../../libs/dataLib'
import { Link } from 'react-router-dom'
import { orderBy } from 'lodash'
import { apiPost } from '../../libs/apiLib'
import { toast } from 'react-toastify'
import { AccountsChart, AccountsReportModal } from '.'
import { GlobalContext } from '../helpers'
import { FaChevronUp, FaChevronDown } from 'react-icons/fa'
import { differenceInDays } from 'date-fns'

export const Accounts = ({ accounts, onRefresh, onDelete }) => {
    const prefix = 'Accounts'
    const { gState } = useContext(GlobalContext)
    const [state, setState] = useState({
        height: JSON.parse(localStorage.getItem(`${prefix}_height`) || 1200),
        pinResize: JSON.parse(localStorage.getItem(`${prefix}_pinResize`) || true),
        columns: JSON.parse(localStorage.getItem(`${prefix}_columns`)) || setting.columns,
        highlightOnly: false,
        pinSearch: JSON.parse(localStorage.getItem(`${prefix}_pinSearch`)),
        pinCharts: JSON.parse(localStorage.getItem(`${prefix}_pinCharts`)),
        showSymbols: JSON.parse(localStorage.getItem(`${prefix}_showSymbols`)),
        showCharts: JSON.parse(localStorage.getItem(`${prefix}_showCharts`)),
        showTradables: JSON.parse(localStorage.getItem(`${prefix}_showTradables`)),
        hideClosed: JSON.parse(localStorage.getItem(`${prefix}_hideClosed`)),
        sortBy: JSON.parse(localStorage.getItem(`${prefix}_sortBy`)) || 'name',
        sortDir: JSON.parse(localStorage.getItem(`${prefix}_sortDir`)) || 'asc',
        skip: 0,
        limit: JSON.parse(localStorage.getItem(`${prefix}_limit`)) || 20,
        items: null,
        searchText: '',
        hideEmpty: JSON.parse(localStorage.getItem(`${prefix}_hideEmpty`) || true),
        showRefs: JSON.parse(localStorage.getItem(`${prefix}_showRefs`)),
        banks: null,
        banksText: JSON.parse(localStorage.getItem(`${prefix}_banksText`)),
        types: null,
        typesText: JSON.parse(localStorage.getItem(`${prefix}_typesText`)),
    })

    useEffect(() => {
        const oo = state.ownersText?.split(/, /).filter(x => x)
        const owners = [...new Set(accounts.map(x => x.ownerName).sort((a, b) => a.localeCompare(b)))].map(x => ({ owner: x, checked: !oo?.length || oo.includes(x) }))
        const bb = state.banksText?.split(/, /).filter(x => x)
        const banks = [...new Set(accounts.map(x => x.bankName).sort((a, b) => a.localeCompare(b)))].map(x => ({ bank: x, checked: !bb?.length || bb.includes(x) }))
        const tt = state.typesText?.split(/, /).filter(x => x)
        const types = [...new Set(accounts.map(x => x.type).sort((a, b) => a.localeCompare(b)))].map(x => ({ type: x, checked: !tt?.length || tt.includes(x) }))
        setState(state => ({ ...state, owners, banks, types }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accounts])

    // useEffect(() => {
    //     localStorage.setItem(`${prefix}_columns`, JSON.stringify(setting.columns))        
    //     setState(state => ({ ...state, columns: setting.columns }))
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [setting])

    const refreshQuotes = async (range = '1d', mfOnly = false, source = 'yfinance2') => {
        let symbols = accounts.map(x => x.holdings?.map(y => y.yahooSymbol || y.symbol)).flat().filter(y => y && (mfOnly ? y.length >= 5 : y.length < 5))
        symbols = [...new Set(symbols)]
        if (!symbols.length) {
            return toast('No tickers to refresh.')
        }
        setState({ ...state, working: true })
        let { updatedCount } = await apiPost('fetch/quotes', { symbols: symbols.join(','), range, source })
        if (updatedCount) {
            toast(`${updatedCount} out of ${symbols.length} quotes refreshed.`)
            onRefresh()
        } else toast(`No quotes refreshed.`)
        setState({ ...state, working: false })
    }

    const refreshYearly = async () => {
        await refreshQuotes('1y')
    }

    const onResize = (size) => {
        let { height } = state
        height = Math.max(height + size, 0)
        localStorage.setItem(`${prefix}_height`, JSON.stringify(height))
        setState({ ...state, height })
    }

    const onSet = (name, value) => {
        localStorage.setItem(`${prefix}_${name}`, JSON.stringify(value))
        setState({ ...state, [name]: value })
    }

    const onToggle = (name) => {
        localStorage.setItem(`${prefix}_${name}`, JSON.stringify(!state[name]))
        setState({ ...state, [name]: !state[name] })
    }

    const onSearch = (searchText) => {
        setState({ ...state, searchText, skip: 0 })
    }

    // const onSet = (name, value) => {
    //     setState({ ...state, [name]: value })
    // }

    const onShow = (i) => {
        let { columns } = state
        columns[i].visible = !columns[i].visible
        localStorage.setItem(`${prefix}_columns`, JSON.stringify(columns))
        setState({ ...state, columns })
    }

    const onReset = () => {
        localStorage.removeItem(`${prefix}_columns`)
        setState({ ...state, columns: setting.columns })
    }

    const onStop = (e) => {
        e.stopPropagation()
    }

    const onSort = (sortBy) => {
        const sortDir = sortBy === state.sortBy ? (state.sortDir === 'desc' ? 'asc' : 'desc') : 'asc'
        localStorage.setItem(`${prefix}_sortBy`, JSON.stringify(sortBy))
        localStorage.setItem(`${prefix}_sortDir`, JSON.stringify(sortDir))
        setState({ ...state, sortBy, sortDir })
    }

    const onToggleFilter = (kind, item) => {
        if (item === 'all') state[kind].forEach(x => x.checked = true)
        else if (item === 'clear') state[kind].forEach(x => x.checked = false)
        else item.checked = !item.checked
        let values = state[kind].find(x => !x.checked) ? state[kind].filter(x => x.checked).map(x => x[kind.replace('s', '')]).join(', ') : ''
        localStorage.setItem(`${prefix}_${kind}Text`, JSON.stringify(values))
        setState({ ...state, [kind]: state[kind], [`${kind}Text`]: values })
    }

    // const onChange = ({ target }) => {
    //     const { name, value } = target
    //     setState({ ...state, [name]: value })
    // }

    // const allHoldingSymbols = (stocksOnly) => {
    //     let len = stocksOnly ? 5 : 6
    //     let symbols = [...new Set(accounts.map(x => x.holdings?.filter(y => y.company?.yahooSymbol || y.symbol).map(y => y.company?.yahooSymbol || y.symbol).filter(y => y.length < len)).flat().filter(x => x))].sort((a, b) => a.localeCompare(b)).join(', ')
    //     gState.showInfoModal({ header: 'All holding symbols', body: symbols, size: "xl", canCancel: false })
    // }

    const generateReport = () => {
        let subject = `Snapshot as of ${new Date().toISOString().substring(0, 10)}`
        let report = { subject, html: genAccountsReport(items, summary), to: gState.loginUser?.email }
        setState({ ...state, report })
    }

    const onReport = async (report) => {
        // console.log(report)
        let result = await apiPost('report/email', { ...report })
        console.log(result)
        if (result.status === 'OK') toast(`Report emailed successfully.`)
        else toast.error('Failed to email report.')
        setState({ ...state, report: null })
    }

    const onCopy = () => {
        let result = copyTable(items, columns)
        console.log(result)
        toast(`Table copied into clipboard.`)
    }

    const onSync = async (account) => {
        gState.showInfoModal({
            header: 'Sync accounts in ' + account.bankName, body: `<p>A new browser tab will popup to let you login to your ${account.bankName} account and accept their terms agreement first. Once you accept, ${account.bankName} will issue a <b>temporary access token</b> for us to query your data (balance, transactions and orders) from all your bank accounts accessible from that login.</p><p class="f-sm text-muted">Pinvestool cannot access or remember your username and password, so you have to login each time you request syncing.</p><p><b>Do you want to continue?</b></p>`, size: 'lg', cb: async (result) => {
                if (result === 'OK') {
                    let result = await apiPost('sync/account/request', { bankName: account.bankName })
                    if (result && !result.error) {
                        window.open(result.url);
                    }
                    else {
                        toast.error(result?.error || 'Server error')
                    }
                }
            }
        })
    }
    // const canShow = (y) => {
    //     return y.visible && (state.hideEmpty || items?.find(x => x[y.id]))
    // }

    // const getValue = (data, type) => {
    //     if (type === 'currency') return toCurrency(data)
    //     return data || ''
    // }

    // const hasHoldings = (account) => {
    //     return 'Trading,IRA,RRSP,RESP,401'.split(',').find(x => account.type.includes(x))
    // }

    const canSync = (account) => {
        return account.bankName === 'Etrade' && account.ownerName === 'Ming'
    }

    const getMatureDateColor = (dt) => {
        if (!dt) return
        if (typeof (dt) == 'string') dt = new Date(dt)
        let days = differenceInDays(dt, new Date())
        if (days <= 0) return 'red'
        else if (days <= 14) return '#ffff00'
        else if (days <= 30) return '#00ffff'
        return ''
    }

    const show = (id) => {
        let column = state.columns.find(x => x.id === id)
        if (!column) return false
        if (!state.showRefs && column.isRef) return false
        if (state.hideEmpty && !items.find(x => x[id])) return false
        return column.visible
    }

    const dropdown = () => <>
        <hr className="my-1" />
        <div><LinkButton text="Refresh yearly spark quotes" onClick={e => refreshYearly()} className="ps-0" /></div>
        <div><LinkButton text="Refresh MF quotes only" onClick={e => refreshQuotes('1d', true)} className="ps-0" /></div>
        <hr className="my-2" />
        <Pin pinned={state.pinResize} onClick={e => onToggle('pinResize')} className="my-2">
            Window height:
            <Link onClick={e => onResize(200)} className='ms-3'><FaChevronDown/></Link>
            <Link onClick={e => onResize(-200)} className='ms-3'><FaChevronUp/></Link>
        </Pin>
        <hr className="my-1" />
        <Pin pinned={pinSearch} onClick={e => onToggle('pinSearch')} className="my-2"><SearchBox searchText={searchText} onSearch={onSearch} show={true} style={{ width: 180 }} /></Pin>
        <Checkbox label="Highlight search only" checked={highlightOnly} onCheck={e => onToggle('highlightOnly')} />
        <hr className="my-1" />
        <Pin pinned={pinCharts} onClick={e => onToggle('pinCharts')}><Checkbox label="Distribution charts" checked={state.showCharts} onCheck={e => onToggle('showCharts')} /></Pin>
        <hr className="my-1" />
        <div><LinkButton text="Snapshot report" onClick={generateReport} className="ps-0" /></div>
        <div><LinkButton to={`/user/${gState.loginUser._id}/transactions`} target="_blank" text="All transactions" className="ps-0" /></div>
        <div><LinkButton to={`/user/${gState.loginUser._id}/tax`} target="_blank" text="All tax report" className="ps-0" /></div>
        <hr className="my-1" />
        <LinkButton to={`/account/add`} text="Add new account" icon={<FaPlus />} className="ps-0" />
    </>

    const { columns, searchText, sortDir, sortBy, pinSearch, highlightOnly, pinCharts } = state
    if (!accounts) return null
    let items = accounts
    items = items.filter(x => state.types?.find(y => y.checked && y.type === x.type)).filter(x => state.banks?.find(y => y.checked && y.bank === x.bankName)).filter(x => state.owners?.find(y => y.checked && y.owner === x.ownerName))
    if (state.showTradables) items = items.filter(x => x.holdCount > 0)
    if (state.hideClosed) items = items.filter(x => !x.status?.toLowerCase().includes('closed') && !x.status?.toLowerCase().includes('idle'))
    if (searchText && !highlightOnly) items = items.filter(x => doSearch(x, searchText, setting.searchFields))
    if (sortBy && sortDir) items = orderBy(items, [sortBy], [sortDir])
    let summary = getSummary(items)
    return (
        <div>
            <div className="move-header-to-top">
                <div className="float-end mt-2">
                    {pinSearch && <SearchBox searchText={searchText} onSearch={onSearch} />}
                    {pinCharts && <LinkButton icon={<FaChartBar />} variant="outline-primary" onClick={e => onToggle('showCharts')} className="ms-2" />}
                    <AutoRefresh key="myAccounts" id="myAccounts" working={state.working} doRefresh={e => refreshQuotes()} dropdown={dropdown()} className="ms-2" />
                </div>
                <h5 className="d-inline-block mt10">My Accounts</h5>
                <MyPopover button={<FaCaretDown className="p-cursor link ms-2"/>}>
                    <div><Checkbox label="Show tradables only" checked={!!state.showTradables} onCheck={e => onToggle('showTradables')} /></div>
                    <div><Checkbox label="Hide idle & closed" checked={!!state.hideClosed} onCheck={e => onToggle('hideClosed')} /></div>
                    <hr className="my-1" />
                    <div>
                        <div className="float-end">
                            <LinkButton text="All" onClick={e => onToggleFilter('owners', 'all')} className="pt-0" />
                            <span onClick={onStop}><LinkButton text="None" onClick={e => onToggleFilter('owners', 'clear')} className="pt-0 pe-0" /></span>
                        </div>
                        <div><b>Owner:</b></div>
                    </div>
                    <Row className="clear-both">
                        {state.owners?.map((x, i) => <Col key={i} xs={6}>
                            <Checkbox label={x.owner} checked={!!x.checked} onCheck={e => onToggleFilter('owners', x)} />
                        </Col>)}
                    </Row>
                    <hr className="my-1" />
                    <div>
                        <div className="float-end">
                            <LinkButton text="All" onClick={e => onToggleFilter('banks', 'all')} className="pt-0" />
                            <span onClick={onStop}><LinkButton text="None" onClick={e => onToggleFilter('banks', 'clear')} className="pt-0 pe-0" /></span>
                        </div>
                        <div><b>Bank:</b></div>
                    </div>
                    <Row className="clear-both">
                        {state.banks?.map((x, i) => <Col key={i} xs={6}>
                            <Checkbox label={x.bank} checked={!!x.checked} onCheck={e => onToggleFilter('banks', x)} />
                        </Col>)}
                    </Row>
                    <hr className="my-1" />
                    <div>
                        <div className="float-end">
                            <LinkButton text="All" onClick={e => onToggleFilter('types', 'all')} className="pt-0" />
                            <span onClick={onStop}><LinkButton text="None" onClick={e => onToggleFilter('types', 'clear')} className="pt-0 pe-0" /></span>
                        </div>
                        <div><b>Type:</b></div>
                    </div>
                    <Row className="clear-both">
                        {state.types?.map((x, i) => <Col key={i} xs={6}>
                            <Checkbox label={x.type} checked={!!x.checked} onCheck={e => onToggleFilter('types', x)} />
                        </Col>)}
                    </Row>
                </MyPopover>
                {state.pinResize && <span className='f-sm'>
                    <Link onClick={e => onResize(200)} className='ms-3'><FaChevronDown/></Link>
                    <Link onClick={e => onResize(-200)} className='ms-3'><FaChevronUp/></Link>
                </span>}
            </div>

            <div className="clear-both" style={{ maxHeight: state.height, maxWdith: "100%", overflow: 'auto' }}>
                <Table size="sm" hover className='f-sm nowrap mt-2'>
                    <thead>
                        <tr>
                            <th>#</th>
                            {columns?.filter(x => show(x.id)).map((x, i) => <ThSort key={i} id={x.id} text={x.name} sortBy={sortBy} sortDir={sortDir} onSort={onSort} className={x.className} />)}
                            <th className="text-end">
                                <MyPopover button={<FaEllipsisH className="grey" />}>
                                    <div><LinkButton icon={<FaCopy />} text="Copy table" onClick={onCopy} className="ps-0" /></div>
                                    <hr className="my-1" />
                                    <div><Checkbox label="Show ref columns" checked={state.showRefs} onCheck={e => onToggle('showRefs')} /></div>
                                    <div><Checkbox label="Hide empty columns" checked={state.hideEmpty} onCheck={e => onToggle('hideEmpty')} /></div>
                                    <hr className="my-1" />
                                    <div className="float-end"><LinkButton text="Reset to default" onClick={onReset} className="p-0" /></div>
                                    <div className="bold">Show columns</div>
                                    <Row className="clear-both mt-2">
                                        {columns?.map((x, i) => <Col key={i} xs={6}>
                                            <Checkbox label={x.name} checked={x.visible} onCheck={e => onShow(i)} />
                                        </Col>)}
                                    </Row>
                                </MyPopover>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style={{ backgroundColor: '#F0FFF0' }}></td>
                            {columns.map((x, i) => <Fragment key={i}>
                                {show(x.id) && <td className={x.className} style={{ backgroundColor: '#F0FFF0', color: summary[x.id] < 0 ? x.downColor : x.upColor }}>
                                    {fmtTo(summary[x.id], x.type, x.digits, x.trimTo)}
                                </td>}
                            </Fragment>)}
                            <td style={{ backgroundColor: '#F0FFF0' }}></td>
                        </tr>
                        {items.map((x, i) => <Fragment key={i}>
                            <tr style={{ color: x.inactive ? '#bbb' : '' }}>
                                <td>{i + 1}</td>
                                {/* <td width="10" className="hide-on-print"><ClickButton variant="link" onClick={e => onToggleExpand(x)} icon={x.expanded ? 'chevron-up' : 'chevron-right'} title="Show/hide holdings" className="mt-n1 pl-0 ml-0" /></td> */}
                                {columns.map((y, j) => <Fragment key={j}>
                                    {show(y.id) && <td className={`${y.className} ${y.id !== 'balance' ? '' : Math.abs(x[y.id] - x['totalValue']) > 0.1 ? 'blueviolet' : ''}`} style={{ color: x[y.id] < 0 ? y.downColor : y.upColor }}>
                                        {y.id === 'holdCount' && !!x[y.id] ? <LinkButton variant="outline-secondary" to={`/account/${x._id}/holdings`} target="_blank" text={x[y.id]} className="py-0" title="Holdings" style={{ width: 35 }} /> :
                                            y.id === 'transCount' && !!x[y.id] ? <LinkButton variant="outline-secondary" to={`/account/${x._id}/transactions`} target="_blank" text={x[y.id]} className="py-0" title="Transactions" style={{ width: 45 }} /> :
                                            y.id === 'matureDate' && !!x[y.id] ? <span style={{backgroundColor: getMatureDateColor(x[y.id])}}><DateTime date={x[y.id]} fmt={y.fmt} /></span> :
                                                y.type === 'date' ? <DateTime date={x[y.id]} fmt={y.fmt} /> :
                                                    y.trimTo ? <TrimTo text={x[y.id]} to={y.trimTo} searchText={searchText} /> : fmtTo(x[y.id], y.type, y.digits, y.trimTo, null, y.showZero)}
                                    </td>}
                                </Fragment>)}
                                <td className="text-end nowrap">
                                    <MyPopover button={<FaEllipsisV className="grey" />}>
                                        <div><LinkButton variant={canSync(x) ? "primary" : 'secondary'} onClick={e => onSync(x)} disabled={!canSync(x)} text={`Sync accounts from ${x.bankName}`} /></div>
                                        <div><LinkButton to={`/account/${x._id}/edit`} icon={<FaEdit />} text="Edit account" className="ps-0" /></div>
                                        <div><ConfirmButton variant="link" icon={<FaTrash />} text="Delete account" onClick={e => onDelete(x)} className="ps-0" /></div>
                                        {(!!x.holdCount || !!x.transCount) && <hr className="my-1" />}
                                        {!!x.holdCount && <div className="my-2"><Link to={`/account/${x._id}/holdings`} target="_blank">View holdings</Link></div>}
                                        {!!x.transCount && <div className="my-2"><Link to={`/account/${x._id}/transactions`} target="_blank">View transactions</Link></div>}
                                        {!!x.transCount && <div className="my-2"><Link to={`/account/${x._id}/perf`} target="_blank">View performance</Link></div>}
                                        <div className="my-2"><Link to={`/user/${gState.loginUser?._id}:${x._id}/tax`} target="_blank">View tax report</Link></div>
                                        <hr className="my-1" />
                                        <div><Link to={`/account/${x._id}/transaction/import`}>Import transactions</Link></div>
                                    </MyPopover>
                                </td>
                            </tr>
                        </Fragment>)}
                    </tbody>
                </Table>
            </div>

            {state.showCharts && <AccountsChart items={items} columns={state.columns} onClose={e => onToggle('showCharts')} />}
            {state.report && <AccountsReportModal report={state.report} onCancel={e => onSet('report', null)} onOK={onReport} />}
        </div>
    )
}