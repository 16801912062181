import React, { useState, useContext } from 'react'
import { Modal, Form, Alert } from 'react-bootstrap'
import { TrimTo, TextInput, CancelButton, SubmitButton, Checkbox } from '../widgets'
import { GlobalContext } from '../helpers'
import { apiPost } from '../../libs/apiLib'
import { useNavigate } from 'react-router-dom'

export const SyncAccount = ({ account }) => {
    const navigate = useNavigate()
    const { gState } = useContext(GlobalContext)
    const [state, setState] = useState({
        error: '',
        requested: false
    })

    const onCheck = (name) => {
        setState({ ...state, [name]: !state[name] })
    }

    const onChange = ({ target }) => {
        let { name, type, value } = target
        if (type === 'number') value = +value
        setState({ ...state, [name]: value, error: '' });
    }

    const onSubmit = async () => {
        setState({ ...state, working: true })
        if (!state.requested) {
            let { balance, orders, transactions } = state
            let data = { _id: account._id, balance, orders, transactions, userId: gState.loginUser?._id }
            let result = await apiPost('sync/account/request', data)
            if (result && !result.error) {
                setState({ ...state, working: false, requested: true })
                window.open(result.url);
            }
            else {
                setState({ ...state, error: result?.error || 'Server error', working: false })
            }
        } else {
            let { accessCode } = state
            let data = {accessCode}
            let result = await apiPost('sync/account/perform', data)
            if (result && !result.error) {
                setState({ ...state, working: false })
                console.log(result)
                // onClose()
            }
            else {
                setState({ ...state, error: result?.error || 'Server error', working: false })
            }
        }
    }

    const onClose = () => {
        navigate(-1)
    }

    return (
        <Modal show={true}>
            <Form>
                <Modal.Header>
                    <Modal.Title>Sync account</Modal.Title>
                </Modal.Header>
                <Modal.Body className="py-3">
                    {state.error && <Alert variant="danger"><TrimTo text={state.error} to={200} /></Alert>}

                    {!state.requested ? <>
                        <div><Checkbox label="Account balance" checked={!!state.balance} onCheck={e => onCheck('balance')} /></div>
                        <div><Checkbox label="Orders" checked={!!state.orders} onCheck={e => onCheck('orders')} /></div>
                        <div><Checkbox label="Transactions" checked={!!state.transactions} onCheck={e => onCheck('transactions')} /></div>
                    </> : <>
                        Please copy & paste the verification code below.
                        
                        <TextInput type="text" header="Verification code" name="accessCode" value={state.accessCode} onChange={onChange} />
                    </>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <CancelButton onClick={onClose} />
                    <SubmitButton disabled={state.working} working={state.working} onClick={onSubmit} className="ms-2" />
                </Modal.Footer>
            </Form>
        </Modal>
    )
}
