import { useState, useEffect, useContext } from 'react'
import { withAuthenticator } from '@aws-amplify/ui-react'
import { useParams } from 'react-router-dom'
import { apiGetList, apiPost, apiGetOne } from '../../libs/apiLib'
import { Checkbox, Loading, MyPopover, Unauthorized, LinkButton, AutoRefresh, Pin } from '../widgets'
import { Holdings } from '.'
import { Container } from 'react-bootstrap'
import { GlobalContext } from '../helpers'
import { orderBy } from 'lodash'
import { FaCaretDown } from 'react-icons/fa'
import { toast } from 'react-toastify'
import { useInterval } from '../widgets'
import { toCurrency, toPercent } from '../../libs/utilLib'

const MyHoldingsPage = () => {
    const prefix = 'MyHoldingsPage'
    const { id: userId } = useParams()
    const { gState } = useContext(GlobalContext)    
    const [state, setState] = useState({
        working: true,
        selectedIds: JSON.parse(localStorage.getItem(`${prefix}_selectedIds`)),
        showHoldings: JSON.parse(localStorage.getItem(`${prefix}_showHoldings`) || true),
        showOrders: JSON.parse(localStorage.getItem(`${prefix}_showOrders`) || true),
        pinHideMF: JSON.parse(localStorage.getItem(`${prefix}_pinHideMF`) || true),
        pinPQuote: JSON.parse(localStorage.getItem(`${prefix}_pinPQuote`)),
        hideMF: JSON.parse(localStorage.getItem(`${prefix}_hideMF`)),
        accounts: null
    })

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useInterval(() => {
        setState({...state})
    }, 1000 * 60)
    
    const fetchData = async () => {
        if (!gState.isAdmin() && gState.loginUser?._id !== userId) return
        const { items } = await apiGetList({
            model: 'account', filter: { userId, type: { $in: ['Trading', 'IRA'] } }, populates: [
                { path: 'holdings', populate: { path: 'company', select: { dailyQuotes: 0, yearlyQuotes: 0 } } },
                { path: 'orders', populate: { path: 'company', select: { dailyQuotes: 0, yearlyQuotes: 0 } } }
            ]
        })
        let accounts = items.filter(x => x.holdings?.length > 0)
        state.selectedIds?.forEach(x => {
            let account = accounts.find(y => y._id === x)
            if (account) account.selected = true
        })
        setState({ ...state, accounts, working: false })
    }

    const fetchAccount = async (accountId) => {
        const account = await apiGetOne({
            model: 'account', filter: { _id: accountId }, populates: [
                { path: 'holdings', populate: { path: 'company', select: { dailyQuotes: 0, yearlyQuotes: 0 } } },
                { path: 'orders', populate: { path: 'company', select: { dailyQuotes: 0, yearlyQuotes: 0 } } }
            ]
        })
        account.selected = true
        let { accounts } = state
        accounts = [...accounts.filter(x => x._id !== accountId), account]
        setState({ ...state, accounts, working: false })
    }

    const refreshQuotes = async (range = '1d', source = 'yfinance2') => {
        setState({ ...state, working: true })
        let accounts = state.accounts.filter(x => x.selected)
        let symbols = []
        if (state.showHoldings) symbols = [...symbols, ...accounts.map(x => x.holdings?.map(y => y.yahooSymbol || y.symbol)).flat()]
        if (state.showOrders) symbols = [...symbols, ...accounts.map(x => x.orders?.map(y => y.yahooSymbol || y.symbol)).flat()]
        symbols = [...new Set(symbols.filter(x => x))]
        // console.log(symbols, accounts.map(x => x.orders?.map(y => y.yahooSymbol || y.symbol)).flat())
        let { updatedCount } = await apiPost('fetch/quotes', { symbols: symbols.join(','), range, source })
        if (updatedCount) {
            toast(`${updatedCount} out of ${symbols.length} quotes refreshed.`)
            fetchData()
        } else {
            toast(`No quotes refreshed.`)
        }
        setState({ ...state, working: false })
    }

    const refreshYearly = async () => {
        await refreshQuotes('1y', 'ysparkline')
    }

    const onCheck = (account) => {
        account.selected = !account.selected
        let selectedIds = state.accounts.filter(x => x.selected).map(x => x._id)
        localStorage.setItem(`${prefix}_selectedIds`, JSON.stringify(selectedIds))
        setState({ ...state, selectedIds })
    }

    const onCheckAll = (checkAll) => {
        let { accounts } = state
        accounts.forEach(x => x.selected = checkAll)
        localStorage.setItem(`${prefix}_selectedIds`, JSON.stringify(accounts.filter(x => x.selected).map(x => x._id)))
        setState({ ...state, accounts })
    }

    const onStop = (e) => {
        e.stopPropagation()
    }

    const onToggle = (name) => {
        localStorage.setItem(`${prefix}_${name}`, JSON.stringify(!state[name]))
        setState({ ...state, [name]: !state[name] })
    }

    const dropdown = () => <>
        <hr className="my-1" />
        <LinkButton text="Refresh yearly spark quotes" onClick={e => refreshYearly()} className="ps-0" />
        <hr className="my-2" />
        <div><Checkbox label="Show market charts on top" checked={!!gState.showMarketCharts} onCheck={e => gState.onToggle('showMarketCharts')} /></div>
        <hr className="my-2" />
        <div><Checkbox label="Show holdings" checked={!!state.showHoldings} onCheck={e => onToggle('showHoldings')} /></div>
        <Pin pinned={state.pinHideMF} onClick={e => onToggle('pinHideMF')} className="my-2"><Checkbox label="Hide MF in holdings" checked={!!state.hideMF} onCheck={e => onToggle('hideMF')} /></Pin>
        <div><Checkbox label="Show orders" checked={!!state.showOrders} onCheck={e => onToggle('showOrders')} /></div>
        <hr className="my-1" />
        <Pin pinned={state.pinPQuote} onClick={e => onToggle('pinPQuote')} className="my-2"><Checkbox label="Use pre/post market quote" checked={!!state.pQuote} onCheck={e => onToggle('pQuote')} /></Pin>
        {/* <div><Checkbox label="Use pre/post market quote" checked={!!state.pQuote} onCheck={e => onToggle('pQuote')} /></div> */}
        <hr className="my-2" />
        <div><LinkButton to={`/user/${gState.loginUser?._id}/tax?aid=${state.accounts.filter(x => x.selected).map(x => x._id).join('+')}`} target="_blank" text="All accounts tax report" className="ps-0" /></div>
    </>

    if (state.working && !state.accounts) return <Loading />
    document.title = `Holdings - ${global.appName}`
    if (!gState.isAdmin() && gState.loginUser?._id !== userId) return <Unauthorized />
    let accounts = orderBy(state.accounts, ['name'])
    let total = 0
    let dayChange = 0
    accounts.filter(x => x.selected).forEach(x => {x.total = 0; x.dayChange = 0; x.holdings.filter(y => state.hideMF ? y.company?.kind !== 'MF' : true).forEach(y => {
        x.total += (y.quantity||0) * ((state.pQuote && y.company?.pPrice ? y.company?.pPrice : y.company?.price) || 0)
        x.dayChange += (y.quantity||0) * ((state.pQuote && y.company?.pPrice ? y.company.pChange : y.company?.change) || 0)}) 
    })
    accounts.filter(x => x.selected).forEach(x => {total += x.total + x.cash; dayChange += x.dayChange})
    // console.log(accounts, total)
    return (
        <Container fluid>
            <div className={gState.showMarketCharts ? '' : 'move-header-to-top'}>
                <div className="float-end">
                    {state.pinHideMF && <Checkbox type="switch" label="Hide MF" checked={!!state.hideMF} onCheck={e => onToggle('hideMF')} />}
                    {state.pinPQuote && <Checkbox type="switch" label="P-Quote" checked={!!state.pQuote} onCheck={e => onToggle('pQuote')} />}
                    <AutoRefresh key="myHoldings" id="myHoldings" working={state.working} variant="primary" doRefresh={e => refreshQuotes()} dropdown={dropdown()} className="ms-2" />
                </div>
                <div className="h5 d-inline-block me-2">My Holdings <br className="d-sm-only"/><span className="f-normal">[<span className="text-muted">{toCurrency(total)}</span> <span className={dayChange < 0 ? 'red' : dayChange > 0 ? 'blue' : ''}>{dayChange > 0 ? '+': ''}{toCurrency(dayChange)} ({toPercent(!!total ? dayChange/total : 0, 2, 2)})</span>]</span> </div>
                <MyPopover button={<FaCaretDown className="link" />} >
                    <div className="float-end mt-n1" onClick={onStop}>
                        <LinkButton text="All" onClick={e => onCheckAll(true)} className="pt-0" />
                        <LinkButton text="None" onClick={e => onCheckAll(false)} className="pt-0 pe-0" />
                    </div>
                    <div><b>Accounts:</b></div>
                    <hr className="my-1 clear-both" />
                    {accounts.map((x, i) => <div key={i}>
                        <div className="float-end ms-2"><span className={x.dayChange < 0 ? 'red' : 'blue'}>{toCurrency(x.dayChange)}</span></div>
                        <Checkbox label={`${x.name} [${x.holdings?.length}]`} checked={!!x.selected} onCheck={e => onCheck(x)} />
                    </div>)}
                </MyPopover>
            </div>
            <div className="clear-both">
                {accounts.filter(x => x.selected).map((x, i) => <Holdings key={i} account={x} onRefresh={fetchAccount} embedded={true} showHoldings={state.showHoldings} showOrders={state.showOrders} hideMF={state.hideMF} pQuote={state.pQuote}/>)}
            </div>
        </Container>
    )
}

export default withAuthenticator(MyHoldingsPage)
