import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import { LinkButton } from '../widgets'

export const TextInput = ({ id, type, header, name, value, placeholder, topright, as, rows, size, title, hint, disabled, onChange, onEnter, onPaste, className = "mb-2", inputStyle }) => {
    const [state, setState] = useState({
        [name]: value
    })
    const handleChange = (e) => {
        if (onChange) return onChange(e)
        let {name, type, value} = e.target
        if (type === 'number') value = +value
        setState({ ...state, [name]: value });
    }

    const handleEnter = (e) => {
        if (onEnter) onEnter(e, state[name])
    }
  
    const onKeyDown = (e) => {
        if (e.keyCode === 13) {
            if (onEnter) onEnter(e, state[name])
        }
    }

    const onStop = (e) => {
        e.stopPropagation()
    }

    return (
        <Form.Group controlId={id || name} className={className} onClick={onStop}>
            {header && <Form.Label title={title} className="blueviolet me-2">{header}</Form.Label>}
            {topright && <span className="float-end">{topright}</span>}
            {onEnter && <LinkButton onClick={handleEnter} className="float-end">OK</LinkButton>}
            <Form.Control type={type} as={as} rows={rows} name={name} value={onEnter ? state[name] : value} size={size} title={title} placeholder={placeholder} disabled={disabled} onChange={handleChange} onKeyDown={onKeyDown} onPaste={onPaste} onFocus={e=>e.currentTarget.select()} style={inputStyle} />
            {hint && <Form.Text className="text-muted">{hint}</Form.Text>}
        </Form.Group>
    )
}

