import React from 'react'
import { Modal } from 'react-bootstrap'
import { DivHtml, LinkButton } from '..'

export const InfoModal = ({ header, body, size, canCancel = true, canNo, cancelText = 'Cancel', noText = 'No', okText = 'OK', cb, onClose }) => {

  const onClick = (value) => {
    onClose()
    if (cb) cb(value)
  }

  return (
    <Modal show={true} backdrop="static" size={size}>
      <Modal.Header>
        <Modal.Title>{header}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <DivHtml html={body} />
      </Modal.Body>

      <Modal.Footer>
        {canCancel && <LinkButton variant="secondary" onClick={e => onClick(cancelText)} text={cancelText} />}
        {canNo && <LinkButton variant="primary" onClick={e => onClick(noText)} text={noText} style={{ minWidth: 70 }} />}
        <LinkButton variant="primary" onClick={e => onClick(okText)} text={okText} style={{ minWidth: 70 }} />
      </Modal.Footer>
    </Modal>
  )
}
