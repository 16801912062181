import { useContext } from 'react'
import { FaHeart, FaRegHeart, FaThumbsUp, FaThumbsDown } from "react-icons/fa"
import { LinkButton, ALink, MyPopover } from "../widgets"
import { GlobalContext } from '../helpers'

export const Ticker = ({ symbol, yahooSymbol, showFavor=true }) => {
    const { gState } = useContext(GlobalContext)
    if (!symbol) return null
    return (
        <>
            {gState.loginUser && showFavor && <div className="float-end">
                <MyPopover button={gState.isFavor(symbol, 'watch') ? <FaHeart className="blueviolet" title="Watching" /> : gState.isFavor(symbol, 'buy') ? <FaThumbsUp className="blueviolet" title="Watch for buy" /> : gState.isFavor(symbol, 'sell') ? <FaThumbsDown className="blueviolet" title="Watch for sell" /> : <FaRegHeart title="Favorite?" />}>
                    <div><LinkButton icon={gState.isFavor(symbol, 'watch') ? <FaHeart className="blueviolet" /> : <FaRegHeart />} text="Watching" onClick={e => gState.onFavor(symbol, 'watch')} /></div>
                    {/* <div><LinkButton icon={gState.isFavor(symbol, 'buy') ? <FaThumbsUp className="blueviolet" /> : <FaRegThumbsUp />} text="Watch for buy" onClick={e => gState.onFavor(symbol, 'buy')} /></div>
                    <div><LinkButton icon={gState.isFavor(symbol, 'sell') ? <FaThumbsDown className="blueviolet" /> : <FaRegThumbsDown />} text="Watch for sell" onClick={e => gState.onFavor(symbol, 'sell')} /></div> */}
                </MyPopover>
            </div>}
            <ALink href={`https://finance.yahoo.com/chart/${yahooSymbol || symbol}`} text={symbol} />
        </>
    )
}