import React from 'react'
import { FaCaretDown, FaCaretUp } from 'react-icons/fa'

export const ThSort = ({ id, text, sortBy, sortDir, onSort, title, className, style }) => {
    return (
        <th title={title} className={className} style={style}>
            <span onClick={e => onSort(id)} className="c-pointer">{text} {sortBy === id && <span className="link">{sortDir === 'desc' ? <FaCaretDown/> : <FaCaretUp/>}</span>}</span>
        </th>
    )
}
