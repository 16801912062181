import React, { useState, useEffect, useContext } from 'react'
import { Modal, Form, Alert } from 'react-bootstrap'
import { TrimTo, TextInput, CancelButton, SubmitButton, LinkButton } from '../widgets'
import { toast } from 'react-toastify'
import { apiPost, apiPut } from '../../libs/apiLib'
import { useNavigate } from 'react-router-dom'
import { GlobalContext } from '../helpers'
import { FaPaste } from 'react-icons/fa'

export const EditNews = ({ news }) => {
    const navigate = useNavigate()
    const { gState } = useContext(GlobalContext)
    const [state, setState] = useState({
        ...news,
        error: ''
    })

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.quantity, state.price, state.fee])

    const onChange = ({ target }) => {
        let { name, type, value } = target
        if (type === 'number') value = +value
        setState({ ...state, [name]: value, error: '' });
    }

    const validate = () => {
        return state.headline && state.sourceUrl
    }

    const onSubmit = async () => {
        setState({ ...state, working: true })
        let { _id, headline, sourceUrl, publisher, publishDate, symbols, remarks } = state
        let news = { headline, sourceUrl, publisher, publishDate, symbols, remarks, userId: gState.loginUser?._id }
        if (_id) news.updatedAt = new Date()
        else news.createdAt = new Date()       
        let result = _id ? (await apiPut('news', _id, news)) : (await apiPost('news', news))
        if (result && !result.error) {
            toast('news updated successfully')
            setState({ ...state, working: false })
            navigate(-1);
        }
        else {
            setState({ ...state, error: result?.error || 'Server error', working: false })
        }
    }

    const onClose = () => {
        navigate(-1)
    }

    const onPaste = async (e) => {
        e.preventDefault()
        let text = await navigator.clipboard.readText()
        if (!text) return
        let table = []
        let lines = text.split(/\r?\n/)
        lines.forEach(x => {
            table.push(x.split('\t'))
        })
        let n = 0
        let skip = 0
        if (table.length > 0 && table[0].includes('Symbol')) {
            n = table[0].findIndex(x => x === 'Symbol')
            skip = 1
        }
        let symbols = state.symbols?.trim().split(/\s+|\s*,\s*|\s*\+\s*/) || []
        table.forEach((x, i) => {
            if (i >= skip) {
                symbols.push(x[n])
            }
        })
        setState({ ...state, symbols: [...new Set(symbols)].filter(x => x).join(' ') })
    }

    return (
        <Modal show={true} backdrop="static" size="xl" keyboard={false}>
            <Form>
                <Modal.Header>
                    <Modal.Title>{news._id ? 'Edit' : 'Add'} news</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {state.error && <Alert variant="danger"><TrimTo text={state.error} to={200} /></Alert>}

                    <TextInput header="Headline" name="headline" value={state.headline} onChange={onChange} />
                    <TextInput header="Source url" name="sourceUrl" value={state.sourceUrl} onChange={onChange} />
                    <TextInput header="Publisher" name="publisher" value={state.publisher} onChange={onChange} />
                    <TextInput header="Publish date" name="publishDate" value={state.publishDate} onChange={onChange} />
                    <LinkButton icon={<FaPaste />} text="Paste table with symbols" onClick={onPaste} className="float-end pe-0" />
                    <TextInput as="textarea" rows={3} header="Symbols" name="symbols" value={state.symbols} onChange={onChange} />
                    <TextInput as="textarea" rows={3} header="Remarks" name="remarks" value={state.remarks} onChange={onChange} />
                </Modal.Body>
                <Modal.Footer>
                    <CancelButton onClick={onClose} />
                    <SubmitButton disabled={!validate()} working={state.working} onClick={onSubmit} className="ms-2" />
                </Modal.Footer>
            </Form>
        </Modal>
    );
}
