import React from 'react'
import { FaThumbtack } from 'react-icons/fa'

export const Pin = ({pinned, children, onClick, className}) => {
  return (
    <div className={className}>
      <div className="float-end c-pointer"><FaThumbtack onClick={onClick} style = {{transform: `rotate(${pinned ? '0': '90'}deg)` }} color="grey"/></div>
      {children}
    </div>
  )
}
