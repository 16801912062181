import React, { useState } from 'react'
import { format, formatDistance, isSameDay } from 'date-fns'
import { toDate } from '../../libs/utilLib'

export const DateTime = ({date, kind, fmt, empty, isUtc, compact, style}) => {
  const [state, setState] = useState({
    kind
  })

  const toggle = () => {
    const kind = state.kind === 'absolute' ? 'relative' : 'absolute'
    setState({...state, kind})
  }

  if (!date) return empty || null
  // console.log(date)
  let dt = toDate(date) 
  if (isUtc) dt = new Date(dt.getTime() - dt.getTimezoneOffset() * 60 * 1000)
  fmt = fmt || 'yyyy-MM-dd hh:mm b'
  if (compact) fmt = isSameDay(dt, new Date()) ? 'HH:mm' : fmt = 'MM/dd'
  let strDate = state.kind === 'absolute' ? format(dt, fmt) : formatDistance(dt, new Date(), {addSuffix: true}).replace(/minutes?/, 'min').replace(/hours?/, 'hr').replace(/almost|about/g, '')
  if (strDate.includes('less than a min')) strDate = 'just now'
  return (
    <span onClick={toggle} className="c-pointer nowrap" style={style}>{strDate}</span>
  )
}
