import React, { useState } from 'react'
// import { GlobalContext } from '../general'
import { addHighlight } from '../../libs/utilLib'

export const DivHtml = ({ html, title, searchText, className, style }) => {
    // const { gState } = useContext(GlobalContext)
    const [state, setState] = useState({
        className,
        // showLen

    })

    const onClick = () => {
        // gState.showInfoModal({header: 'Info', body: html, size: 'xl', canCancel: false, canJson: true})
        if (className === state.className) setState({...state, className: ''})
        else setState({...state, className})

        // setState({...state, showLen: state.showLen === showLen ? 0 : showLen})
    }

    if (!html) return html
    let _html = html
    if (/<\/|\/>/.test(html)) _html = _html.replace(/(<script )/gi, '<illegalscript ')

    let _style = {...(style  || {})}
    if (className !== state.className) _style.whiteSpace = 'normal'
    // if (showLen) {
    //     if (state.showLen && state.showLen < _html.length) {
    //         _html = _html.substring(0, state.showLen) + '...'
    //         _style.whiteSpace = 'nowrap'
    //     } 
    //     else {
    //         _style.whiteSpace = 'normal'
    //     }
    // }
    if (searchText) _html = addHighlight(_html.replace(/\n/g, '<br/>'), searchText)

    return (
        <div dangerouslySetInnerHTML={{ __html: _html }} onClick={onClick} title={title} className={state.className} style={_style} />
    )
}