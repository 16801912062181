import React from 'react'
import { EditWatchlist } from '.'

export const AddWatchlistPage = () => {
  const watchlist = { 
    name: '', 
    symbols: '',
    description: '',
  }

  return (
    <>
      <EditWatchlist watchlist={watchlist}/>
    </>
  );
}
