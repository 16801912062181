import { useState, useEffect } from 'react'
import { apiGetList } from '../../libs/apiLib'
import { Checkbox, LinkButton, MyPopover, useInterval } from '../widgets'
import { toPercent, toCurrency } from '../../libs/utilLib'
import { format } from 'date-fns'
import { sortBy } from 'lodash'
import { EditSymbolsModal } from '../quote'
import { FaEdit, FaEllipsisV, FaSync } from 'react-icons/fa'
// import { toast } from 'react-toastify'
import { SparkAreaChart } from '../chart'
// import { useTabActive } from '../widgets'

// let _refreshStart = new Date()
export const Headline = () => {
    const prefix = 'Headline'
    // const isTabActive = useTabActive()
    const [state, setState] = useState({
        show: true,
        working: true,
        symbols: JSON.parse(localStorage.getItem(`${prefix}_symbols`)) || '^DJI,^IXIC,^GSPC,^RUT,CL=F',
        refreshInterval: JSON.parse(localStorage.getItem(`${prefix}_refreshInterval`) || 600),
        showSpark: JSON.parse(localStorage.getItem(`${prefix}_showSpark`) || true),
        refreshIn: '',
        refreshedAt: new Date(),
        items: null,
    })

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.symbols])

    const fetchData = async (fetchFirst = false) => {
        setState({ ...state, working: true })
        const result = await apiGetList({
            model: 'company', filter: { symbols: state.symbols },
            select: { yearlyQuotes: 0 },
            fetchFirst, source: 'ysparkline', range: '1d'
        })
        setState({ ...state, items: result?.items, refreshedAt: new Date(), working: false })
    }

    useInterval(() => {
        const dt = new Date()
        const day = dt.getDay()
        const hour = dt.getHours()
        if (!state.show || day === 0 || day === 6 || hour < 8 || hour >= 17) return
        fetchData(true)
    }, 5 * 1000 * 60)

    const onYahoo = (item) => {
        window.open(`https://finance.yahoo.com/chart/${item.symbol}`)
    }

    const onEdit = () => {
        setState({ ...state, editing: true })
    }

    const onOK = (symbols) => {
        localStorage.setItem(`${prefix}_symbols`, JSON.stringify(symbols))
        setState({ ...state, symbols, editing: false })
    }

    const onCancel = () => {
        setState({ ...state, editing: false })
    }

    const onToggle = (name) => {
        localStorage.setItem(`${prefix}_${name}`, JSON.stringify(!state[name]))
        setState({ ...state, [name]: !state[name] })
    }

    // if (state.loading) return <Loading />
    if (!state.items) return null
    const items = sortBy(state.items, x => state.symbols.split(',').indexOf(x.symbol))
    return (
        <div className="f-sm text-center nowrap" style={{ maxWdith: "100%", overflow: 'auto' }}>
            {state.show && <div className="d-inline-block text-muted c-pointer me-2" onClick={e => window.open('https://finance.yahoo.com/news/')} style={{ verticalAlign: 'top' }}>
                <span>{format(state.refreshedAt, 'M/dd')}</span>
                <br />
                <span>{format(state.refreshedAt, 'H:mm')}</span>
            </div>}
            {state.show && items?.map((x, i) => <div key={i} title={x.name} className="d-inline-block c-pointer me-1" onClick={e => onYahoo(x)}>
                <div className="d-inline-block" style={{ verticalAlign: 'top' }}>
                    <span className="bold text-muted">{x.symbol}</span><span className="bold">{toCurrency(x.price)}</span>
                    <br />
                    <span className={x.change < 0 ? 'red' : 'blue'}>{toCurrency(x.change)} ({toPercent(x.changePercent/100.0, 2)})</span>
                </div>
                {state.showSpark && <div className="d-inline-block mt-1"><SparkAreaChart dates={x.dailyQuotes.dates} values={x.dailyQuotes.prices} width={100} height={35} /></div>}
            </div>)}
            <MyPopover button={<FaEllipsisV className="mt-1 grey" style={{ verticalAlign: 'top' }} />}>
                <LinkButton icon={<FaSync className={state.working ? 'spinner' : ''} />} text="Refresh" onClick={e => fetchData(true)} className="ps-0 pt-0" />
                <hr className="my-1" />
                <LinkButton icon={<FaEdit />} text="Edit symbols" onClick={onEdit} className="ps-0" />
                <hr className="my-1" />
                <div><Checkbox label="Show sparkline" checked={state.showSpark} onCheck={e => onToggle('showSpark')} /></div>
                <LinkButton text={`${state.show ? 'Hide' : 'Show'} quotes`} onClick={e => onToggle('show')} className="ps-0" />
            </MyPopover>
            {state.editing && <EditSymbolsModal header={`Edit headline symbols`} symbols={state.symbols} onOK={onOK} onCancel={onCancel} />}
        </div>
    )
}
