import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

export const IconTip = ({tooltip, id='myTooltip', placement='auto', icon, className='', style}) => {

  const renderTooltip = <Tooltip id={id} className={!tooltip && "d-none" }>{tooltip}</Tooltip>

  return (
    <OverlayTrigger placement={placement} flip overlay={renderTooltip}>
      <span className={className} style={style}>
        {icon}
      </span>
    </OverlayTrigger>
  )
}
