import axios from 'axios'
const host = 'http://127.0.0.1:3000'
// REST API endpoint: https://x8970jp6zd.execute-api.us-east-1.amazonaws.com/dev
// Hosting endpoint: https://d2w0dfir47hcag.cloudfront.net

const headers = {
    "Accept": "application/hal+json,text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,*/*;q=0.8",
    "Content-Type": "application/json;charset=UTF-8"
    // "x-apigateway-context": "xxx"
}

const apiGetList = async (args) => {
    const result = await axios.get(`${host}/api/list?args=${encodeURIComponent(JSON.stringify(args))}`, { headers })
    return result.data
}

const apiGetOne = async (args) => {
    const result = await axios.get(`${host}/api/one?args=${encodeURIComponent(JSON.stringify(args))}`, { headers })
    return result.data
}

const apiGetDistinct = async (args) => {
    const result = await axios.get(`${host}/api/distinct?args=${encodeURIComponent(JSON.stringify(args))}`, { headers })
    return result.data
}

const apiPost = async (table, body) => {
    const result = await axios.post(`${host}/api/${table}`, body, { headers })
    return result.data
}

const apiPut = async (table, id, body) => {
    const result = await axios.put(`${host}/api/${table}/${id}`, body, { headers })
    return result.data
}

const apiDelete = async (table, id, args) => {
    const result = await axios.delete(`${host}/api/${table}/${id}` + (args ? `?args=${encodeURIComponent(JSON.stringify(args))}` : ''), { headers })
    return result.data
}

export const localAPIs = {
    apiGetList, apiGetOne, apiGetDistinct, apiPost, apiPut, apiDelete
}