import { useState } from 'react'
import { Table } from "react-bootstrap"
import { ALink, ConfirmButton, DateTime, LinkButton } from "../widgets"
import { convertUnicode, trimTo } from "../../libs/utilLib"
import { FaChevronRight, FaEdit, FaTrash } from "react-icons/fa"

export const News = ({ items, doSearch, doDelete }) => {
    const [state, setState] = useState({
        hovering: false
    })

    const onHover = (item) => {
        item.hovering = !item.hovering
        setState({ ...state })
    }

    const onSearch = (item) => {
        doSearch(item.symbols, trimTo(item.headline, 50) + ` [${item.publishDate}]`)
    }

    return (
        <div className="clear-both" style={{ maxWdith: "100%", overflow: 'auto' }}>
            <Table size="sm" hover className="f-sm">
                <tbody>
                    {items?.map((x, i) => <tr key={i}>
                        <td onMouseEnter={e => onHover(x)} onMouseLeave={e => onHover(x)}>
                            {x.hovering && <div className="float-end">
                                <LinkButton icon={<FaEdit />} to={`/news/${x._id}/edit`} className="py-0 pe-0" />
                                <ConfirmButton icon={<FaTrash />} variant="link" confirmText="Delete" onClick={e => doDelete(x)} className="ms-2 py-0 pe-0" />
                            </div>}
                            <div className="f-xs grey my-1">
                                {x.publisher && <>{x.publisher} &nbsp; &bull; &nbsp; </>}
                                <DateTime date={x.publishDate} />
                            </div>
                            <ALink href={x.sourceUrl} text={convertUnicode(x.headline?.replace(/\\n/g, ''))} />
                            {x.symbols && <div className="f-sm my-1">
                                {x.hovering && <div className="float-end"><LinkButton icon={<FaChevronRight />} onClick={e => onSearch(x)} title="Go quote view" className="py-0 pe-0" /></div>}
                                {x.symbols}
                            </div>}
                            {x.remarks && <div className="text-danger f-sm">{x.remarks}</div>}
                        </td>
                    </tr>)}
                </tbody>
            </Table>
        </div>
    )
}