import React, { useState, useEffect } from 'react'
import { Pagination, Form } from 'react-bootstrap'
import { MyPopover } from '.'
import { FaAngleDoubleLeft, FaAngleDoubleRight, FaAngleRight, FaAngleLeft } from 'react-icons/fa'
// import { GlobalContext } from '../common'

export const PagingBox = ({ skip, limit, total, setPage, className }) => {
    // const { gState } = useContext(GlobalContext)
    const pages = [10, 20, 50, 100, 200, 500, 1000]
    const [state, setState] = useState({
        skip: skip,
        limit: limit,
        total: total
    })

    useEffect(() => {
        setState(state => ({ ...state, skip: skip || 0, limit: limit || 25 }))
    }, [skip, limit])

    useEffect(() => {
        setState({ ...state, total })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [total])

    const goTo = (skip, limit) => {
        limit = limit || state.limit
        // if (limit && skip + limit > state.total) skip = Math.max(state.total - limit, 0)
        setState({ ...state, skip, limit })
        setPage(skip, limit)
    }

    const canGoFirst = () => state.skip > state.limit
    const canGoPrev = () => state.skip > 0
    const canGoNext = () => state.skip < state.total - state.limit
    const canGoLast = () => state.skip < state.total - state.limit - 1

    if (total < 1) return null
    const msg = `${state.skip + 1} - ${Math.min(state.skip + state.limit, state.total)} / ${state.total}`
    return (
        <span className={className}>
            <Pagination size="sm" className="d-inline-flex mb-0">
                {canGoFirst() && <Pagination.First onClick={e => goTo(0)}><FaAngleDoubleLeft /></Pagination.First>}
                <Pagination.Prev onClick={e => goTo(Math.max(state.skip - state.limit, 0))} disabled={!canGoPrev()}><FaAngleLeft /></Pagination.Prev>

                <MyPopover title="" button={<Pagination.Item>{msg}</Pagination.Item>}>
                    {/* {renderPopover()} */}
                    <div>Page Size</div>
                    {pages.map(x => <div key={x} onClick={e => setPage(state.skip, x)} className="my-2"><Form.Check type="radio" custom="true" checked={x === state.limit} label={`${x} items`} readOnly /></div>)}
                    <div onClick={e => setPage(state.skip, 9999)} className="my-2"><Form.Check type="radio" custom="true" checked={state.limit === 9999} label={`All items`} readOnly /></div>
                </MyPopover>

                <Pagination.Next onClick={e => goTo(Math.min(state.skip + state.limit, state.total - 1))} disabled={!canGoNext()}><FaAngleRight /></Pagination.Next>
                {canGoLast() && <Pagination.Last onClick={e => goTo(state.total - state.limit)}><FaAngleDoubleRight /></Pagination.Last>}
            </Pagination>
        </span>
    )
}
