import { useState, useEffect } from 'react'
import { Container } from 'react-bootstrap'
import { Checkbox, MyPopover, Loading } from '../widgets'
import { FaCaretDown } from 'react-icons/fa'
import { apiGetDistinct } from '../../libs/apiLib'
import { toast } from 'react-toastify'
import { QuotesView } from '../quote'

export const HomePage = () => {
    const prefix = 'HomePage'
    const [sectors, setSectors] = useState(null)
    const [industries, setIndustries] = useState(null)
    const [state, setState] = useState({
        working: true,
        selectedSector: JSON.parse(localStorage.getItem(`${prefix}_selectedSector`)),
        selectedIndustry: JSON.parse(localStorage.getItem(`${prefix}_selectedIndustry`)),
    })

    useEffect(() => {
        fetchSectors()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (state.selectedSector) fetchIndustries()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.selectedSector])

    const fetchSectors = async () => {
        setState({ ...state, working: true })
        const result = await apiGetDistinct({ model: 'company', field: 'sector' })
        if (result?.error) toast.error(result.error)
        let sectors = [{ name: 'All', checked: !state.selectedSector }, ...result.map(x => ({ name: x, checked: x === state.selectedSector }))]
        setSectors(sectors)
        setState({ ...state, working: false })
    }

    const fetchIndustries = async () => {
        setState({ ...state, working: true })
        const result = await apiGetDistinct({ model: 'company', query: { sector: state.selectedSector }, field: 'industry' })
        if (result?.error) toast.error(result.error)
        let industries = [{ name: 'All', checked: !state.selectedIndustry }, ...result.map(x => ({ name: x, checked: x === state.selectedIndustry }))]
        setIndustries(industries)
        setState({ ...state, working: false })
    }

    const onSector = (name) => {
        let _sectors = sectors
        _sectors.forEach(x => x.checked = x.name === name)
        setSectors(_sectors)
        let selectedSector = name === 'All' ? null : name
        localStorage.setItem(`${prefix}_selectedSector`, JSON.stringify(selectedSector))
        localStorage.setItem(`${prefix}_selectedIndustry`, JSON.stringify(null))
        setState({ ...state, selectedSector, selectedIndustry: null })
    }

    const onIndustry = (name) => {
        let _industries = industries
        _industries.forEach(x => x.checked = x.name === name)
        setIndustries(_industries)
        let selectedIndustry = name === 'All' ? null : name
        localStorage.setItem(`${prefix}_selectedIndustry`, JSON.stringify(selectedIndustry))
        setState({ ...state, selectedIndustry })
    }

    const header = () => <>
        <span className="f-sm">Sector:</span> <MyPopover button={<span className="link me-3">{state.selectedSector || 'All'} <FaCaretDown /></span>} style={{ minWidth: 280 }}>
            <div className="bold my-2">Sectors:</div>
            {sectors?.map((x, i) => <div key={i}>
                <div><Checkbox type="radio" label={x.name} checked={x.checked} onCheck={e => onSector(x.name)} /></div>
            </div>)}
        </MyPopover>
        {
            state.selectedSector && <>
                <span className="f-sm">Industry:</span> <MyPopover button={<span className="link me-3">{state.selectedIndustry || 'All'} <FaCaretDown /></span>} style={{ minWidth: 280 }}>
                    <div className="bold my-2">Industries:</div>
                    {industries?.map((x, i) => <div key={i} className="clear-both">
                        <div><Checkbox type="radio" label={x.name} checked={x.checked} onCheck={e => onIndustry(x.name)} /></div>
                    </div>)}
                </MyPopover>
            </>
        }
    </>

    if (state.working) return <Loading />
    const watchlist = { _id: '_browse_', name: 'Browse', sector: state.selectedSector, industry: state.selectedIndustry }
    document.title = "Home - " + global.appName
    return (
        <Container fluid>
            <QuotesView watchlist={watchlist} header={header()} sector={state.selectedSector} industry={state.selectedIndustry} />
        </Container>
    )
}