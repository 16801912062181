import { Fragment, useState } from 'react'
import { FaChevronDown, FaChevronUp } from "react-icons/fa"
import { toCurrency } from "../../libs/utilLib"
import { LinkButton, ThSort } from "../widgets"
import { Table } from 'react-bootstrap'
import { orderBy } from 'lodash'

export const ReportInterestsByCategory = ({ header, items }) => {
    const prefix = 'ReportInterestsByCategory'
    const [state, setState] = useState({
        expanded: false,
        sortBy: JSON.parse(localStorage.getItem(`${prefix}_sortBy`)) || 'amount',
        sortDir: JSON.parse(localStorage.getItem(`${prefix}_sortDir`)) || 'asc',
    })

    const onExpand = (e) => {
        e.preventDefault()
        setState({...state, expanded: !state.expanded})
    }

    const onSort = (sortBy) => {
        const sortDir = sortBy === state.sortBy ? (state.sortDir === 'desc' ? 'asc' : 'desc') : 'asc'
        localStorage.setItem(`${prefix}_sortBy`, JSON.stringify(sortBy))
        localStorage.setItem(`${prefix}_sortDir`, JSON.stringify(sortDir))
        setState({ ...state, sortBy, sortDir })
    }

    const total = items?.reduce((t, x) => t += x.amount || 0, 0)
    if (!items?.length || !total) return null
    const { sortBy, sortDir } = state
    if (sortBy && sortDir) items = orderBy(items, [sortBy], [sortDir])
    const count = items.filter(x => x.amount).length
    return (
        <div>
            <div className="float-end bold"><div className={total < 0 ? 'red' : ''}>{toCurrency(total) || '$0'}</div></div>
            <b className='blueviolet'>{header} [{count}] <LinkButton icon={state.expanded ? <FaChevronUp /> : <FaChevronDown />} onClick={e => onExpand(e)} className="pe-0" /></b>
            {state.expanded && <div className="clear-both" style={{ maxWdith: "100%", overflow: 'auto', paddingRight: 5 }}>
                <Table size="sm" hover className="nowrap">
                    <tbody>
                        <tr>
                            <th>#</th>
                            <ThSort id="accountName" text="Account name" sortBy={sortBy} sortDir={sortDir} onSort={onSort} />
                            <ThSort id="amount" text="Amount" sortBy={sortBy} sortDir={sortDir} onSort={onSort} className="text-end" />
                        </tr>
                        {items?.filter(x => x.amount > 0).map((x, i) => <Fragment key={i}>
                            <tr>
                                <td>{i+1}</td>
                                <td className='black'>{x.accountName}</td>
                                <td className="black text-end">{toCurrency(x.amount)}</td>
                            </tr>
                        </Fragment>)}
                    </tbody>
                </Table>
            </div>}
        </div>
    )
}