import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { DivHtml, LinkButton, TextInput } from '../widgets'
import { FaPaste } from 'react-icons/fa'

export const EditSymbolsModal = ({ header, symbols, onOK, onCancel }) => {
    const [state, setState] = useState({
        symbols
    })

    const onChange = ({ target }) => {
        let value = target.type === 'number' ? +target.value : target.value
        setState({ ...state, [target.name]: value, error: '' })
    }

    const onPaste = async (e) => {
        e.preventDefault()
        let text = await navigator.clipboard.readText()
        if (!text) return
        let table = []
        let lines = text.split(/\r?\n/)
        lines.forEach(x => {
            table.push(x.split('\t'))
        })
        let n = 0
        let skip = 0
        if (table.length > 0 && table[0].includes('Symbol')) {
            n = table[0].findIndex(x => x === 'Symbol')
            skip = 1
        }
        let symbols = state.symbols?.trim().split(/\s+|\s*,\s*|\s*\+\s*/) || []
        table.forEach((x, i) => {
            if (i >= skip) {
                symbols.push(x[n])
            }
        })
        setState({...state, symbols: [...new Set(symbols)].filter(x => x).join(' ')})
    }

    return (
        <Modal show={true} size="lg" onHide={onCancel}>
            <Modal.Header closeButton>
                <Modal.Title>{header}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className="float-end"><LinkButton icon={<FaPaste/>} onClick={onPaste}/></div>
                <TextInput header="Symbols:" as="textarea" rows={5} name='symbols' value={state.symbols} onChange={onChange}/>
                <div className="f-sm float-end">{state.symbols?.trim().split(/\s+|\s*,\s*|\s*\+\s*/).length}</div>
                <DivHtml className="f-sm grey" html="Use comma, space or + sign as separators." />
            </Modal.Body>

            <Modal.Footer>
                <LinkButton variant="secondary" onClick={onCancel} text="Cancel" />
                <LinkButton variant="primary" onClick={e => onOK(state.symbols)} text="OK" style={{ minWidth: 70 }} />
            </Modal.Footer>
        </Modal>
    )
}
