import { useState, useEffect, Fragment } from 'react'
import { useParams } from 'react-router-dom'
import { apiGetOne, apiGetList } from '../../libs/apiLib'
import { Loading, MyPopover, Checkbox, TextInput, LinkButton } from '../widgets'
import { FaCaretDown, FaCaretUp, FaChevronUp, FaChevronDown } from 'react-icons/fa'
// import { AccountPerformanceChart } from '.'
import { AccountPerformanceChart } from '.'
import { Container, Table } from 'react-bootstrap'
import { addMonths, startOfDay, format } from 'date-fns'
import { toNum } from '../../libs/utilLib'
import { timeRanges } from '../../libs/consts'
import { orderBy } from 'lodash'

export const AccountPerformancePage = () => {
  const prefix = 'AccountPerformancePage'
  const { id: accountId } = useParams()
  const [state, setState] = useState({
    loading: true,
    account: null,
    timeRangeId: JSON.parse(localStorage.getItem(`${prefix}_timeRangeId`)) || '1y',
    compareSymbols: JSON.parse(localStorage.getItem(`${prefix}_compareSymbols`)) || '^DJI QQQ',
  })

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.timeRangeId])
  useEffect(() => {
    fetchCompare()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.compareSymbols])

  const fetchData = async () => {
    let startDate =  format(startOfDay(addMonths(new Date(), -(timeRanges.find(x => x.id === state.timeRangeId).value * 12))), "yyyy-MM-dd");
    const account = await apiGetOne({ model: 'account', filter: { _id: accountId }, populates: [{ path: 'perfs', match: {date: {$gte: startDate}}, options: { sort: { 'date': 1 } } }] })
    const compareTo = await getHists(account.perfs?.[0].date)
    setState({ ...state, account, compareTo, loading: false })
  }

  const fetchCompare = async (account) => {
    account = account || state.account
    if (!account) return
    const compareTo = await getHists(account.perfs?.[0].date)
    setState({ ...state, compareTo })
  }

  const getHists = async (start) => {
    let arrSymbols = state.compareSymbols?.split(/\s*[ |,|;]\s*/)
    const result = {}
    for (let i = 0; i < arrSymbols?.length; i++){
      let symbol = arrSymbols[i]
      const { items } = await apiGetList({ model: 'hist', filter: { symbol, date: { $gte: start } }, select: {date: 1, close: 1}, sort: { date: 1 } })
      if (items?.length) result[symbol] = items
    }
    return result
  }   

  const onToggle = (name, item) => {
    if (item) {
      item[name] = !item[name]
      setState({...state})
    } else {
      setState({...state, [name]: !state[name]})
    }
  }

  const onCheckTimeRange = (item) => {
    localStorage.setItem(`${prefix}_timeRangeId`, JSON.stringify(item.id))   
    setState({...state, timeRangeId: item.id})
  }

  const onOK = (e, text) => {
    localStorage.setItem(`${prefix}_compareSymbols`, JSON.stringify(text))
    setState({...state, compareSymbols: text})
  }


  if (state.loading) return <Loading />
  document.title = `Performance for ${state.account.name} - ${global.appName}`
  const timeRange = timeRanges.find(x => x.id === state.timeRangeId)
  return (
    <Container fluid>
      <div>
        <MyPopover header="Time range" button={<><b>Time range:</b> {timeRange?.name || ''} <FaCaretDown className="p-cursor link ms-2 me-3"/></>}>
          <div style={{minWidth: 100}}>
            {timeRanges.map((x, i) => <div key={i}>
              {x.name === '-' ? <hr className='my-1'/> : <Checkbox label={x.name} checked={x.id === state.timeRangeId} onCheck={e => onCheckTimeRange(x)} />}
            </div>)}
          </div>
        </MyPopover>
        <MyPopover header="Compare to" button={<><b>Compare to:</b> {state.compareSymbols || ''} <FaCaretDown className="p-cursor link ms-2"/></>}>
          <div style={{minWidth: 200}}>
            {/* <LinkButton onClick={onOK} className="float-end">OK</LinkButton> */}
            <TextInput type="text" header="Ticker symbols" name="compare" value={state.compareSymbols} onEnter={onOK}/>
          </div>
        </MyPopover>
      </div>
      {/* <AccountPerformanceChart items={state.account.perfs} /> */}
      <AccountPerformanceChart symbol={state.account.name} hists={state.account.perfs} compareTo={state.compareTo} onRefresh={fetchData} />
      <div>
        <LinkButton icon={state.expanded ? <FaChevronUp/> : <FaChevronDown/>} onClick={e => onToggle('expanded')} className='float-end'/>
        <h4>Details</h4>
      </div>
      {state.expanded && <div className="nowrap clear-both" style={{ maxWdith: "100%", maxHeight: 800, overflow: 'auto', paddingRight: 5 }}>
        <Table size="sm" hover>
          <thead>
            <tr><th>#</th><th>Date</th><th>Open</th><th>High</th><th>Low</th><th>Close</th><th>Cash</th><th>Holds</th></tr>
          </thead>
          <tbody>
            {orderBy(state.account.perfs, ['date'], ['desc']).map((x, i) => <Fragment key={i}>
              <tr>
                <td>{i+1}</td>
                <td>{x.date}</td>
                <td>{toNum(x.open)}</td>
                <td>{toNum(x.high)}</td>
                <td>{toNum(x.low)}</td>
                <td>{toNum(x.close)}</td>
                <td>{toNum(x.cash)}</td>
                <td>
                  {x.holds?.map(y => `${y.symbol}: ${toNum(y.quantity, 0)}`).join(', ')}
                  <LinkButton icon={!x.expanded ? <FaCaretDown/> : <FaCaretUp/>} onClick={e => onToggle('expanded', x)}/>
                </td>
              </tr>
              {x.expanded && <tr>
                <td colSpan={50}>
                  <Table size="sm" className="mb-0">
                    <tbody>
                    <tr>
                      <th>Symbol</th>
                      {x.holds?.map((y, j) => <th key={j}>{y.symbol}</th>)}
                    </tr>
                    <tr>
                      <td>Qty</td>
                      {x.holds?.map((y, j) => <td key={j}>{toNum(y.quantity, 4, true)}</td>)}
                    </tr>
                    <tr>
                      <td>Price</td>
                      {x.holds?.map((y, j) => <td key={j}>{toNum(y.price)}</td>)}
                    </tr>
                    </tbody>
                  </Table>
                </td>
              </tr>}
            </Fragment>)}
          </tbody>
        </Table>
      </div>}
    </Container>
  )
}
