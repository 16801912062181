import React, { useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { Modal, Form } from 'react-bootstrap'
import { LinkButton, Loading } from '../widgets'
import { apiPost } from '../../libs/apiLib'
// import { useNavigate } from 'react-router-dom'
// import { GlobalContext } from '../helpers'

export const SyncBankPage = () => {
    // const { gState } = useContext(GlobalContext)
    // const navigate = useNavigate()
    const { bank } = useParams()
    const [searchParams] = useSearchParams()
    const verifier = searchParams.get('oauth_verifier')
    const [state, setState] = useState({
        working: true,
    })

    useEffect(() => {
        onSubmit()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // const onCheck = (name) => {
    //     setState({ ...state, [name]: !state[name] })
    // }

    // const onChange = ({ target }) => {
    //     let { name, type, value } = target
    //     if (type === 'number') value = +value
    //     setState({ ...state, [name]: value, error: '' });
    // }

    const onSubmit = async () => {
        let result = await apiPost('sync/account/perform', { accessCode: verifier })
        if (result && !result.error) {
            console.log(result)
            setState({ ...state, working: false, success: result.success })
            // onClose()
        }
        else {
            setState({ ...state, error: result?.error || 'Server error', working: false })
        }
    }

    const onClose = () => {
        // navigate(`/user/${gState.loginUser?._id}/accounts`)
        window.open("about:blank", "_self");
        window.close();
    }

    return (
        <Modal show={true}>
            <Form>
                <Modal.Header>
                    <Modal.Title>Sync accounts from {bank}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="py-3">
                    {state.working ? <Loading text="Syncing in progress. Please wait..." size="h4" /> : state.error ? <div className="text-danger">Sorry, sync failed. Please try again.</div> : <div className="text-success">Sync successful.</div>}

                    {/* <TextInput type="text" header="Verification code" value={verifier} />
                    <div><Checkbox label="Account balance" checked={!!state.balance} onCheck={e => onCheck('balance')} /></div>
                    <div><Checkbox label="Orders" checked={!!state.orders} onCheck={e => onCheck('orders')} /></div>
                    <div><Checkbox label="Transactions" checked={!!state.transactions} onCheck={e => onCheck('transactions')} /></div> */}
                </Modal.Body>
                <Modal.Footer>
                    {/* <CancelButton onClick={onClose} /> */}
                    <LinkButton variant="primary" onClick={onClose} text="OK" style={{ minWidth: 70 }} />
                    {/* <SubmitButton disabled={state.working} onClick={onSubmit} className="ms-2" /> */}
                </Modal.Footer>
            </Form>
        </Modal>
    )
}
