import { useState, useEffect } from 'react'
import { Form, Row, Col, ButtonGroup } from 'react-bootstrap'
import { FaCaretDown, FaArrowDown, FaArrowUp, FaTimes } from 'react-icons/fa'
import { MyPopover, LinkButton } from '../widgets'
import { orderBy } from 'lodash'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { toast } from 'react-toastify'
import { apiGetList, apiPost } from '../../libs/apiLib'
import { isLastWorkDay } from '../../libs/utilLib'

export const TransactionsChart = ({ items, columns, onClose }) => {
    const prefix = 'TransactionsChart'
    const [state, setState] = useState({
        height: JSON.parse(localStorage.getItem(`${prefix}_height`)) || 500,
        categoryColumn: 'date',
        seriesColumn: 'price'
    })

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items])

    const fetchData = async () => {
        if (!items.length) return
        const symbol = items[0].symbol
        const startDate = items[0].date
        // const endDate = items[items.length-1].date
        const { items: hists } = await apiGetList({ model: 'hist', filter: { symbol, date: { $gte: startDate } }, select: {date: 1, close: 1}, sort: { date: 1 } })
        // console.log(symbol, startDate, endDate, hists)
        if (!hists?.length || !isLastWorkDay(hists[hists.length - 1].date)) {
            await onDownload(symbol, hists?.[hists?.length - 1]?.date || startDate, hists)
        } else setState({ ...state, hists, loading: false, downloading: false })
    }
    
    const onDownload = async (symbol, fromDate, hists) => {
        setState({ ...state, downloading: true })
        fromDate = fromDate || state.fromDate
        // toDate = toDate || state.toDate
        const resp = await apiPost('fetch/hists', { symbol, fromDate })
        // console.log(fromDate, toDate, resp)
        toast(`${resp.items?.length || 0} quotes downloaded.`)
        // console.log(items)
        hists = hists || []
        resp.items?.forEach(x => {
            if (!hists?.find(y => y._id === x._id)) hists.push(x)
        })
        // console.log(items)
        if (hists?.length) setState({ ...state, hists, loading: false, downloading: false })
    }

    const onSelect = (name, value) => {
        setState({ ...state, [name]: value })
    }

    const onResize = (size) => {
        let { height } = state
        if (height === null || height === undefined) height = 400
        height = Math.max(height + size, 0)
        localStorage.setItem(`${prefix}_height`, JSON.stringify(height))
        setState({ ...state, height })
    }

    const getChartSeries = () => {
        if (state.seriesColumn === 'balance') {
            orderBy(items, ['date']).reduce((t, x) => { x.balance = (t += -x.amount); return t }, 0)
        }
        let series = [
            { name: 'Quantity', type: 'column', pointWidth: 5, yAxis: 0, data: items.filter(x => x.quantity).map(x => ([new Date(x.date).getTime(), x['quantity']])) },
            { name: seriesName, type: 'scatter', yAxis: 1, data: items.filter(x => x[state.seriesColumn]).map(x => ([new Date(x.date).getTime(), x[state.seriesColumn]])) },
        ]
        if (seriesName === 'Price') {
            series.push({ name: 'Quote', type: 'line', yAxis: 1, data: state.hists?.map(x => ([new Date(x.date).getTime(), x.close])) })
        }
        // console.log(series)
        return { series }
    }

    const chartOptions = ({ series, height }) => {
        return {
            credits: { enabled: false }, accessibility: { enabled: false },
            chart: { height },
            title: { text: '' },
            legend: { enabled: true, x: 0, y: 0, align: 'right', verticalAlign: 'top', floating: true },
            tooltip: {
                enabled: true,
                formatter: function () {
                    return '<b>' + Highcharts.dateFormat('%Y-%m-%d %H:%m:%S', new Date(this.x)) + '</b>: ' + this.y;
                }
            },
            xAxis: {
                // tickInterval: (24 * 3600 * 1000), // the number of milliseconds in a day
                allowDecimals: false, crosshair: true,
                title: {
                    text: 'Date',
                    scalable: false
                },
                type: 'datetime',
                labels: {
                    formatter: function () {
                        return Highcharts.dateFormat('%Y-%m-%d', (this.value));
                    }
                }
            },
            yAxis: [{ title: { text: 'Quantity' } }, { title: { text: seriesName }, crosshair: true, opposite: true }],
            series
        }
    }

    if (!items) return null
    const seriesName = columns.find(x => x.id === state.seriesColumn)?.name
    return (
        <div className='mt-3'>
            <div>
                <div className="float-end">
                    <ButtonGroup>
                        <LinkButton variant="outline-secondary" icon={<FaArrowDown />} onClick={e => onResize(200)} className="" />
                        <LinkButton variant="outline-secondary" icon={<FaArrowUp />} onClick={e => onResize(-200)} className="" />
                    </ButtonGroup>
                    <LinkButton variant="outline-secondary" icon={<FaTimes />} onClick={onClose} className="pr-0 ms-2" />
                </div>
                <h5 className="d-inline-block me-2">Charts</h5>
                <MyPopover button={<FaCaretDown />}>
                    <div className="bold mb-2">Series:</div>
                    <Row className="clear-both">
                        {columns.filter(x => 'number,currency'.includes(x.type)).map((x, i) => <Col key={i} xs={6}>
                            <span onClick={e => onSelect('seriesColumn', x.id)}><Form.Check type="radio" checked={x.id === state.seriesColumn} label={x.name} readOnly /></span>
                        </Col>)}
                    </Row>
                </MyPopover>
                {/* Series:<MyPopover button={<LinkButton iconEnd={<FaCaretDown />} text={seriesName} className="mt-n1 me-3" />}>
                    <div className="text-left">
                        {columns.filter(x => 'number,currency'.includes(x.type)).map((x, i) => <div key={i}>
                            <span onClick={e => onSelect('seriesColumn', x.id)}><Form.Check type="radio" checked={x.id === state.seriesColumn} label={x.name} readOnly /></span>
                        </div>)}
                    </div>
                </MyPopover> */}
            </div>

            <div>
                <HighchartsReact highcharts={Highcharts} options={chartOptions({ ...getChartSeries(), height: state.height })} />
            </div>
        </div>
    )
}