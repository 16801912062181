// import { getUnique, getTimeDiffInText } from "./utilLib"

export const quotesView = {
    searchFields: 'symbol,name',
    columns: [
        //   { id: 'index', name: '#', visible: false },
        //   { id: 'idx', name: 'Idx', visible: false },
        { id: 'symbol', name: 'Symbol', visible: true, trimTo: 20 },
        //   { id: 'favor', name: '', visible: true },
        //   { id: 'chart', name: '', visible: true },
        { id: 'name', name: 'Name', visible: true, trimTo: 25 },
        { id: 'price', name: 'Last price', type: 'currency', visible: true, className: 'text-end' },
        { id: 'change', name: 'Chg', type: 'currency', visible: true, className: 'text-end' },
        { id: 'changePercent', name: 'Chg %', type: 'percent', visible: true, className: 'text-end' },
        { id: 'volume', name: 'Vol (K)', type: 'number', visible: false, className: 'text-end' },
        { id: 'lastTraded', name: 'Time', type: 'date', visible: true, className: "text-center" },
        { id: 'bidSize', name: 'BS', visible: false, className: 'text-end' },
        { id: 'bidPrice', name: 'Bid', visible: false, className: 'text-end' },
        { id: 'askPrice', name: 'Ask', visible: false, className: 'text-start' },
        { id: 'askSize', name: 'AS', visible: false, className: 'text-start' },
        { id: 'prevClose', name: 'PrvCls', type: 'currency', visible: false, className: 'text-end' },
        { id: 'open', name: 'Open', type: 'currency', visible: false, className: 'text-end' },
        { id: 'dayRange', name: 'Day range', visible: true, className: 'text-center' },
        { id: 'dayLow', name: 'Day low', type: 'currency', visible: false, className: 'text-end' },
        { id: 'dayHigh', name: 'Day high', type: 'currency', visible: false, className: 'text-end' },
        { id: 'avgVolume', name: 'Vol avg', type: 'number', visible: false, className: 'text-end' },
        { id: 'yearRange', name: 'Year range', visible: true, className: 'text-center' },
        { id: 'yearLow', name: 'Year low', type: 'currency', visible: false, className: 'text-end' },
        { id: 'yearHigh', name: 'Year high', type: 'currency', visible: false, className: 'text-end' },
        { id: 'position', name: 'Pos %', type: 'number', visible: false, className: 'text-end' },
        { id: 'upPercent', name: 'Up %', type: 'number', visible: false, className: 'text-end' },
        { id: 'downPercent', name: 'Down %', type: 'number', visible: false, className: 'text-end' },
        { id: 'mktCap', name: 'Mkt cap (M)', type: 'number', visible: true, className: 'text-end' },
        { id: 'beta', name: 'Beta', visible: false, className: 'text-end' },
        { id: 'pe', name: 'P/E', visible: true, className: 'text-end' },
        { id: 'eps', name: 'EPS', visible: true, className: 'text-end' },
        { id: 'targetPrice', name: 'Target', visible: false, className: 'text-end' },
        { id: 'earnDate', name: 'Earn date', type: 'date', visible: true },
        { id: 'divDate', name: 'Div date', type: 'date', visible: true },
        { id: 'divValue', name: 'Div $', visible: false, className: 'text-end' },
        { id: 'divYield', name: 'Div %', visible: true, className: 'text-end' },
        { id: 'divFreq', name: 'DF', visible: true },
        { id: 'divSince', name: 'Div since', visible: true },
        { id: 'expenseRatio', name: 'Exp %', visible: true, className: 'text-end' },
        { id: 'payoutRatio', name: 'Payout', visible: true, className: 'text-end' },
        { id: 'priceToSales', name: 'P/S', visible: false, className: 'text-end' },
        { id: 'priceToBook', name: 'P/B', visible: false, className: 'text-end' },
        { id: 'evToRevenue', name: 'Ev/Rev', visible: false, className: 'text-end' },
        { id: 'evToEbitda', name: 'Ev/Ebt', visible: false, className: 'text-end' },
        { id: 'ytdReturn', name: 'Ytd ret', visible: true, className: 'text-end' },
        { id: 't3mReturn', name: 'T3m ret', visible: true, className: 'text-end' },
        { id: 'exchange', name: 'Exch', visible: false },
        { id: 'yahooSymbol', name: 'Y symbol', visible: true },
        { id: 'dailySparkline', name: 'Day sparkline', visible: false },
        { id: 'yearlySparkline', name: 'Year sparkline', visible: false },
        { id: 'macd', name: 'MACD', visible: false },
        { id: 'rsi', name: 'RSI', visible: false },
        { id: 'sector', name: 'Sector', visible: false },
        { id: 'industry', name: 'Industry', visible: true },
        { id: 'kind', name: 'Kind', visible: false },
        { id: 'type', name: 'Type', visible: true },
        { id: 'year', name: 'Est', visible: true },
        { id: 'country', name: 'Country', visible: false },
        { id: 'hq', name: 'Hq', visible: true },
        { id: 'buyPrice', name: 'Buy price', visible: true, className: 'text-end' },
        { id: 'diffAmount', name: 'Diff $', visible: false, className: 'text-end' },
        { id: 'diffPercent', name: 'Diff %', visible: true, className: 'text-end' },
        { id: 'weight', name: 'Weight', visible: false, className: 'text-end' },
        { id: 'remarks', name: 'Remarks', visible: true },
        { id: 'pPrice', name: 'P-Price', visible: true, type: 'currency', className: 'text-end' },
        { id: 'pChange', name: 'P-Chg $', visible: true, type: 'currency', className: 'text-end', downColor: 'red', upColor: 'blue' },
        { id: 'pChangePercent', name: 'P-Chg %', visible: true, type: 'percent', className: 'text-end', downColor: 'red', upColor: 'blue' },
        { id: 'pLastTraded', name: 'P-Time', visible: true, type: 'date', fmt: 'yyyy-MM-dd', className: "text-center" },
        { id: 'updatedAt', name: 'Updated', type: 'date', fmt: 'yyyy-MM-dd', visible: true },
        // { id: 'remarks', name: 'Remarks', visible: true },
    ]
}

export const accountsView = {
    searchFields: 'name,type,bankName,mask,remarks,status',
    columns: [
        // { id: 'index', name: '#', visible: true },
        { id: 'name', name: 'Account name', type: 'text', visible: true, trimTo: 30, cantHide: true },
        { id: 'holdCount', name: 'Holds', visible: true },
        { id: 'type', name: 'Type', type: 'text', visible: true, trimTo: 25 },
        { id: 'bankName', name: 'Bank', type: 'text', visible: false, trimTo: 25 },
        // { id: 'clientName', name: 'Client', visible: true, trimTo: 25 },
        // { id: 'accountNo', name: 'AccNo', visible: false, trimTo: 25 },
        { id: 'mask', name: 'Mask', type: 'text', visible: true, trimTo: 25 },
        { id: 'balance', name: 'Actual value', visible: true, type: 'currency', className: 'text-end', showZero: true, title: 'This is coming from the website and it should match the market value. It shows in grey if matching and in red if not matching.' },
        { id: 'balUpdated', name: 'Updated', type: 'date', visible: true },
        { id: 'totalValue', name: 'Calc value', visible: true, type: 'currency', className: 'text-end bold', downColor: 'red' },
        { id: 'cash', name: 'Cash $', visible: true, type: 'currency', className: 'text-end' },
        { id: 'cashPercent', name: 'Cash %', visible: true, type: 'percent', digits: 1, className: 'text-end' },
        { id: 'security', name: 'Security', visible: true, type: 'currency', className: 'text-end' },
        { id: 'dayChange', name: 'Day win $', visible: true, type: 'currency', className: 'text-end bold', downColor: 'red', upColor: 'blue' },
        { id: 'dayChangePercent', name: 'Day %', visible: true, type: 'percent', digits: 2, className: 'text-end bold', downColor: 'red', upColor: 'blue' },
        { id: 'winAmount', name: 'Total win $', visible: true, type: 'currency', className: 'text-end', downColor: 'red', upColor: 'blue' },
        { id: 'winPercent', name: 'Total win %', visible: true, type: 'percent', digits: 2, className: 'text-end', downColor: 'red', upColor: 'blue' },
        { id: 'invested', name: 'Invested', visible: true, type: 'currency', className: 'text-end' },
        { id: 'diff', name: 'Diff', visible: true, type: 'currency', className: 'text-end' },
        { id: 'pct', name: 'Pct', visible: true, type: 'percent', className: 'text-end' },
        { id: 'fee', name: 'Fee', visible: true, type: 'currency', className: 'text-end' },
        { id: 'percentage', name: 'Portion', visible: true, type: 'percent', digits: 1, className: 'text-end' },
        { id: 'refAmount', name: 'Ref $', visible: true, type: 'currency', className: 'text-end', title: 'Standings on 7/11/2021', isRef: true },
        { id: 'refDiff', name: 'Ref diff', visible: true, type: 'currency', className: 'text-end', downColor: 'red', upColor: 'blue', isRef: true },
        { id: 'refGain', name: 'Ref gain', visible: true, type: 'currency', className: 'text-end', downColor: 'red', upColor: 'blue', isRef: true },
        { id: 'paycheck', name: 'Paycheck', visible: true, type: 'currency', className: 'text-end', isRef: true },
        { id: 'expense', name: 'Expense', visible: true, type: 'currency', className: 'text-end', downColor: 'red', upColor: 'blue', isRef: true },
        { id: 'transfer', name: 'Transfer', visible: true, type: 'currency', className: 'text-end', downColor: 'red', upColor: 'blue', isRef: true },
        // { id: 'holds', name: 'Holdings', visible: false, trimTo: 50 },
        { id: 'createdAt', name: 'Started at', type: 'date', fmt: 'yyyy-MM-dd', visible: false },
        { id: 'description', name: 'Description', visible: false, trimTo: 50 },
        { id: 'accountNo', name: 'Acc No', type: 'text', visible: false },
        { id: 'ownerName', name: 'Owner', type: 'text', visible: false, trimTo: 25 },
        { id: 'beneficiary', name: 'Beneficiary', type: 'text', visible: false, trimTo: 50 },
        { id: 'status', name: 'Status', type: 'text', visible: true, trimTo: 50 },
        { id: 'matureDate', name: 'Mature date', type: 'date', visible: true, fmt: 'yyyy-MM-dd' },
        { id: 'remarks', name: 'Remarks', type: 'text', visible: true, trimTo: 50 },
        { id: 'transCount', name: 'Trans', visible: true },
    ]
}

// export const accountSettings = {
//     searchFields: 'name,type,bankName,mask,remarks,status',
//     columns: [
//         { id: 'name', name: 'Name', visible: true, type: 'html' },
//         { id: 'type', name: 'Type', visible: true, type: 'html' },
//         { id: 'bankName', name: 'Bank', visible: true, type: 'html' },
//         { id: 'mask', name: 'Mask', visible: true, type: 'html' },
//         { id: 'balUpdated', name: 'Updated', visible: true, type: 'date', kind: "absolute" },
//         { id: 'balance', name: 'Balance', visible: true, type: 'currency' },
//         { id: 'cash', name: 'Cash', visible: true, type: 'currency' },
//         { id: 'invested', name: 'Invested', visible: true, type: 'currency' },
//         { id: 'fee', name: 'Fee', visible: true, type: 'currency' },
//         { id: 'refAmount', name: '2021-07-11', visible: true, type: 'currency' },
//         { id: 'remarks', name: 'Remarks', visible: true, type: 'html', maxWidth: 200 },
//         { id: 'status', name: 'Status', visible: true, type: 'html' },
//         { id: 'createdAt', name: 'Created', visible: true, type: 'date' },
//     ]
// }

export const transactionSettings = {
    searchFields: 'symbol,date,type,description,category,remarks',
    columns: [
        { id: 'date', name: 'Date', visible: true },
        { id: 'type', name: 'Type', visible: true, type: 'html' },
        { id: 'symbol', name: 'Symbol', visible: true, type: 'html' },
        { id: 'quantity', name: 'Quantity', visible: true, type: 'number', className: 'text-end' },
        { id: 'price', name: 'Price', visible: true, type: 'currency', className: 'text-end' },
        { id: 'fee', name: 'Fee', visible: true, type: 'currency', className: 'text-end' },
        { id: 'extra', name: 'Extra', visible: true, type: 'currency', className: 'text-end' },
        { id: 'amount', name: 'Amount', visible: true, type: 'currency', className: 'text-end' },
        // { id: 'balance', name: 'Balance', visible: true, type: 'currency', className: 'text-end' },
        { id: 'hold', name: 'Hold', visible: true, type: 'number', className: 'text-end' },
        { id: 'soldDate', name: 'Sold date', visible: true },
        { id: 'soldPrice', name: 'Sold price', visible: true, type: 'currency', className: 'text-end' },
        { id: 'holdDays', name: 'Hold days', visible: true, type: 'number', className: 'text-end' },
        { id: 'winAmount', name: 'Win $', visible: true, type: 'currency', className: 'text-end' },
        { id: 'winPercent', name: 'Win %', visible: true, type: 'percent', className: 'text-end' },
        { id: 'refGain', name: 'Gain/Lose', visible: true, type: 'currency', className: 'text-end' },
        { id: 'category', name: 'Category', visible: true, type: 'html' },
        { id: 'remarks', name: 'Remarks', visible: true, type: 'html' },
        { id: 'description', name: 'Description', visible: true, type: 'html' },
        { id: 'createdAt', name: 'Created', visible: true, type: 'date' },
        // { id: 'updatedAt', name: 'Updated', type: 'date', visible: true },
        { id: 'accountName', name: 'Account Name', type: 'html', visible: true },
    ]
}

export const orderColumns = [
    { id: 'date', name: 'Order date', visible: false },
    { id: 'orderNumber', name: 'Order #', visible: false },
    { id: 'orderType', name: 'Type', visible: false },
    { id: 'symbol', name: 'Symbol', visible: true },
    { id: 'name', name: 'Name', visible: true },
    { id: 'quantity', name: 'Qty', visible: true, className: 'text-end' },
    { id: 'orderPrice', name: 'Order price', visible: true, className: 'text-end' },
    { id: 'amount', name: 'Amount', visible: true, className: 'text-end' },
    { id: 'diffAmount', name: 'Diff $', visible: true, className: 'text-end' },
    { id: 'diffPercent', name: 'Diff %', visible: true, className: 'text-end' },
    { id: 'status', name: 'Status', visible: false },
    { id: 'price', name: 'Last price', visible: true, type: 'currency', className: 'text-end' },
    { id: 'change', name: 'Chg $', visible: true, type: 'currency', className: 'text-end', downColor: 'red', upColor: 'blue' },
    { id: 'changePercent', name: 'Chg %', visible: true, type: 'percent', className: 'text-end', downColor: 'red', upColor: 'blue' },
    { id: 'lastTraded', name: 'Time', visible: true, type: 'date', fmt: 'yyyy-MM-dd', className: "text-center" },
    { id: 'dayRange', name: 'Day range', visible: true, className: 'text-center' },
    { id: 'yearRange', name: 'Year range', visible: true, className: 'text-center' },
    { id: 'position', name: 'Pos %', type: 'number', visible: false, className: 'text-end' },
    { id: 'upPercent', name: 'Up %', type: 'number', visible: false, className: 'text-end' },
    { id: 'downPercent', name: 'Down %', type: 'number', visible: false, className: 'text-end' },
    { id: 'mktCap', name: 'Mkt cap (M)', type: 'number', visible: true, className: 'text-end' },
    { id: 'pe', name: 'P/E', visible: true, className: 'text-end' },
    { id: 'eps', name: 'EPS', visible: true, className: 'text-end' },
    { id: 'targetPrice', name: 'Target', visible: false, className: 'text-end' },
    { id: 'earnDate', name: 'Earn date', type: 'date', visible: true },
    { id: 'divDate', name: 'Div date', type: 'date', visible: true },
    { id: 'divValue', name: 'Div $', visible: false, className: 'text-end' },
    { id: 'divYield', name: 'Div %', visible: true, className: 'text-end' },
    { id: 'divFreq', name: 'DF', visible: true },
    { id: 'divSince', name: 'Div since', visible: true },
    { id: 'expenseRatio', name: 'Exp %', visible: true, className: 'text-end' },
    { id: 'payoutRatio', name: 'Payout', visible: false, className: 'text-end' },
    { id: 'priceToSales', name: 'P/S', visible: false, className: 'text-end' },
    { id: 'priceToBook', name: 'P/B', visible: false, className: 'text-end' },
    { id: 'evToRevenue', name: 'Ev/Rev', visible: false, className: 'text-end' },
    { id: 'evToEbitda', name: 'Ev/Ebt', visible: false, className: 'text-end' },
    { id: 'ytdReturn', name: 'Ytd ret', visible: false, className: 'text-end' },
    { id: 't3mReturn', name: 'T3m ret', visible: false, className: 'text-end' },
    { id: 'category', name: 'Category', visible: false },
    { id: 'sector', name: 'Sector', visible: false },
    { id: 'industry', name: 'Industry', visible: true },
    { id: 'year', name: 'Est', visible: true },
    { id: 'hq', name: 'Hq', visible: true },
    { id: 'remarks', name: 'Remarks', visible: true },
    // { id: 'bidPrice', name: 'Bid', visible: true, className: 'text-end' },
    // { id: 'askPrice', name: 'Ask', visible: true, className: 'text-end' },
    // { id: 'cashLeft', name: 'Cash left', visible: true, className: 'text-end' },
    { id: 'pPrice', name: 'P-Price', visible: true, type: 'currency', className: 'text-end' },
    { id: 'pChange', name: 'P-Chg $', visible: true, type: 'currency', className: 'text-end', downColor: 'red', upColor: 'blue' },
    { id: 'pChangePercent', name: 'P-Chg %', visible: true, type: 'percent', className: 'text-end', downColor: 'red', upColor: 'blue' },
    { id: 'pLastTraded', name: 'P-Time', visible: true, type: 'date', fmt: 'yyyy-MM-dd', className: "text-center" },
    { id: 'updatedAt', name: 'Updated', type: 'date', fmt: 'yyyy-MM-dd', visible: false },
]

export const transactionColumns = [
    { id: 'index', name: '#', visible: true },
    { id: 'date', name: 'Date', visible: true },
    { id: 'type', name: 'Type', visible: true },
    { id: 'symbol', name: 'Symbol', visible: true },
    { id: 'quantity', name: 'Quantity', visible: true, className: 'text-end' },
    { id: 'price', name: 'Price', visible: true, className: 'text-end' },
    { id: 'fee', name: 'Fee', visible: true, className: 'text-end' },
    { id: 'extra', name: 'Extra', visible: true, className: 'text-end' },
    { id: 'amount', name: 'Amount', visible: true, className: 'text-end' },
    { id: 'balance', name: 'Balance', visible: true, className: 'text-end' },
    { id: 'description', name: 'Description', visible: true },
    { id: 'category', name: 'Category', visible: true },
    { id: 'remarks', name: 'Remarks', visible: true },
]

export const holdingsView = {
    searchFields: 'symbol,name,remarks',
    columns: [
        //   { id: 'index', name: '#', visible: true },
        { id: 'symbol', name: 'Symbol', type: 'text', visible: true },
        // { id: 'link', name: '', visible: true },
        // { id: 'chart', name: '', visible: true },
        { id: 'name', name: 'Name', type: 'text', visible: true },
        { id: 'price', name: 'Last price', visible: true, type: 'currency', className: 'text-end' },
        { id: 'change', name: 'Chg $', visible: true, type: 'currency', className: 'text-end', downColor: 'red', upColor: 'blue' },
        { id: 'changePercent', name: 'Chg %', visible: true, type: 'percent', className: 'text-end', downColor: 'red', upColor: 'blue' },
        { id: 'lastTraded', name: 'Time', visible: true, type: 'date', fmt: 'yyyy-MM-dd', className: "text-center" },
        { id: 'dayRange', name: 'Day range', visible: true, className: 'text-center' },
        { id: 'yearRange', name: 'Year range', visible: true, className: 'text-center' },
        { id: 'position', name: 'Pos %', type: 'number', visible: false, className: 'text-end' },
        { id: 'upPercent', name: 'Up %', type: 'number', visible: false, className: 'text-end' },
        { id: 'downPercent', name: 'Down %', type: 'number', visible: false, className: 'text-end' },
        { id: 'tickerRemarks', name: 'Ticker remarks', visible: false },
        { id: 'quantity', name: 'Qty', visible: true, type: 'number', digits: 5, className: 'text-end' },
        { id: 'avgPrice', name: 'Avg price', visible: true, type: 'currency', className: 'text-end' },
        { id: 'paid', name: 'Paid', visible: true, type: 'currency', className: 'text-end' },
        { id: 'marketValue', name: 'Market value', visible: true, type: 'currency', className: 'text-end' },
        { id: 'dayChange', name: 'Today win $', visible: true, type: 'currency', className: 'text-end bold', downColor: 'red', upColor: 'blue' },
        { id: 'winAmount', name: 'Total win $', visible: true, type: 'currency', className: 'text-end', downColor: 'red', upColor: 'blue' },
        { id: 'winPercent', name: 'Total win %', visible: true, type: 'percent', className: 'text-end', downColor: 'red', upColor: 'blue' },
        { id: 'percentage', name: 'Portion', visible: true, type: 'percent', className: 'text-end' },
        { id: 'mktCap', name: 'Mkt cap (M)', type: 'number', visible: true, className: 'text-end' },
        { id: 'beta', name: 'Beta', visible: false, className: 'text-end' },
        { id: 'pe', name: 'P/E', visible: true, className: 'text-end' },
        { id: 'eps', name: 'EPS', visible: true, className: 'text-end' },
        { id: 'targetPrice', name: 'Target', visible: false, className: 'text-end' },
        { id: 'earnDate', name: 'Earn date', visible: true },
        { id: 'divDate', name: 'Div date', visible: true },
        { id: 'divValue', name: 'Div $', visible: false, className: 'text-end' },
        { id: 'divYield', name: 'Div %', visible: true, className: 'text-end' },
        { id: 'divFreq', name: 'DF', type: 'text', visible: true },
        { id: 'divSince', name: 'Div since', visible: true },
        { id: 'expenseRatio', name: 'Exp %', visible: true, className: 'text-end' },
        { id: 'payoutRatio', name: 'Payout', visible: false, className: 'text-end' },
        { id: 'priceToSales', name: 'P/S', visible: false, className: 'text-end' },
        { id: 'priceToBook', name: 'P/B', visible: false, className: 'text-end' },
        { id: 'evToRevenue', name: 'Ev/Rev', visible: false, className: 'text-end' },
        { id: 'evToEbitda', name: 'Ev/Ebt', visible: false, className: 'text-end' },
        { id: 'ytdReturn', name: 'Ytd ret', visible: false, className: 'text-end' },
        { id: 't3mReturn', name: 'T3m ret', visible: false, className: 'text-end' },
        { id: 'category', name: 'Category', type: 'text', visible: false },
        { id: 'sector', name: 'Sector', type: 'text', visible: false },
        { id: 'industry', name: 'Industry', type: 'text', visible: true },
        { id: 'kind', name: 'Kind', type: 'text', visible: false },
        { id: 'type', name: 'Type', type: 'text', visible: false },
        { id: 'year', name: 'Est', type: 'text', visible: true },
        { id: 'hq', name: 'Hq', type: 'text', visible: true },
        { id: 'remarks', name: 'Remarks', type: 'text', visible: true },
        { id: 'yahooSymbol', name: 'Y symbol', visible: false },
        { id: 'pPrice', name: 'P-Price', visible: true, type: 'currency', className: 'text-end' },
        { id: 'pChange', name: 'P-Chg $', visible: true, type: 'currency', className: 'text-end', downColor: 'red', upColor: 'blue' },
        { id: 'pChangePercent', name: 'P-Chg %', visible: true, type: 'percent', className: 'text-end', downColor: 'red', upColor: 'blue' },
        { id: 'pLastTraded', name: 'P-Time', visible: true, type: 'date', fmt: 'yyyy-MM-dd', className: "text-center" },
        { id: 'updatedAt', name: 'Updated', type: 'date', fmt: 'yyyy-MM-dd', visible: false },
    ]
}

export const timeRanges = [
    {id: '1y', name: '1 year', value: 1, checked: true},
    {id: '2y', name: '2 year', value: 2},
    {id: '3y', name: '3 year', value: 3},
    {id: '4y', name: '4 year', value: 4},
    {id: '5y', name: '5 year', value: 5},
    {id: '10y', name: '10 year', value: 10},
    {name: '-'},
    {id: 'max', name: 'All', value: 100},
  ]
  
export const sellPrefs = [
    {id: 'none', name: 'None'},
    {id: 'lowestPriceFirst', name: 'Lowest price first'},
    {id: 'firstInFirstOut', name: 'First in first out'}
]