import { ReportTradesByCategory, ReportInterestsByCategory, ReportDividendsByCategory } from '.'

export const ReportByCategory = ({ data }) => {
    const interests = data.map(x => ({accountName: x.accountName, amount: x.totalInterest})).filter(x => x.amount)
    const dividends = data.map(x => ({accountName: x.accountName, amount: x.totalDividend, divs: x.divs})).filter(x => x.amount)
    const shorts = data.map(x => ({accountName: x.accountName, amount: x.totalShort, items: x.shortTerms})).filter(x => x.amount)
    const longs = data.map(x => ({accountName: x.accountName, amount: x.totalLong, items: x.longTerms})).filter(x => x.amount)
    return (
        <>
            <ReportInterestsByCategory header="Interests" items={interests}/>
            <ReportDividendsByCategory header="Dividends" items={dividends}/>
            <ReportTradesByCategory header="Capital gain - Short term" items={shorts} group="byCategory"/>
            <ReportTradesByCategory header="Capital gain - Long term" items={longs} group="byCategory"/>
        </>
    )
}