import React, { useState, useEffect, useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Navbar, Nav, Container } from 'react-bootstrap'
import { GlobalContext } from '../helpers'
import { apiPost, apiGetOne } from '../../libs/apiLib'
import { Hub, Auth, Storage } from 'aws-amplify'
import { Form, Image } from 'react-bootstrap'
import { LinkButton, MyPopover } from '../widgets'
import { FaEdit } from 'react-icons/fa'
import { toast } from 'react-toastify'
import { Headline } from '.'
// import awsconfig from '../../aws-exports'

export const Header = () => {
    // const prefix = 'Header'
    const navigate = useNavigate()
    const { gState } = useContext(GlobalContext)
    const [state, setState] = useState({
        // pinWatchlists: JSON.parse(localStorage.getItem(`${prefix}_pinWatchlists`)),
        // pinAccounts: JSON.parse(localStorage.getItem(`${prefix}_pinAccounts`)),
        // pinHoldings: JSON.parse(localStorage.getItem(`${prefix}_pinHoldings`)),
        showEdit: false
    })

    useEffect(() => {
        Hub.listen('auth', async data => {
            console.log('A new auth event has happened:', data)
            if (data.payload.event === 'signIn') {
                const session = await Auth.currentSession();
                console.log('session', session)
                if (session && session.idToken) {
                    const { sub: cognitoId, email, email_verified, 'cognito:groups': groups, 'cognito:username': username } = session.idToken.payload
                    let loginUser = { cognitoId, email, email_verified, groups, username }
                    // Check if user by this cognitoId is already in db, if already in db, get the _id and done, if not already in db, put it to create and get _id, and done
                    let user = await apiGetOne({ model: 'user', filter: { cognitoId } })
                    if (user?._id) {
                        loginUser = user
                    } else {
                        user = await apiPost('user', loginUser)
                        if (user?._id) loginUser = user
                        else toast.error(`Login user syncing failed.`)
                    }
                    gState.updateLoginUser(loginUser, true)
                } else if (data.payload.event === 'signOut') {
                    gState.updateLoginUser(null)
                }
            }
        })

        getUserImage()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getUserImage = async () => {
        let userImage = gState.loginUser?.imageKey ? await Storage.get(gState.loginUser?.imageKey, {level: 'public'}) : '/images/user-dark.png'
        setState({...state, userImage})
    }

    const onLogin = () => {
        navigate('/login')
    }

    const onLogout = () => {
        Auth.signOut().catch(err => console.error('Error on onLogout(): ', err))
        gState.updateLoginUser(null)
        navigate('/')
    }

    const onSet = (name, value) => {
        setState({ ...state, [name]: value })
    }

    // const onToggle = (name) => {
    //     localStorage.setItem(`${prefix}_${name}`, JSON.stringify(!state[name]))
    //     setState({ ...state, [name]: !state[name] })
    // }

    const onStop = (e) => {
        if (!state.showEdit) return
        e.stopPropagation()
    }

    const onFileChange = async (e) => {
        try {
            const file = e.target.files[0]
            const { key } = await Storage.put(file.name, file, { contentType: 'image/png' })
            let { loginUser } = gState
            loginUser.imageKey = key
            // loginUser.imageUrl = `https://${awsconfig.aws_user_files_s3_bucket}.s3.amazonaws.com/public/${key}`
            gState.updateLoginUser(loginUser)
        } catch (err){
            console.log(err?.message || err)
        }
    }

    const onRefresh = async () => {
        let user = await apiGetOne({ model: 'user', filter: { _id: gState.loginUser._id } })
        gState.updateLoginUser(user, true)
    }

    // const onTest = async () => {
    //     // let scrapes = [
    //     //     {mask: '1234', kind: 'Balance', date: '2023-03-04', amount: 1023},
    //     //     {mask: '1234', kind: 'Transaction', date: '2023-03-04', amount: 10.99, type: 'Interest'}
    //     // ]
    //     // let result = await apiPost('scrape', scrapes)
    //     // console.log(result)
    // }

    const { loginUser } = gState
    if (gState.hideTopbar) return null
    const { pathname } = window.location
    const showHeadline = pathname === '/' || pathname.endsWith('watchlists') || (gState.showMarketCharts && pathname.match(/user\/.*?\/holdings/))
    return (
        <Container fluid>
            <Navbar bg="light" className="py-0">
                <Navbar.Brand as={Link} to="/" className="me-2"><Image src="/logo.png" style={{ height: 25, width: 25 }} title="Personal investment tool" /></Navbar.Brand>
                <Nav className="ms-auto"></Nav>
                {showHeadline && <Headline />}
                <Nav className="me-auto"></Nav>
                {/* {gState.loginUser && state.pinWatchlists && <LinkButton to={`/user/${gState.loginUser?._id}/watchlists`} text="Watchlists" className="ps-2" />}
                {gState.loginUser && state.pinAccounts && <LinkButton to={`/user/${gState.loginUser?._id}/accounts`} text="Accounts" className="ps-2" />}
                {gState.loginUser && state.pinHoldings && <LinkButton to={`/user/${gState.loginUser?._id}/holdings`} text="Holdings" className="ps-2" />} */}
                <div className="ms-2">
                    {loginUser?.username ?
                        <MyPopover button={<Image src={state.userImage} title={loginUser.username} style={{ border: '1px solid #aaa', borderRadius: '50%', height: 28, width: 28 }} />}>
                            <div className="nowrap">
                                <div className="d-flex">
                                    <div className="me-2 p-relative" onMouseEnter={e => onSet('showEdit', true)} onMouseLeave={e => onSet('showEdit', false)} onClick={onStop}>
                                        <Image src={state.userImage} style={{ width: 80, borderRadius: 10 }} />
                                        {state.showEdit && <label><FaEdit className="p-absolute" style={{ top: -5, left: -5, padding: 1, borderRadius: 5, backgroundColor: 'white' }} /><input type="file" accept="image/png" onChange={onFileChange} style={{ display: "none" }} /></label>}
                                    </div>
                                    <div>
                                        <div className="h5">{loginUser.username}</div>
                                        <div className='my-2'>{loginUser.email}</div>
                                        {gState.isAdmin(false) && <div onClick={e => gState.onToggle('adminMode')}><Form.Switch inline={true} custom="true" label="Admin mode" checked={!!gState.adminMode} readOnly /></div>}
                                    </div>
                                </div>
                                <hr className="mt-2 mb-1" />
                                <div><LinkButton to={`/user/${gState.loginUser._id}/watchlists`} text="Watchlists" target="_blank" className="ps-0" /></div>
                                <div><LinkButton to={`/user/${gState.loginUser._id}/accounts`} text="Accounts" target="_blank" className="ps-0" /></div>
                                <div><LinkButton to={`/user/${gState.loginUser._id}/holdings`} text="Holdings" target="_blank" className="ps-0" /></div>
                                {gState.isAdmin() && <div>
                                    <hr className="my-1" />
                                    <div>
                                        <span className="blueviolet me-2">Manage</span>
                                    </div>
                                </div>}
                                <hr className='my-2' />
                                <div>
                                    <LinkButton variant="outline-danger" onClick={onLogout} className="float-end">Logout</LinkButton>
                                    <LinkButton variant="outline-secondary" onClick={onRefresh}>Refresh</LinkButton>
                                </div>
                            </div>
                        </MyPopover>
                        :
                        <LinkButton variant="outline-secondary" onClick={onLogin}>Login</LinkButton>}
                </div>
            </Navbar>
        </Container>
    )
}