import React from 'react'
import { EditCompany } from '.'

export const AddCompanyPage = () => {
  const company = { 
    name: '', 
    description: '',
  }

  return (
    <>
      <EditCompany company={company}/>
    </>
  );
}
