import { useState, useEffect } from 'react'
import { Table, Row, Col } from 'react-bootstrap'
import { FaBars, FaEllipsisH, FaPlus, FaEdit, FaEllipsisV, FaTimes, FaChartBar, FaCaretDown, FaTrash, FaCopy } from 'react-icons/fa'
import { DateTime, Loading, PagingBox, SearchBox, Checkbox, ThSort, DivHtml, MyPopover, LinkButton, Error, Pin, NoData, Ticker, ConfirmButton } from '../widgets'
import { doSearch, toCurrency, toPercent, toNum, copyTable } from '../../libs/utilLib'
import { apiGetList, apiGetDistinct, apiDelete } from '../../libs/apiLib'
import { prepTrans } from '../../libs/dataLib'
import { transactionSettings as setting, sellPrefs } from '../../libs/consts'
import { sumBy, orderBy } from 'lodash'
import { TransactionsChart } from '.'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import { FaChevronUp, FaChevronDown } from 'react-icons/fa'
import { differenceInDays } from 'date-fns'

export const Transactions = ({ user, account, symbol, quote, onClose }) => {
    const prefix = 'Transactions'
    const [state, setState] = useState({
        height: JSON.parse(localStorage.getItem(`${prefix}_height`) || 800),
        // pinResize: JSON.parse(localStorage.getItem(`${prefix}_pinResize`) || true),
        highlightOnly: false,
        columns: JSON.parse(localStorage.getItem(`${prefix}_columns`)) || setting.columns,
        // pinRefresh: JSON.parse(localStorage.getItem(`${prefix}_pinRefresh`) || true),
        pinSearch: JSON.parse(localStorage.getItem(`${prefix}_pinSearch`)),
        pinCharts: JSON.parse(localStorage.getItem(`${prefix}_pinCharts`) || true),
        showTransactions: JSON.parse(localStorage.getItem(`${prefix}_showTransactions`)),
        showCharts: JSON.parse(localStorage.getItem(`${prefix}_showCharts`) || true),
        sortBy: JSON.parse(localStorage.getItem(`${prefix}_sortBy`)) || 'date',
        sortDir: JSON.parse(localStorage.getItem(`${prefix}_sortDir`)) || 'desc',
        skip: 0,
        limit: JSON.parse(localStorage.getItem(`${prefix}_limit`)) || 20,
        items: null,
        totalCount: 0,
        searchText: '',
        types: null,
        typesText: JSON.parse(localStorage.getItem(`${prefix}_typesText`)),
        symbol,
        symbols: null, 
        // symbolsText: JSON.parse(localStorage.getItem(`${prefix}_symbolsText`)),
        error: '',
        hideEmpty: JSON.parse(localStorage.getItem(`${prefix}_hideEmpty`) || true),
        sellPref: JSON.parse(localStorage.getItem(`${prefix}_sellPref`)) || 'lowestPriceFirst',
    })

    // useEffect(() => {
    //     setState(state => ({ ...state, columns: setting.columns }))
    // }, [])
    useEffect(() => {
        setState({...state, symbol})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [symbol])

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.skip, state.limit, state.sortBy, state.sortDir, state.columns, state.symbol, state.symbolsText])

    const fetchData = async () => {
        setState({ ...state, loading: true })
        let query = user ? { userId: user._id } : { accountId: account._id }
        let filter = user ? { userId: user._id } : { accountId: account._id }
        if (state.symbol) filter.symbol = state.symbol
        else if (state.symbols?.filter(x => !x.checked).length > 0) {
            if (state.symbols.filter(x => x.checked).length > 0) {
                filter.symbol = {$in: state.symbols.filter(x => x.checked).map(x => x.symbol)}
            } else {
                filter.symbol = ""
            }
        }
        const { items, totalCount } = await apiGetList({ model: 'transaction', filter, sort: { [sortBy]: sortDir === 'asc' ? 1 : -1 }, skip, limit, totalCount: 1, populates: [{ path: 'account' }] })
        let types = null
        let symbols = null
        if (account) {
            const tmp0 = state.typesText?.split(/, /).filter(x => x)
            types = (await apiGetDistinct({ model: 'transaction', field: 'type', query })).map(x => ({ type: x, checked: !tmp0?.length || tmp0.find(y => y === x) }))
            if (state.symbols?.filter(x => !x.checked).length > 0) {
                symbols = state.symbols
            } else {
                const tmp1 = state.symbolsText?.split(/, /).filter(x => x)
                symbols = (await apiGetDistinct({ model: 'transaction', field: 'symbol', query })).filter(x => x).map(x => ({ symbol: x, checked: !tmp1?.length || tmp1.find(y => y === x) }))
            }
        }
        setState({ ...state, items, totalCount, types, symbols, loading: false })
    }

    const onDelete = async (tran) => {
        let result = await apiDelete('transaction', tran._id)
        if (result.deletedCount > 0) {
            toast(`Transaction deleted successfully.`)
            fetchData()
        }
        else toast.error('Failed to delete this transaction.')
    }

    const onResize = (size) => {
        let { height } = state
        height = Math.max(height + size, 0)
        localStorage.setItem(`${prefix}_height`, JSON.stringify(height))
        setState({ ...state, height })
    }

    const onToggle = (name) => {
        localStorage.setItem(`${prefix}_${name}`, JSON.stringify(!state[name]))
        setState({ ...state, [name]: !state[name] })
    }

    // const onSet = (name, value) => {
    //     setState({ ...state, [name]: value })
    // }

    const onToggleFilter = (value, parent, kind) => {
        if (value === 'all') state[parent].forEach(x => x.checked = true)
        else if (value === 'none') state[parent].forEach(x => x.checked = false)
        else value.checked = !value.checked
        const values = state[parent].find(x => !x.checked) ? state[parent].filter(x => x.checked).map(x => x[kind]).join(', ') : ''
        if (kind !== 'symbol') localStorage.setItem(`${prefix}_${parent}Text`, JSON.stringify(values))
        setState({ ...state, [parent]: state[parent], [`${parent}Text`]: values })
    }

    const setPage = (skip, limit) => {
        localStorage.setItem(`${prefix}_limit`, JSON.stringify(limit))
        setState({ ...state, skip, limit })
    }

    const onSearch = (searchText) => {
        setState({ ...state, searchText, skip: 0 })
    }

    const onShow = (i) => {
        let { columns } = state
        columns[i].visible = !columns[i].visible
        localStorage.setItem(`${prefix}_columns`, JSON.stringify(columns))
        setState({ ...state, columns })
    }

    const onReset = () => {
        localStorage.removeItem(`${prefix}_columns`)
        setState({ ...state, columns: setting.columns })
    }

    const onStop = (e) => {
        e.stopPropagation()
    }

    const onCopy = () => {
        let result = copyTable(items, columns)
        console.log(result)
        toast(`Table copied into clipboard.`)
    }

    const onSort = (sortBy) => {
        const sortDir = sortBy === state.sortBy ? (state.sortDir === 'desc' ? 'asc' : 'desc') : 'asc'
        localStorage.setItem(`${prefix}_sortBy`, JSON.stringify(sortBy))
        localStorage.setItem(`${prefix}_sortDir`, JSON.stringify(sortDir))
        setState({ ...state, sortBy, sortDir })
    }

    const onSelect = (item, key) => {
        localStorage.setItem(`${prefix}_${key}`, JSON.stringify(item.id))
        setState({...state, [key]: item.id})
    }
    const getValue = (data, type) => {
        if (type === 'currency') return toCurrency(data)
        return data || ''
    }

    const canShow = (y) => {
        if (!user && y.id === 'accountName') return false
        return y.visible && (!state.hideEmpty || items1?.find(x => x[y.id]))
    }

    const getTotal = (id) => {
        let items2 = items1.filter(x => id !== 'winAmount' || x.type !== 'Sold')
        return sumBy(items2, x => x[id])
    }
    // const getSymbolUrl = (symbol) => `https://finance.yahoo.com/chart/${symbol}`

    const { loading, error, items, totalCount, skip, limit, searchText, columns, sortBy, sortDir, pinSearch, highlightOnly, pinCharts } = state
    // let items1 = items?.filter(x => !state.types?.length || state.types.find(y => y.checked && y.type === x.type)).filter(x => !state.symbols?.length || state.symbols.find(y => y.checked && y.symbol === x.symbol))
    let items1 = prepTrans(items, quote, state.sellPref)?.filter(x => !state.types?.length || state.types.find(y => y.checked && y.type === x.type))
    // if (!symbol && state.symbols?.filter(x => !x.checked).length > 0) items1 = items?.filter(x => !state.symbols?.length || state.symbols.find(y => y.checked && y.symbol === x.symbol))
    // if (symbol) items1 = items1?.filter(x => !state.symbols?.length || state.symbols.find(y => y.checked && y.symbol === x.symbol))
    if (searchText && !highlightOnly) items1 = items?.filter(x => doSearch(x, searchText, setting.searchFields))
    // if (sortBy && sortDir) items1 = orderBy(items1, [sortBy], [sortDir])   
    return (
        <div className="mt-2">
            <div>
                <div className="float-end">
                    {pinSearch && <SearchBox searchText={searchText} onSearch={onSearch} />}
                    <PagingBox skip={skip} limit={limit} total={totalCount} setPage={setPage} className="ms-2" />
                    {/* {pinRefresh && <LinkButton icon={<FaSync />} variant="outline-primary" onClick={e => fetchData()} className="ms-2" />} */}
                    {pinCharts && <LinkButton icon={<FaChartBar />} variant="outline-primary" onClick={e => onToggle('showCharts')} className="ms-2" />}
                    <MyPopover icon={<FaBars className="mt-n1" />} variant="outline-primary" className="ms-2 mt-n1">
                        {/* <Pin pinned={pinRefresh} onClick={e => onToggle('pinRefresh')}><LinkButton icon={<FaSync className="me-2" />} text="Refresh" onClick={e => fetchData()} className="p-0" /></Pin>
                        <hr className="my-1" /> */}
                        {/* <Pin pinned={state.pinResize} onClick={e => onToggle('pinResize')} className="my-2"> */}
                        <div>
                            Window height:
                            <Link onClick={e => onResize(200)} className='ms-3'><FaChevronDown/></Link>
                            <Link onClick={e => onResize(-200)} className='ms-3'><FaChevronUp/></Link>
                        </div>
                        {/* </Pin> */}
                        <hr className="my-2" />
                        <Pin pinned={pinCharts} onClick={e => onToggle('pinCharts')}><Checkbox label="Show charts" checked={state.showCharts} onCheck={e => onToggle('showCharts')} /></Pin>
                        <hr className="my-2" />
                        <Pin pinned={pinSearch} onClick={e => onToggle('pinSearch')} className="my-2"><SearchBox searchText={searchText} onSearch={onSearch} show={true} style={{ width: 180 }} /></Pin>
                        <Checkbox label="Highlight search only" checked={highlightOnly} onCheck={e => onToggle('highlightOnly')} />
                        {/* <hr className="my-1" />
                        <div><Checkbox label="Show charts" checked={state.showCharts} onCheck={e => onToggle('showCharts')} /></div> */}
                        {account && <>
                            <hr className="my-2" />
                            <LinkButton to={`/account/${account._id}/transaction/import`} text="Import transactions" className="ps-0" />
                        </>}
                    </MyPopover>
                    {onClose && <LinkButton variant="outline-primary" icon={<FaTimes />} onClick={onClose} className="ms-2" />}
                </div>
                <h6 className="d-inline-block black">Transactions for <br className="d-sm-only" />{symbol ? symbol : account ? account.name : user.username}</h6>
                {account && <MyPopover button={<FaCaretDown className="p-cursor link ms-2"/>} style={{ minWidth: 250 }}>
                    <div className="mb-2"><b>Sell lot preference:</b></div>
                    {sellPrefs.map((x, i) => <span key={i}>
                        <Checkbox type="radio" label={x.name} checked={x.id === state.sellPref} onCheck={e => onSelect(x, 'sellPref')} />
                    </span>)}
                    <hr className="my-2" />
                    {state.types && <>
                        <div className="float-end mt-n2">
                            <LinkButton text="All" onClick={e => onToggleFilter('all', 'types', 'type')} className="pt-0 pe-2" />
                            <span onClick={onStop}><LinkButton text="None" onClick={e => onToggleFilter('none', 'types', 'type')} className="pt-0 pe-0" /></span>
                        </div>
                        <div><b>Type:</b></div>
                        <hr className="my-1 clear-both" />
                        <Row>
                            {state.types.map((x, i) => <Col key={i} xs={6}>
                                <Checkbox label={x.type} checked={!!x.checked} onCheck={e => onToggleFilter(x, 'types', 'type')} />
                            </Col>)}
                        </Row>
                    </>}
                    {!state.symbol && <>
                        <hr className="my-1" />
                        {state.symbols && <>
                            <div className="float-end">
                                <LinkButton text="All" onClick={e => onToggleFilter('all', 'symbols', 'symbol')} className="pt-0 pe-2" />
                                <span onClick={onStop}><LinkButton text="None" onClick={e => onToggleFilter('none', 'symbols', 'symbol')} className="pt-0 pe-0" /></span>
                            </div>
                            <div><b>Symbol:</b></div>
                            <hr className="my-1 clear-both" />
                            <div style={{ maxHeight: 200, maxWdith: "100%", overflow: 'auto' }}>
                            <Row>
                                {state.symbols.map((x, i) => <Col key={i} xs={4}>
                                    <Checkbox label={x.symbol} checked={!!x.checked} onCheck={e => onToggleFilter(x, 'symbols', 'symbol')} />
                                </Col>)}
                            </Row>
                            </div>
                        </>}
                    </>}
                </MyPopover>}
                {/* {state.pinResize && <span className='f-sm'>
                    <Link onClick={e => onResize(200)} className='ms-3'><FaChevronDown/></Link>
                    <Link onClick={e => onResize(-200)} className='ms-3'><FaChevronUp/></Link>
                </span>} */}

                {/* <br className="d-sm-only" />
                <span className="f-sm">
                    {state.types && <MyPopover button={<span className="c-pointer me-2"><span className="blueviolet">Type:</span> {state.typesText || 'All'}</span>}>
                        <LinkButton text="Clear all" onClick={e => onToggleFilter('clear')} className="float-end pe-0" />
                        <LinkButton text="Select all" onClick={e => onToggleFilter('all')} className="ps-0" />
                        <hr className="my-1" />
                        <Row>
                            {state.types.map((x, i) => <Col key={i} xs={6}>
                                <Checkbox label={x.type} checked={!!x.checked} onCheck={e => onToggleFilter(x)} />
                            </Col>)}
                        </Row>
                    </MyPopover>}
                    {state.symbols && <MyPopover button={<span className="c-pointer"><span className="blueviolet">Symbol:</span> {state.symbol || 'All'}</span>}>
                        <Checkbox type="radio" label={"All"} checked={!state.symbol} onCheck={e => onSet('symbol', null)} />
                        <hr className="my-1" />
                        <Row>
                            {state.symbols.map((x, i) => <Col key={i} xs={4}>
                                <Checkbox type="radio" label={x} checked={x === state.symbol} onCheck={e => onSet('symbol', x)} />
                            </Col>)}
                        </Row>
                    </MyPopover>}
                </span> */}
            </div>

            {loading ? <Loading /> : error ? <Error error={error} /> : !items1?.length ? <NoData /> :
                <div className="nowrap clear-both" style={{ maxHeight: state.height, maxWdith: "100%", overflow: 'auto', paddingRight: 5 }}>
                    <Table size="sm" hover className="nowrap f-sm">
                        <thead>
                            <tr>
                                <th>#</th>
                                {columns?.filter(x => canShow(x)).map((x, i) => <ThSort key={i} id={x.id} text={x.name} sortBy={sortBy} sortDir={sortDir} onSort={onSort} className={x.className} />)}
                                <th className="text-end">
                                    <MyPopover button={<FaEllipsisH className="grey mt-n1" />} className="ms-2 mt-n1">
                                        <div><LinkButton icon={<FaCopy />} text="Copy table" onClick={onCopy} className="ps-0" /></div>
                                        <hr className="my-1" />
                                        <div><Checkbox label="Hide empty columns" checked={state.hideEmpty} onCheck={e => onToggle('hideEmpty')} /></div>
                                        <hr className="my-1" />
                                        <div className="float-end"><LinkButton text="Reset to default" onClick={onReset} className="p-0" /></div>
                                        <div className="bold">Show columns</div>
                                        <Row className="clear-both mt-2" onClick={onStop}>
                                            {columns?.map((x, i) => <Col key={i} xs={6}>
                                                <Checkbox label={x.name} checked={x.visible} onCheck={e => onShow(i)} />
                                            </Col>)}
                                        </Row>
                                    </MyPopover>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {items1?.map((x, i) => <tr key={i} style={{backgroundColor: (state.sellPref !== 'none' && (x.type === 'Sold' || (x.type === 'Bought' && !x.hold))) ? '#eee' : ''}}>
                                <td>{skip + i + 1}</td>
                                {columns?.filter(y => canShow(y)).map((y, j) => <td key={j} style={{backgroundColor: (y.id === 'date' && differenceInDays(new Date(), new Date(x[y.id])) > 365) ? '#ccc' : ''}} >
                                    {y.id === 'symbol' && x.symbol ? <Ticker symbol={x.symbol} yahooSymbol={x.yahooSymbol}/> :
                                        y.type === 'date' ? <DateTime date={x[y.id]} kind={y.kind} fmt={y.fmt}/> :
                                            y.type === 'html' ? <DivHtml html={x[y.id]} searchText={searchText} className="truncate" style={{ maxWidth: y.maxWidth }} /> :
                                                <div className={(y.className || '') + ' ' + (y.maxWidth ? 'truncate' : '')} style={{maxWidth: y.maxWidth, color: (y.type === 'currency' || y.type === 'number' || y.type === 'percent') ? (x[y.id] < 0 ? 'red' : 'blue') : '' }}>{y.type === 'percent' ? toPercent(getValue(x[y.id], y.type), 2) : getValue(x[y.id], y.type)}</div>}
                                </td>)}
                                <td className="text-end nowrap">
                                    <MyPopover button={<FaEllipsisV className="grey ms-2" />}>
                                        <div><LinkButton to={`/company/add`} icon={<FaPlus />} text="Add ticker" className="ps-0" /></div>
                                        {x.symbol && <div><LinkButton to={`/company/${x.symbol}/edit`} icon={<FaEdit />} text="Edit ticker" className="ps-0" /></div>}
                                        <hr className="my-2" />
                                        <div><ConfirmButton variant="link" icon={<FaTrash />} text="Delete transaction" onClick={e => onDelete(x)} className="ps-0" /></div>
                                        {!state.symbol && x.symbol && <>
                                            <hr className="my-2" />
                                            <div><LinkButton onClick={e => setState({ ...state, symbol: x.symbol })} text={`View transactions for ${x.symbol} only`} className="ps-0" /></div>
                                        </>}
                                    </MyPopover>
                                </td>
                            </tr>)}
                            <tr className="bold" style={{ backgroundColor: '#ddd' }}>
                                <td></td>
                                {columns?.filter(y => canShow(y)).map((y, j) => <td key={j} className={y.className}>
                                    {j === 0 ? <b>Total</b> : ('quantity,fee,extra,amount,hold,winAmount'.includes(y.id) && toNum(getTotal(y.id)))}
                                </td>)}
                                <td></td>
                            </tr>
                        </tbody>
                    </Table>
                </div>}

            {state.showCharts && <TransactionsChart items={orderBy(items1, ['date'])} columns={state.columns} onClose={e => onToggle('showCharts')} />}
        </div>
    )
}