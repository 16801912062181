import { Fragment, useState } from 'react'
import { FaChevronDown, FaChevronUp } from "react-icons/fa"
import { toCurrency } from "../../libs/utilLib"
import { LinkButton } from "../widgets"
import { Table } from 'react-bootstrap'
import { ReportDividends } from '.'
import { groupBy } from 'lodash'

export const ReportDividendsByCategory = ({ header, items }) => {
    const [state, setState] = useState({
        expanded: false,
    })

    const onExpand = (e, item) => {
        e.preventDefault()
        if (item) {
            item.expanded = !item.expanded
            setState({...state})
        } else {
            setState({...state, expanded: !state.expanded})
        }
    }

    const total = items?.reduce((t, x) => t += x.amount || 0, 0)
    if (!items?.length || !total) return null
    const count = Object.keys(groupBy(items.filter(y => y.divs?.length), x => x.accountName)).length
    return (
        <div>
            <div className="float-end bold"><div className={total < 0 ? 'red' : ''}>{toCurrency(total) || '$0'}</div></div>
            <b className='blueviolet'>{header} [{count}] <LinkButton icon={state.expanded ? <FaChevronUp /> : <FaChevronDown />} onClick={e => onExpand(e)} className="pe-0" /></b>
            {state.expanded && <div className="clear-both" style={{ maxWdith: "100%", overflow: 'auto', paddingRight: 5 }}>
                <Table size="sm" hover className="nowrap">
                    <tbody>
                        {items.filter(x => x.divs?.length).map((x, i) => <Fragment key={i}>
                            <tr>
                                <td>
                                    <span className="bold">{x.accountName}</span> [{x.divs.length}] <LinkButton icon={x.expanded ? <FaChevronUp /> : <FaChevronDown />} onClick={e => onExpand(e, x)} className="pe-0" />
                                </td>
                                <td></td>
                                <td className="bold text-end">{toCurrency(x.amount)}</td>
                            </tr>
                            {x.expanded && <ReportDividends items={x.divs}/>}
                        </Fragment>)}
                    </tbody>
                </Table>
            </div>}
        </div>
    )
}