import { Fragment, useState } from 'react'
import { FaBars, FaChevronDown, FaChevronUp } from "react-icons/fa"
import { toCurrency } from "../../libs/utilLib"
import { LinkButton, MyPopover, Checkbox, Pin } from "../widgets"
import { Table, Container } from 'react-bootstrap'
import { prepTaxReport } from '../../libs/dataLib'
import { ReportByCategory, ReportByBank } from '.'

export const TaxReport = ({ username, accountIds, items }) => {
    const prefix = 'TaxReport'
    const [state, setState] = useState({
        singleYearExpand: JSON.parse(localStorage.getItem(`${prefix}_singleYearExpand`) || true),
        pinGroupBy: JSON.parse(localStorage.getItem(`${prefix}_pinGroupBy`)),
        groupBy: JSON.parse(localStorage.getItem(`${prefix}_groupBy`)) || 'bank',
    })

    const onToggle = (name) => {
        localStorage.setItem(`${prefix}_${name}`, JSON.stringify(!state[name]))
        setState({ ...state, [name]: !state[name] })
    }

    const onSet = (name, value) => {
        localStorage.setItem(`${prefix}_${name}`, JSON.stringify(value))
        setState({ ...state, [name]: value })
    }

    const onExpand = (item) => {
        if (state.singleYearExpand) {
            items.filter(x => x !== item).forEach(x => x.expanded = false)
        }
        item.expanded = !item.expanded
        setState({ ...state })
    }

    const items1 = prepTaxReport(items, state.groupBy)
    return (
        <div>
            <Container fluid className="mb-4">
                <div className="float-end">
                    {state.pinGroupBy && <span>
                        <span className="me-2">Group by</span>
                        <Checkbox type="radio" label="Bank" checked={state.groupBy === 'bank'} onCheck={e => onSet('groupBy', 'bank')} />
                        <Checkbox type="radio" label="Category" checked={state.groupBy === 'category'} onCheck={e => onSet('groupBy', 'category')} />
                    </span>}
                    <MyPopover icon={<FaBars />} variant="outline-primary" className="ms-2 mt-n1">
                        <Checkbox label="Single year expand" checked={state.singleYearExpand} onCheck={e => onToggle('singleYearExpand')} />
                        <hr className="my-1" />
                        <div>
                            <Pin pinned={state.pinGroupBy} onClick={e => onToggle('pinGroupBy')}><div className="mb-2 me-2">Group by</div></Pin>
                            <Checkbox type="radio" label="Bank" checked={state.groupBy === 'bank'} onCheck={e => onSet('groupBy', 'bank')} />
                            <Checkbox type="radio" label="Category" checked={state.groupBy === 'category'} onCheck={e => onSet('groupBy', 'category')} />
                        </div>
                    </MyPopover>
                </div>
                <h4>Tax report for {username} {!!accountIds && <>[{items1?.[0].data?.[0].accountName}]</>}</h4>
            </Container>

            <Container fluid>
                <div className="clear-both" style={{ maxWdith: "100%", overflow: 'auto' }}>
                    <Table size="sm" className="nowrap">
                        <thead>
                            <tr>
                                <th>Tax year</th>
                                <th className="text-end">Interest</th>
                                <th className="text-end">Dividend</th>
                                <th className="text-end">Short term<br/>Captial gain</th>
                                <th className="text-end">Long term<br/>Capital gain</th>
                                <th className="text-end">Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {items1.map((x, i) => <Fragment key={i}>
                                <tr>
                                    <td>
                                        <h4 className="d-inline-block me-2" style={{ color: 'white', backgroundColor: 'black', padding: '0px 5px' }}>{x.year}</h4>
                                        <LinkButton icon={x.expanded ? <FaChevronUp /> : <FaChevronDown />} onClick={e => onExpand(x)} className="pe-0" />
                                    </td>
                                    <td className={'text-end ' + (x.totalInterest > 0 ? 'blue' : '')}>{toCurrency(x.totalInterest) || '$0'}</td>
                                    <td className={'text-end ' + (x.totalDividend > 0 ? 'blue' : '')}>{toCurrency(x.totalDividend) || '$0'}</td>
                                    <td className={'text-end ' + (x.totalShort < 0 ? 'red': x.totalShort > 0 ? 'blue': '')}>{toCurrency(x.totalShort) || '$0'}</td>
                                    <td className={'text-end ' + (x.totalLong < 0 ? 'red': x.totalLong > 0 ? 'blue': '')}>{toCurrency(x.totalLong) || '$0'}</td>
                                    <td className={'text-end ' + (x.total < 0 ? 'red': x.total > 0 ? 'blue': '')}>{toCurrency(x.total) || '$0'}</td>
                                </tr>
                                {x.expanded && <tr><td colSpan={20}>
                                    {state.groupBy === 'bank' ? <ReportByBank data={x.data} /> : <ReportByCategory data={x.data} />}
                                </td></tr>}
                            </Fragment>)}
                        </tbody>
                    </Table>
                </div>
                {!accountIds && <div className="mt-2 f-sm text-muted">Note: Non taxable accounts like 401(k) and IRA accounts and credit cards are not included.</div>}
            </Container>
        </div>
    )
}