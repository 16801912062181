import { useContext } from 'react'
import { MyPopover, LinkButton } from '../widgets'
import { FaCaretDown, FaCaretRight } from 'react-icons/fa'
import { apiPut } from '../../libs/apiLib'
import { GlobalContext } from '../helpers'
import { toast } from 'react-toastify'

export const AddSymbolToWatchlist = ({symbol, fromWatchlist, onRemoveSymbol}) => {
    const { gState } = useContext(GlobalContext)

    const onAdd = async (to) => {
        if (!to.tickers.includes(symbol)) {
            to.tickers.push(symbol)
            let result = await apiPut('watchlist', to._id, { tickers: to.tickers })
            console.log(result)
            toast(`${symbol} added to ${to.name}`)
            await gState.getWatchlists()
        } else {
            toast(`${symbol} already exists in ${to.name}.`)
        }
        if (onRemoveSymbol) onRemoveSymbol(symbol)
    }

    return (
        <MyPopover header="Watchlist" button={<>{fromWatchlist ? 'Move' : 'Add'} {symbol} to watchlist <FaCaretDown /></>} style={{ paddingTop: 5 }}>
            {gState.watchlists?.filter(x => !fromWatchlist || x._id !== fromWatchlist._id).map((y, j) => <div key={j}>
                <FaCaretRight /><LinkButton text={y.name + ' (' + (y.tickers?.length || 0) + ')'} onClick={e => onAdd(y)} className="ps-0 py-0" />
            </div>)}
        </MyPopover>

    )
}