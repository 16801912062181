import React, { useState, useContext } from 'react'
import { Modal, Alert, Table } from 'react-bootstrap'
import { CancelButton, SubmitButton, LinkButton, DivHtml, IconTip, Checkbox } from '../widgets'
import { useNavigate } from 'react-router-dom'
import { GlobalContext } from '../helpers'
import { transactionColumns } from '../../libs/consts'
import { convertTsvToTransactions } from '../../libs/tranLib'
import { apiPost } from '../../libs/apiLib'
import { toast } from 'react-toastify'
import { FaSpinner } from 'react-icons/fa'

export const ImportTransactions = ({ account }) => {
  console.log(account)
  const prefix = "ImportTransactions"
  const navigate = useNavigate()
  const { gState } = useContext(GlobalContext)
  const [state, setState] = useState({
    working: false,
    error: '',
    sortBy: 'idx', sortDir: 'asc',
    source: '', warnings: [], errors: [],
    transactions: [], columns: [],
    clearFirst: false,
    hideInput: false,
    hideEmpty: true,
    showItems: true,
    showHelp: JSON.parse(localStorage.getItem(`${prefix}_showHelp`) || true),
    maxLength: JSON.parse(localStorage.getItem(`${prefix}_maxLength`) || 500),
    searchText: '',
  })

  const checkEmpty = (transactions) => {
    transactions = transactions || state.transactions
    transactionColumns.forEach(x => {
      x.isEmpty = !transactions.find(y => y[x.id])
    })
  }

  const onSet = (obj) => {
    setState({ ...state, ...obj })
  }

  // const onToggle = (name, parent) => {
  //   if (parent) {
  //     parent[name] = !parent[name]
  //     setState({...state})
  //   } else {
  //     localStorage.setItem(`${prefix}_${name}`, !state[name])
  //     setState({...state, [name]: !state[name]})
  //   }
  //   checkEmpty()
  // }

  const convert = (source, isSample) => {
    let { transactions, columns, errors, warnings } = convertTsvToTransactions(source || state.source, state.includeId)
    if (!transactions) return
    checkEmpty(transactions)
    if (transactions.find(x => !x.name)) errors.push(`Name is required but all of them don't have a value.`)
    errors.push(...transactions.filter(x => x._error).map(x => x._error))
    warnings.push(...transactions.filter(x => x._warning).map(x => x._warning))
    let newState = { ...state, source: isSample ? '' : source, transactions, columns, warnings, errors, isSample, error: '', changed: false, isPasting: false }
    setState(newState)
  }

  const onPaste = async (e) => {
    setState({...state, isPasting: true})
    let source = await navigator.clipboard.readText()
    convert(source)
}

  //   const onCopy = () => {
  //     if (navigator.clipboard.writeText(sampleMenus)) gState.showInfo(`Sample menus copied to clipboard.`)
  //     else gState.showError(`Failed to copy sample menus to clipboard.`)
  //   }

  const onClose = () => {
    navigate(-1)
  }

  const canImport = () => {
    let error = transactions.filter(x => x._error).map(x => x._error).join('<br/>')
    if (!transactions || !transactions.length) error += 'Nothing to import.<br/>'
    // transactions.forEach((x, i) => {
    //   if (!x.name) {
    //     let err = `Row ${i+1} Name is required but it's empty. `
    //     x._error = (x._error || '') + err
    //     error += err + '<br/>' 
    //   }
    //   if (x.spice < 0 || x.spice > 4) {
    //     let err = `Row ${i+1} Spice is invalid - it must be between 0 and 4. `
    //     x._error = (x._error || '') + err
    //     error += err + '<br/>' 
    //   }
    // })
    if (error) setState({ ...state, error })
    return !error
  }

  const onImport = () => {
    if (!canImport()) return
    if (account.transactions && account.transactions.length && !transactions.find(x => x.id)) {
      let question = `You are about to import transactions into <b>${account.name}</b>. `
      if (state.clearFirst) question += `<div class="mt-2 red">All existing data will be deleted first!</div> `
      else question += `<div class="mt-2 red">All items will be added as new records.</div>`
      question += `<div class="mt-2">Do you want to proceed?</div>`
      gState.showQuestionModal({
        title: 'Import transactions', question, cb: async (result) => {
          if (!result.canceled) {
            await doImport()
          }
        }
      })
    } else doImport()

    async function doImport() {
      setState({ ...state, working: true })
      let obj = { userId: gState.loginUser._id, accountId: account._id, clearFirst: state.clearFirst, transactions: transactions }
      let { error, imported } = await apiPost('import/transactions', obj)
      if (error) return setState({ ...state, error: 'Failed to import...' + error.message })
      else toast(`Imported ${imported} records.`)
      // gState.showInfo('Transactions imported successfully')
      // global.onGoBack = 'reload'
      navigate(-1)
      // importTransactions({ variables: { jsonStr: JSON.stringify(obj) } }).then(({ data, error }) => {
      //   if (!error) {
      //     if (data.importTransactions.error) return setState({ ...state, error: 'Failed to import...' + data.importTransactions.error })
      //     gState.showInfo('Transactions imported successfully')
      //     global.onGoBack = 'reload'
      //     navigate(-1)
      //   }
      // })
    }
  }

  const getTransactions = () => {
    let { transactions, category, searchText } = state
    if (category) transactions = transactions?.filter(x => x.category === category)
    if (searchText) transactions = transactions?.filter(x => transactionColumns.find(y => typeof (x[y.id]) === 'string' && x[y.id].toLowerCase().includes(searchText.toLowerCase())))
    return transactions
  }


  const { error, working } = state
  const transactions = getTransactions()
  // const isNew = !account.transactions || !account.transactions.length
  return (
    <Modal show={true} backdrop="static" size="xl" keyboard={false}>
      <Modal.Header className="p-relative">
        <span className="f-xs grey p-absolute" style={{ top: 13, left: 15 }}>Import transactions to</span>
        <Modal.Title className="p-relative" style={{ top: 8 }}>{account.name}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div>
          {!transactions.length && <div className="grey mb-3 text-center">
            Copy the source in TSV format (e.g. copied from a spreadsheet like Micorsoft Excel or Google Sheet) into the clipboard and then click the button below.
          </div>}
          <div className="mb-3">
            <div className={`${transactions.length ? '' : 'text-center'}`}>
              {!!transactions.length && <span className="lightbold me-2">Preview</span>}
              <LinkButton text="Paste TSV Source" onClick={onPaste} variant="outline-primary" icon={state.isPasting ? <FaSpinner className="spinner"/> : null} size="sm" className="mt-n1 me-2" />
            </div>
          </div>

          {state.showItems && <div className="mt-2 f-sm">
            {(state.category || state.searchText) && <div className="mb-2">
              {state.category && <span className="b-light bg-light me-1" style={{ borderRadius: 10 }}><span className="grey">Category:</span> {state.category} <LinkButton icon="times" onClick={e => onSet({ category: '' })} size="sm" className="mt-n1 ps-1 pe-0" /></span>}
              {state.searchText && <span className="b-light bg-light me-1" style={{ borderRadius: 10 }}><span className="grey">Search:</span> {state.searchText} <LinkButton icon="times" onClick={e => onSet({ searchText: '' })} size="sm" className="mt-n1 ps-1 pe-0" /></span>}
            </div>}
            {transactions && transactions.length > 0 && <div style={{ maxHeight: '80vh', overflow: 'auto' }}>
              <Table size="sm" className="nowrap">
                <tbody>
                  <tr>
                    <th>#</th>
                    {state.columns.map((x, i) => <th key={i}>{x.name}</th>)}
                  </tr>
                  {transactions.map((x, i) => <tr key={i}>
                    <td>{i + 1} {x._error && <IconTip iconClass="danger" tooltip={x._error} />} {x._warning && <IconTip iconClass="warning" tooltip={x._warning} />}</td>
                    {state.columns.map((y, j) => <td key={j}><DivHtml html={y.id === '_price' ? (x[y.id] || '').toString().replace(/;/g, '<br/>') : x[y.id]} /></td>)}
                  </tr>)}
                </tbody>
              </Table>
            </div>}
          </div>}
        </div>
        {(error || state.error) && <Alert variant="danger" className="mt-2 mb-0"><DivHtml html={error || state.error} /></Alert>}
      </Modal.Body>

      <Modal.Footer className="relative">
        <span className="p-absolute" style={{ left: 10, bottom: 20 }}><Checkbox checked={!!state.clearFirst} label="Clear first" onCheck={e => onSet({ clearFirst: !state.clearFirst })} /></span>
        <CancelButton onClick={onClose} size="sm" />
        <SubmitButton disabled={!state.transactions.length || state.isSample} working={working} text="Import" onClick={onImport} />
      </Modal.Footer>
    </Modal>
  )
}
