import React, { useState } from 'react'
import { Form, Button, InputGroup, FormControl } from 'react-bootstrap'
import { FaSearch, FaTimes } from 'react-icons/fa'
import { LinkButton } from '.'

export const SearchBox = ({ searchText, searchOnEnter, placeholder = "Search...", onSearch, show, height = 32, className, style = { paddingRight: 20, height: 31, width: 180 } }) => {
    const [state, setState] = useState({
        show,
        searchText,
    })

    const onToggle = (name) => {
        setState({ ...state, [name]: !state[name] })
    }
    const onChange = ({ target }) => {
        setState({ ...state, [target.name]: target.value });
    }

    const onKeyDown = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault()
            handleSearch()
        }
    }

    const onKeyUp = (e) => {
        if (!searchOnEnter) {
            handleSearch()
        }
    }

    const onFocus = ({currentTarget}) => {
        currentTarget?.select()
    }

    const onClear = (e) => {
        setState({ ...state, searchText: '' })
        handleSearch(e, '')
    }

    const handleSearch = (e, searchText) => {
        if (e) e.preventDefault()
        onSearch(searchText === undefined ? state.searchText : searchText)
    }

    const onStop = (e) => {
        e.stopPropagation()
    }

    return (
        <span className={className} onClick={onStop}>
            {state.show ?
                <span>
                    <Form className="p-relative d-inline-block">
                        <InputGroup size="sm">
                            <FormControl placeholder={placeholder} name="searchText" value={state.searchText || ''} onChange={onChange} onKeyDown={onKeyDown} onKeyUp={onKeyUp} onFocus={onFocus} style={style} />
                            {searchOnEnter && <InputGroup.Text><FaSearch className="f-sm c-pointer" onClick={handleSearch}/></InputGroup.Text>}
                            {/* {searchOnEnter && <InputGroup.Text>
                                <Button size="sm" variant="link" onClick={handleSearch} style={{ height: 22, width: 22, border: '1px solid lightgrey' }}><FaSearch className="f-sm"/></Button>
                            </InputGroup.Text>} */}
                        </InputGroup>
                        {state.searchText && <Button variant="link" onClick={onClear} className="p-absolute" style={{ top: -4, right: searchOnEnter ? 25 : -8 }}><FaTimes className="text-muted" /></Button>}
                    </Form>
                </span>
                :
                <LinkButton variant="link" size="sm" onClick={e => onToggle('show')}><FaSearch /></LinkButton>
            }
        </span>
    )
}