import React, { useState } from "react"
import HighStock from "highcharts/highstock"
import HighchartsReact from "highcharts-react-official"
// import dragPanes from "highcharts/modules/drag-panes"
import indicatorsAll from "highcharts/indicators/indicators-all"
// import { mathLib } from '../../libs/mathLib'
import { LinkButton, NoData } from "../widgets"
import { FaChevronDown, FaChevronUp, FaTimes } from "react-icons/fa"
import { rangSelectorButtons } from "../../libs/chartLib"
// dragPanes(HighStock)
indicatorsAll(HighStock)

export const Chart = ({ symbol, hists }) => {
    const prefix = 'Chart'
    const [state, setState] = useState({
        chartHeight: JSON.parse(localStorage.getItem(`${prefix}_chartHeight`) || 1250),
        showVolume: true,
        volumeHeight: 20,
        showMACD: true,
        macdHeight: 20,
        showRSI: true,
        rsiHeight: 20,
        chartType: 'candlestick',
        chartZoom: 4,
        showSMA: true,
        showIKH: true,
        showFlag: true,
        showValue: true,
        tooltipTop: true,
        tooltipSplit: false,
        tooltipShared: true,
        smaParams: { index: 3, shortPeriod: 5, longPeriod: 50 },
        macdParams: { shortPeriod: 12, longPeriod: 26, signalPeriod: 9, period: 26 },
        rsiParams: { period: 14 },
    })

    const onToggle = (name) => {
        setState({ ...state, [name]: !state[name] })
    }

    const onResize = (id, size) => {
        let h = state[id + 'Height'] + size
        if (id !== 'chart') {
            if (h < 10) h = 10
            else if (h > 90) h = 90
        }
        setState({ ...state, [id + 'Height']: h })
    }
    if (!symbol) return null
    if (!hists?.length) return <NoData text="No data - please download historical quotes first."/>
    const ohlc = hists.map(x => ([new Date(x.date).getTime(), x.open, x.high, x.low, x.close]))
    const tooltip = { headerFormat: '{point.key}', pointFormat: ' <b>O</b>:{point.open} <b>H</b>:{point.high} <b>L</b>:{point.low} <b>C</b>:{point.close} ', footerFormat: '' }
    const macdHeight = state.showMACD ? Math.min(Math.max(state.macdHeight, 0), 70) : 0
    const rsiHeight = state.showRSI ? Math.min(Math.max(state.rsiHeight, 0), 70) : 0
    const mainHeight = Math.max(100 - macdHeight - rsiHeight, 0)
    const groupingUnits = undefined; //[ [ "week", [1] ], ["month", [1, 2, 3, 4, 6]] ]
    const chartOptions = {
        accessibility: { enabled: false }, navigator: { enabled: false }, scrollbar: { enabled: false }, credits: { enabled: false },
        chart: { height: state.chartHeight + 'px' },
        rangeSelector: { selected: +state.chartZoom, buttons: rangSelectorButtons },
        title: { text: symbol, floating: true, style: { fontSize: "15px", fontWeight: "bold" } },
        xAxis: { crosshair: true },
        yAxis: [
            { labels: { align: "right", x: -3 }, title: { text: "OHLC" }, height: mainHeight + '%', lineWidth: 1, resize: { enabled: true }, crosshair: true },
        ],
        series: [
            { id: 'candlestick', type: state.chartType, name: symbol, data: ohlc, dataGrouping: { units: groupingUnits }, upColor: '#00873c', upLineColor: '#00873c', color: '#eb0f29', lineColor: '#eb0f29', tooltip },
        ],
        tooltip: {
            positioner: state.tooltipTop ? function () { return { x: 37, y: 30 } } : undefined,
            split: state.tooltipSplit, shared: state.tooltipShared, valueDecimals: 2, padding: 0, xDateFormat: '%a %Y-%m-%d', shadow: false, borderWidth: 0, backgroundColor: '#eee'
        },
    }

    const addSMA = () => {
        const tooltip = { headerFormat: '', pointFormat: '<b><span style="color:{series.color}"> {series.name}:</span></b> {point.y}', footerFormat: '' }
        const tooltip1 = { headerFormat: '', pointFormat: ' {point.y}', footerFormat: '' }
        chartOptions.series.push(...[
            { type: "sma", name: "SMA", linkedTo: 'candlestick', params: { index: state.smaParams.index || 3, period: +state.smaParams.shortPeriod || 14 }, lineWidth: 1, marker: { radius: 2 }, tooltip: tooltip },
            { type: "sma", name: "SMALong", linkedTo: 'candlestick', params: { index: state.smaParams.index || 3, period: +state.smaParams.longPeriod || 30 }, lineWidth: 1, marker: { radius: 2 }, tooltip: tooltip1 },
            // { type: "bb", name: "BB", linkedTo: 'candlestick', params: { index: state.smaParams.index || 3, period: 20 }, lineWidth: 1, marker: { radius: 2 }, tooltip: tooltip1 }
        ])
    }

    const addVolume = () => {
        const volumes = hists.map(x => ([new Date(x.date).getTime(), x.volume]))
        const volumeHeight = state.showVolume ? Math.min(Math.max(state.volumeHeight, 0), 70) : 0
        const tooltip = { headerFormat: '', pointFormat: '<span style="color:{series.color}"><b>V</b></span>:{point.y:.1f} ', footerFormat: '' }
        chartOptions.yAxis.push({ id: 'volume', labels: { align: "left", x: 0 }, title: { text: "Volume" }, top: mainHeight - volumeHeight + '%', height: volumeHeight + '%', offset: 0, lineWidth: 1, opposite: false, resize: { enabled: true } })
        chartOptions.series.push({ type: "column", name: "Volume", data: volumes, yAxis: 'volume', dataGrouping: { units: groupingUnits }, color: '#ccc', tooltip })
    }

    const addMACD = () => {
        // const macd = mathLib.MACD({ arr: hists.map(x => x.close), xx: hists.map(x => new Date(x.date).getTime()), shortPeriod: 24, longPeriod: 52, signalPeriod: 18 })
        const tooltip = { headerFormat: '', pointFormat: ' <span style="color:{series.color}">{series.name}:</span> {point.MACD:.2f}/{point.signal:.2f}/{point.y:.2f} ', footerFormat: '' }
        chartOptions.yAxis.push({ id: 'macd', labels: { align: "right", x: -3 }, title: { text: "MACD" }, top: mainHeight + '%', height: macdHeight + '%', offset: 0, lineWidth: 1 })
        chartOptions.series.push(...[
            { type: 'macd', linkedTo: 'candlestick', yAxis: 'macd', name: 'MACD', params: state.macdParams, tooltip },
            // { type: "line", name: "MACD", yAxis: "macd", lineWidth: 1, data: macd[0], visible: !!state.showMACD, tooltip },
            // { type: "line", name: "MACD", yAxis: "macd", lineWidth: 1, data: macd[1], visible: !!state.showMACD, tooltip: tooltip },
            // { type: "column", name: "MACD", yAxis: "macd", data: macd[2], visible: !!state.showMACD, tooltip: tooltip },
        ])
    }
    // const addMACD2 = () => {
    //     const macd = mathLib.MACD({ arr: hists.map(x => x.close), xx: hists.map(x => new Date(x.date).getTime()), shortPeriod: 24, longPeriod: 52, signalPeriod: 18 })
    //     const tooltip = { headerFormat: '', pointFormat: ' <span style="color:{series.color}">{series.name}:</span> {point.MACD:.2f}/{point.signal:.2f}/{point.y:.2f} ', footerFormat: '' }
    //     chartOptions.yAxis.push({ id: 'macd2', labels: { align: "right", x: -3 }, title: { text: "MACD" }, top: (mainHeight + macdHeight) + '%', height: macdHeight + '%', offset: 0, lineWidth: 1 })
    //     chartOptions.series.push(...[
    //         // { type: 'macd', linkedTo: 'candlestick', yAxis: 'macd', name: 'MACD', params: state.macdParams, tooltip },
    //         { type: "line", name: "MACD", yAxis: "macd2", lineWidth: 1, data: macd[0], visible: !!state.showMACD, tooltip },
    //         { type: "line", name: "MACD", yAxis: "macd2", lineWidth: 1, data: macd[1], visible: !!state.showMACD, tooltip: tooltip },
    //         { type: "column", name: "MACD", yAxis: "macd2", data: macd[2], visible: !!state.showMACD, tooltip: tooltip },
    //     ])
    // }

    const addRSI = () => {
        // const rsi = mathLib.RSI({ arr: hists.map(x => x.close), xx: hists.map(x => x.date) })
        const tooltip = { headerFormat: '', pointFormat: ' <span style="color:{series.color}">{series.name}:</span> {point.y:.1f} ', footerFormat: '' }
        chartOptions.yAxis.push({ id: 'rsi', labels: { align: "right", x: -3 }, title: { text: "RSI" }, top: (mainHeight + macdHeight) + '%', height: rsiHeight + '%', offset: 0, lineWidth: 1, plotLines: [{ value: 50, color: 'black' }, { value: 80, color: 'red', width: 2, dashStyle: 'shortdash' }, { value: 20, color: 'red', width: 2, dashStyle: 'shortdash' }] })
        chartOptions.series.push(...[
            { type: 'rsi', linkedTo: 'candlestick', yAxis: 'rsi', name: 'RSI', marker: {enabled: false}, params: state.rsiParams, tooltip },
            // { type: "line", name: "RSI", yAxis: "rsi", lineWidth: 1, data: rsi[0], visible: !!state.showRSI, tooltip }
        ])
    }

    if (state.showSMA) addSMA()
    if (state.showVolume) addVolume()
    if (state.showMACD) addMACD()
    // addMACD2()
    if (state.showRSI) addRSI()

    return (
        <div className="p-relative">
            <HighchartsReact highcharts={HighStock} constructorType={"stockChart"} options={chartOptions} />
            <div className="p-absolute" style={{ top: 40, left: 35 }}>
                <b>PRICE</b>
                {state.showSMA && <span className="ms-3"><b>SMA</b> {state.smaParams.shortPeriod}/{state.smaParams.longPeriod}</span>}
                <span className="ms-3">
                    <LinkButton icon={<FaChevronDown />} onClick={e => onResize('chart', -100)} />
                    <LinkButton icon={<FaChevronUp />} onClick={e => onResize('chart', 100)} />
                </span>
            </div>
            {state.showMACD && <div className="p-absolute" style={{ top: 40 + (100 - macdHeight - rsiHeight) * (state.chartHeight - 80) / 100, left: 35 }}>
                <b>MACD</b> {state.macdParams.shortPeriod}/{state.macdParams.longPeriod}/{state.macdParams.signalPeriod}
                <span className="ms-3">
                    <LinkButton icon={<FaChevronDown />} onClick={e => onResize('macd', -10)} />
                    <LinkButton icon={<FaChevronUp />} onClick={e => onResize('macd', 10)} />
                    <LinkButton icon={<FaTimes />} onClick={e => onToggle('showMACD')} />
                </span>
            </div>}
            {state.showRSI && <div className="p-absolute" style={{ top: 40 + (100 - rsiHeight) * (state.chartHeight - 80) / 100, left: 35 }}>
                <b>RSI</b> {state.rsiParams.period}
                <span className="ms-3">
                    <LinkButton icon={<FaChevronDown />} onClick={e => onResize('rsi', -10)} />
                    <LinkButton icon={<FaChevronUp />} onClick={e => onResize('rsi', 10)} />
                    <LinkButton icon={<FaTimes />} onClick={e => onToggle('showRSI')} />
                </span>
            </div>}
        </div>
    )
}
