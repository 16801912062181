import { useState } from 'react'
import { FaChevronDown, FaChevronUp } from "react-icons/fa"
import { toCurrency } from "../../libs/utilLib"
import { LinkButton } from "../widgets"
import { Table } from 'react-bootstrap'
import { ReportTradesByBank, ReportDividends } from '.'

export const ReportByBank = ({ data }) => {
    const [state, setState] = useState({
        expanded: false,
    })

    const onExpand = (e, item, key) => {
        e.preventDefault()
        if (item) {
            item[key] = !item[key]
            setState({...state})
        } else {
            setState({...state, expanded: !state.expanded})
        }
    }
    return (
        <>
            {data?.map((y, j) => <div key={j}>
                <div>
                    <div className="float-end"><b className={y.total < 0 ? 'red': y.total > 0 ? 'blue' : ''}>{toCurrency(y.total, 2, 2)}</b></div>
                    <b className="blueviolet">{y.accountName} [{y.count}] <LinkButton icon={y.expAccount ? <FaChevronUp /> : <FaChevronDown />} onClick={e => onExpand(e, y, 'expAccount')} className="pe-0" /></b>
                </div>
                {y.expAccount && <div className="clear-both" style={{ maxWdith: "100%", overflow: 'auto' }}>
                    <Table size="sm" hover className="nowrap">
                        <tbody>
                            {y.totalInterest && <tr>
                                <td colSpan={5}>Interest</td>
                                <td colSpan={20} className="text-end">{toCurrency(y.totalInterest)}</td>
                            </tr>}
                            {y.totalDividend && <tr>
                                <td colSpan={5}>
                                    <span className="bold">Dividend</span> [{y.divs?.length}] {y.divs?.length > 0 && <LinkButton icon={y.expDivs ? <FaChevronUp /> : <FaChevronDown />} onClick={e => onExpand(e, y, 'expDivs')} className="pe-0" />}
                                </td>
                                <td colSpan={20} className="bold text-end">{toCurrency(y.totalDividend)}</td>
                            </tr>}
                            {y.expDivs && <ReportDividends items={y.divs}/>}
                            <ReportTradesByBank header="Short term" items={y.shortTerms}/>
                            <ReportTradesByBank header="Long term" items={y.longTerms}/>
                        </tbody>
                    </Table>
                </div>}
            </div>)}
        </>
    )
}