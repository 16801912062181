import { mathLib } from './mathLib'
import { toNumber } from './utilLib'

export const rangSelectorButtons = [
    {
        type: 'month',
        count: 1,
        text: '1m',
        events: {
            click: function () {
                alert('Clicked button');
            }
        }
    }, {
        type: 'month',
        count: 3,
        text: '3m'
    }, {
        type: 'month',
        count: 6,
        text: '6m'
    }, {
        type: 'ytd',
        text: 'YTD'
    }, {
        type: 'year',
        count: 1,
        text: '1y'
    }, {
        type: 'year',
        count: 2,
        text: '2y'
    }, {
        type: 'year',
        count: 3,
        text: '3y'
    }, {
        type: 'year',
        count: 4,
        text: '4y'
    }, {
        type: 'year',
        count: 5,
        text: '5y'
    }, {
        type: 'year',
        count: 10,
        text: '10y'
    }, {
        type: 'all',
        text: 'All'
    }
]

export const columnOptions = ({ categories, series, height, avg, onColumnClick = null, legend = false, dataLabels = true, stackLabels = false }) => {
    let extra = { plotLines: [] }
    if (avg !== undefined) extra = { plotLines: [{ color: 'salmon', value: avg, width: 1, zIndex: 2 }] }
    return {
        credits: { enabled: false }, accessibility: { enabled: false },
        chart: { type: 'column', height },
        title: { text: '' },
        plotOptions: {
            column: { stacking: 'normal', dataLabels: { enabled: dataLabels } },
            series: { cursor: 'pointer', point: { events: { click: onColumnClick } } }
        },
        legend: { enabled: legend, x: 0, y: 0, align: 'right', verticalAlign: 'top', floating: true },
        xAxis: { categories },
        yAxis: { min: 0, title: { text: '' }, stackLabels: { enabled: stackLabels }, ...extra }, // note: stackLabels disappears when resizing, defer=true doesn't fix.
        series
    }
}

export const pieOptions = ({ series, height, onClickPie }) => {
    return {
        credits: { enabled: false }, accessibility: { enabled: false },
        chart: { type: 'pie', height },
        title: { text: '' },
        plotOptions: {
            pie: {
                size: '75%',
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                }
            },
            series: { cursor: 'pointer', point: { events: { click: onClickPie } } }
        },
        legend: { enabled: false, x: 0, y: 0, align: 'right', verticalAlign: 'top', floating: true },
        // xAxis: { categories },
        yAxis: { min: 0, title: { text: '' }, stackLabels: { enabled: true } },
        series
    }
}

export const scatterOptions = ({ series, height, dateFormat, showTooltip = true, showCrosshair = true, showStdDev = false, stdDistance = 1, chartType = 'scatter' }) => {
    let extra = { plotLines: [] }
    if (showStdDev && series.length === 1 && series[0].data?.length > 3) {
        let { mean, std, cv } = mathLib.getStdDev(series[0].data.map(x => x[1]))
        if (std > 0.001) {
            extra = { plotLines: [{ color: 'lime', value: mean, width: 1, zIndex: 2 }, { color: 'salmon', value: mean + std * stdDistance, width: 1, zIndex: 2 }, { color: 'salmon', value: mean - std * stdDistance, width: 1, zIndex: 2 }] }
            series[0].name += ` (Mean:${toNumber(mean)} SD:${toNumber(std)} CV:${mean ? toNumber(cv, 1, 1) : 'N/A'})`
        }
        // console.log(mean, std, extra)
    }
    return {
        credits: { enabled: false }, accessibility: { enabled: false },
        chart: { type: chartType, height },
        title: { text: '' },
        legend: { enabled: true, x: 0, y: 0, align: 'right', verticalAlign: 'top', floating: true },
        tooltip: {
            enabled: showTooltip,
            formatter: function () {
                return '<b>' + dateFormat('%Y-%m-%d %H:%m:%S', new Date(this.x)) + '</b>: ' + this.y;
            }
        },
        xAxis: {
            // tickInterval: (24 * 3600 * 1000), // the number of milliseconds in a day
            allowDecimals: false, crosshair: showCrosshair,
            title: {
                text: 'Date',
                scalable: false
            },
            type: 'datetime',
            labels: {
                formatter: function () {
                    return dateFormat('%Y-%m-%d', (this.value));
                }
            }
        },
        yAxis: { title: { text: '' }, crosshair: showCrosshair, ...extra },
        series
    }
}