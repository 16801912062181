import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { EditWatchlist } from '.'
import { Loading } from '../widgets'
import { apiGetOne } from '../../libs/apiLib'

export const EditWatchlistPage = () => {
  const { id: watchlistId } = useParams()
  const [state, setState] = useState({
    loading: true,
    watchlist: null
  })

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchData = async () => {
    const watchlist = await apiGetOne({ model: 'watchlist', filter: { _id: watchlistId } })
    setState({ ...state, watchlist, loading: false })
  }

  if (state.loading) return <Loading/>
  return (
    <>
      <EditWatchlist watchlist={state.watchlist}/>
    </>
  );
}
