import React, { useEffect, useState, useContext } from 'react'
import { Loading, PagingBox, SearchBox, Pin, NoData, LinkButton } from '../widgets'
import { News } from '.'
import { apiGetList, apiDelete } from '../../libs/apiLib'
import { toast } from 'react-toastify'
import { AutoRefresh } from '../widgets'
import { FaPlus, FaTimes } from 'react-icons/fa'
import { GlobalContext } from '../helpers'
import { capitalize } from '../../libs/utilLib'

export const NewsView = ({ header, kind, limit, doSearch }) => {
  const prefix = 'NewsView_' + kind
  const { gState } = useContext(GlobalContext)
  const [state, setState] = useState({
    working: true,
    searchText: '',
    skip: 0,
    limit: JSON.parse(localStorage.getItem(`${prefix}_limit`)) || limit || 20,
    view: JSON.parse(localStorage.getItem(`${prefix}_view`)) || 'list',
    pinSearch: JSON.parse(localStorage.getItem(`${prefix}_pinSearch`)) || false,
    pinPaging: JSON.parse(localStorage.getItem(`${prefix}_pinPaging`)) || false,
    pinAdd: JSON.parse(localStorage.getItem(`${prefix}_pinAdd`)) || false,
  })

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.searchText, state.skip, state.limit])

  const fetchData = async (fetchFirst) => {
    setState({ ...state, working: true })
    let filter = kind === 'news' ? {} : { symbols: { $ne: null } }
    const { items, totalCount, fetchCount } = await apiGetList({ model: 'news', filter, sort: { publishDate: -1 }, skip: state.skip, limit: state.limit, totalCount: 1, fetchFirst })
    if (fetchFirst) toast(`${fetchCount} news fetched.`)
    setState({ ...state, items, totalCount, working: false })
  }

  const refreshNews = async () => {
    await fetchData(true)
    // let result = await apiPost('fetch/news', {})
    // if (result.count) {
    //   toast(`${result.count} news refreshed.`)
    //   await fetchData()
    // } else {
    //   toast(`No new news refreshed.`)
    //   setState({...state, working: false})
    // }
  }

  const setPage = (skip, limit) => {
    localStorage.setItem(`${prefix}_limit`, JSON.stringify(limit))
    setState({ ...state, skip, limit })
  }

  const onSearch = (searchText) => {
    setState({ ...state, searchText })
    fetchData()
  }

  const onToggle = (name) => {
    localStorage.setItem(`${prefix}_${name}`, !state[name])
    setState({ ...state, [name]: !state[name] })
  }

  const onDelete = async (news) => {
    let result = await apiDelete('news', news._id)
    console.log(result)
    toast(`News deleted.`)
    await fetchData()
  }

  const onClose = () => {
    gState.onToggle(`show${capitalize(kind)}`)
  }

  const dropdown = () => {
    return (
      <>
        <hr className="my-1" />
        <Pin pinned={pinSearch} onClick={e => onToggle('pinSearch')} className="mt-2"><SearchBox searchText={searchText} onSearch={onSearch} show={true} searchOnEnter={true} /></Pin>
        {totalCount > 10 && <Pin pinned={pinPaging} onClick={e => onToggle('pinPaging')} className="my-2"><PagingBox skip={state.skip} limit={state.limit} total={totalCount} setPage={setPage} className="ms-0" /></Pin>}
        <hr className="my-1" />
        <Pin pinned={pinAdd} onClick={e => onToggle('pinAdd')}><LinkButton icon={<FaPlus />} text="Add news" to={`/news/add`} className="ps-0" /></Pin>
        <hr className="my-1" />
        <div><LinkButton icon={<FaTimes />} text="Close" onClick={onClose} className="ps-0" /></div>
      </>
    )
  }

  const { items, totalCount, pinSearch, pinPaging, pinAdd, searchText } = state
  // if (state.working) return <Loading />
  // console.log(items)
  return (
    <>
      {header && <div className="mt-2">
        <span className="float-end">
          {pinSearch && <SearchBox searchText={searchText} onSearch={onSearch} className="ms-2" />}
          {pinPaging && <PagingBox skip={state.skip} limit={state.limit} total={totalCount} setPage={setPage} className="ms-2" />}
          {pinAdd && <LinkButton icon={<FaPlus />} variant="outline-primary" to={`/news/add`} className="ms-2" />}
          <AutoRefresh key={kind} id={kind} working={state.working} doRefresh={refreshNews} dropdown={dropdown()} className="ms-2" />
        </span>
        <h4>{header}</h4>
      </div>}
      <div className="clear-both">
        {items?.length ? <News items={items} searchText={state.searchText} doSearch={doSearch} doDelete={onDelete}/> : state.working ? <Loading /> : <NoData />}
      </div>
    </>
  )
}
