import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { DivHtml, LinkButton, TextInput } from '..'

export const InputModal = ({ title, input, size = 'lg', bodyClass, onOK, onCancel }) => {
    const { question, hint, as, rows, type, name = 'name', value, placeholder, inputStyle } = input
    const [state, setState] = useState({
        [name]: value
    })

    const onChange = ({ target }) => {
        let value = target.type === 'number' ? +target.value : target.value
        setState({ ...state, [target.name]: value, error: '' })
    }

    return (
        <Modal show={true} size={size} onHide={onCancel}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>

            <Modal.Body className={bodyClass}>
                {question && <DivHtml html={question} />}
                <TextInput title={input.title} as={as} rows={rows} type={type} name={name} value={state[name]} placeholder={placeholder} onChange={onChange} inputStyle={inputStyle} />
                {hint && <DivHtml className="f-sm grey" html={hint} />}
            </Modal.Body>

            <Modal.Footer>
                <LinkButton variant="secondary" onClick={onCancel} text="Cancel" />
                <LinkButton variant="primary" onClick={e => onOK(state[name])} text="OK" style={{ minWidth: 70 }} />
            </Modal.Footer>
        </Modal>
    )
}
