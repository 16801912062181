import React, { useEffect } from 'react'
import { withAuthenticator } from '@aws-amplify/ui-react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Button } from 'react-bootstrap'

const Login = ({signOut, user}) => {
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const redirectPath = searchParams.get('redirect')

    useEffect(() => {
        navigate(`${redirectPath || '/'}`)
        // window.location.reload()
    }, [redirectPath, navigate])

    return (
        <h5>Welcome {user.username} <Button onClick={signOut}>Sign out</Button></h5>
    )
}
export default withAuthenticator(Login)