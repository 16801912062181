import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { GlobalState } from './components/helpers'
import { HomePage, Header, Footer, Login } from './components/basics'
import { AccountsPage } from './components/account'
import { ImportTransactionsPage, TransactionsPage } from './components/transaction'
import { HoldingsPage, MyHoldingsPage } from './components/holding'
import { WatchlistsPage } from './components/watchlist'
import { FaAngleDoubleUp } from 'react-icons/fa'
import { ChartPage } from './components/chart'
import { AddAccountPage, EditAccountPage, SyncAccountPage, SyncBankPage, AccountPerformancePage } from './components/account'
import { TaxReportPage } from './components/tax'
import { AddCompanyPage, EditCompanyPage } from './components/company'
import { AddWatchlistPage, EditWatchlistPage } from './components/watchlist'
import { AddNewsPage, EditNewsPage } from './components/news'
import ScrollToTop from "react-scroll-to-top"

function App() {
  return (
    <GlobalState>
      <BrowserRouter>
        <Header />
        <div className="main-container">
          <Routes>
            <Route index element={<HomePage />} />
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/user/:id/watchlists" element={<WatchlistsPage />} />
            <Route exact path="/user/:id/accounts" element={<AccountsPage />} />
            <Route exact path="/user/:id/holdings" element={<MyHoldingsPage />} />
            <Route exact path="/user/:userId/transactions" element={<TransactionsPage />} />
            <Route exact path="/account/add" element={<AddAccountPage />} />
            <Route exact path="/sync/:bank" element={<SyncBankPage />} />
            <Route exact path="/account/:id/sync" element={<SyncAccountPage />} />
            <Route exact path="/account/:id/edit" element={<EditAccountPage />} />
            <Route exact path="/account/:id/perf" element={<AccountPerformancePage />} />
            <Route exact path="/account/:id/holdings" element={<HoldingsPage />} />
            <Route exact path="/account/:id/transactions" element={<TransactionsPage />} />
            <Route exact path="/account/:id/transaction/import" element={<ImportTransactionsPage />} />
            <Route exact path="/chart/:ticker" element={<ChartPage />} />
            <Route exact path="/company/add" element={<AddCompanyPage />} />
            <Route exact path="/company/:id/edit" element={<EditCompanyPage />} />
            <Route exact path="/watchlist/add" element={<AddWatchlistPage />} />
            <Route exact path="/watchlist/:id/edit" element={<EditWatchlistPage />} />
            <Route exact path="/news/add" element={<AddNewsPage />} />
            <Route exact path="/news/:id/edit" element={<EditNewsPage />} />
            <Route exact path="/user/:id/tax" element={<TaxReportPage />} />
            <Route path="*" element={<HomePage />} />
          </Routes>
          <Footer />
        </div>
      </BrowserRouter>
      <ToastContainer position="bottom-right" />
      <ScrollToTop smooth component={<p style={{borderRadius: 5, margin: 0, paddingBottom: 0, opacity: 0.5}}><FaAngleDoubleUp size={30}/></p>}/>
    </GlobalState>
  );
}

export default App;
