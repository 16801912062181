import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { EditCompany } from '.'
import { Loading } from '../widgets'
import { apiGetOne } from '../../libs/apiLib'

export const EditCompanyPage = () => {
  const { id: companyId } = useParams()
  const [state, setState] = useState({
    loading: true,
    company: null
  })

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchData = async () => {
    const company = await apiGetOne({ model: 'company', filter: { _id: companyId } })
    setState({ ...state, company, loading: false })
  }

  if (state.loading) return <Loading/>
  return (
    <>
      <EditCompany company={state.company}/>
    </>
  );
}
