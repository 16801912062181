import React, { useState } from 'react'
import { truncate } from 'lodash'
import { addHighlight } from '../../libs/utilLib'

export const TrimTo = ({text, to, showAll, searchText, className}) => {
  const [state, setState] = useState({
    showAll
  })

  const toggle = () => {
    setState({...state, showAll: !state.showAll})
  }

  if (!text) return null
  const txt = state.showAll ? text : truncate(text, {length: to})
  return (
    <span onClick={toggle} title={text} dangerouslySetInnerHTML={{__html: addHighlight(txt.replace(/\n/g, '<br/>'), searchText)}} className={`c-pointer ${className}`}></span>
  )
}
