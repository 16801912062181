import { useState, useContext } from 'react'
import { FaHeart, FaRegHeart, FaThumbsUp, FaRegThumbsUp, FaThumbsDown, FaRegThumbsDown } from "react-icons/fa"
import { toCurrency } from "../../libs/utilLib"
import { LinkButton, ALink, MyPopover, ThSort } from "../widgets"
import { orderBy } from 'lodash'
import { GlobalContext } from '../helpers'


export const ReportDividends = ({ items }) => {
    const prefix = 'ReportDividends'
    const { gState } = useContext(GlobalContext)
    const [state, setState] = useState({
        sortBy: JSON.parse(localStorage.getItem(`${prefix}_sortBy`)) || 'symbol',
        sortDir: JSON.parse(localStorage.getItem(`${prefix}_sortDir`)) || 'asc',
    })

    const onSort = (sortBy) => {
        const sortDir = sortBy === state.sortBy ? (state.sortDir === 'desc' ? 'asc' : 'desc') : 'asc'
        localStorage.setItem(`${prefix}_sortBy`, JSON.stringify(sortBy))
        localStorage.setItem(`${prefix}_sortDir`, JSON.stringify(sortDir))
        setState({ ...state, sortBy, sortDir })
    }

    if (!items?.length) return null
    const { sortBy, sortDir } = state
    if (sortBy && sortDir) items = orderBy(items, [sortBy], [sortDir])
    return (
        <>
            <tr>
                <th>#</th>
                <ThSort id="symbol" text="Symbol" sortBy={sortBy} sortDir={sortDir} onSort={onSort} />
                <ThSort id="amount" text="Amount" sortBy={sortBy} sortDir={sortDir} onSort={onSort} className="text-end" />
            </tr>
            {items.map((x, i) => <tr key={i} className="f-sm grey">
                <td width="10"><LinkButton text={i + 1} to={`/chart/${x.symbol}`} target="_blank" className="p-0" /></td>
                <td width="100">
                    {x.symbol ? <>
                        <div className="float-end">
                        <MyPopover button={gState.isFavor(x.symbol, 'watch') ? <FaHeart className="blueviolet" title="Watching" /> : gState.isFavor(x.symbol, 'buy') ? <FaThumbsUp className="blueviolet" title="Watch for buy" /> : gState.isFavor(x.symbol, 'sell') ? <FaThumbsDown className="blueviolet" title="Watch for sell" /> : <FaRegHeart title="Favorite?" />}>
                            <div><LinkButton icon={gState.isFavor(x.symbol, 'watch') ? <FaHeart className="blueviolet" /> : <FaRegHeart />} text="Watching" onClick={e => gState.onFavor(x.symbol, 'watch')} /></div>
                            <div><LinkButton icon={gState.isFavor(x.symbol, 'buy') ? <FaThumbsUp className="blueviolet" /> : <FaRegThumbsUp />} text="Watch for buy" onClick={e => gState.onFavor(x.symbol, 'buy')} /></div>
                            <div><LinkButton icon={gState.isFavor(x.symbol, 'sell') ? <FaThumbsDown className="blueviolet" /> : <FaRegThumbsDown />} text="Watch for sell" onClick={e => gState.onFavor(x.symbol, 'sell')} /></div>
                        </MyPopover>
                    </div>
                    <ALink href={`https://finance.yahoo.com/chart/${x.symbol}`} text={x.symbol} />
                    </> : <>Unknown</>}
                </td>
                <td colSpan="20" className="text-end grey">{toCurrency(x.amount)}</td>
            </tr>)}

        </>
    )
}