import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

export const SparkLineChart = ({ dates, values, width, height, lines }) => {
    // console.log(values)
    let series = []
    values.forEach(x => {
        let data = []
        let date
        for (let i = 0; i < Math.max(x.length, 78); i++) {
            if (i < x.length) {
                let date = new Date(+dates[i])
                let value = x[i]
                data.push([date, value])
                date = dates[i]
            }
            else {
                date += 5 * 60 * 1000
                data.push(new Date(date), null)
            }
        }
        series.push({ data })
    })
    const options = {
        credits: { enabled: false }, accessibility: { enabled: false },
        chart: { type: 'line', width, height, margin: [0, 5, 0, 5], backgroundColor: null, borderWidth: 0, style: { overflow: 'visible' }, skipClone: true },
        title: { text: '' }, legend: { enabled: false }, tooltip: { enabled: false },
        xAxis: { type: 'datetime', allowDecimals: false, startOnTick: false, endOnTick: false, tickPositions: [], lineWidth: 0, gridLineWidth: 0, labels: { enabled: false }, title: { text: '' }, crosshair: false },
        yAxis: { labels: { enabled: false }, title: { text: '' }, crosshair: false, startOnTick: false, endOnTick: false, tickPositions: [], lineWidth: 0, gridLineWidth: 0, plotLines: lines?.map(x => ({value: x})) },
        plotOptions: { series: { animation: false, lineWidth: 1, shadow: false, states: { hover: { lineWidth: 1 } }, marker: { radius: 1, states: { hover: { radius: 2 } } }, fillOpacity: 0.25 } },
        series
    }
    // console.log(options)
    return (
        <HighchartsReact highcharts={Highcharts} options={options} />
    )
}