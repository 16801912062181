import React, { useEffect, useState } from 'react'
import { PagingBox, SearchBox, Pin, LinkButton, AutoRefresh, Loading, NoData } from '../widgets'
import { Quotes } from '.'
import { apiGetList } from '../../libs/apiLib'
import { toast } from 'react-toastify'
import { processQuotes } from '../../libs/dataLib'
import { FaChevronUp, FaChevronDown } from 'react-icons/fa'
import { Link } from 'react-router-dom'

export const QuotesView = ({ watchlist, header, sector, industry, dropdownMenu, onRemoveSymbol, dropdownRight }) => {
  const prefix = 'QuotesView'
  const [state, setState] = useState({
    working: true,
    searchText: '',
    skip: 0,
    limit: JSON.parse(localStorage.getItem(`${prefix}_limit`)) || 100,
    height: JSON.parse(localStorage.getItem(`${prefix}_height`) || 800),
    pinSearch: JSON.parse(localStorage.getItem(`${prefix}_pinSearch`)) || false,
    pinPaging: JSON.parse(localStorage.getItem(`${prefix}_pinPaging`)) || false,
    pinAdd: JSON.parse(localStorage.getItem(`${prefix}_pinAdd`)) || false,
  })

  useEffect(() => {
    fetchData(watchlist?.searchText)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.skip, state.limit, state.searchText, state.sortBy, state.sortDir, watchlist?.tickers, watchlist?.searchText, sector, industry])

  const fetchData = async (searchText, fetchFirst = false, range = '1d', source = 'yfinance2') => {
    setState({ ...state, working: true })
    let { skip, limit } = state
    let totalCount = !!searchText || !!header
    searchText = searchText || state.searchText
    let searchFields = 'symbol'
    if (searchText !== searchText.toUpperCase()) searchFields += ',name'
    let symbols = watchlist?.tickers
    if (fetchFirst && state.items?.length && symbols?.length) {
      for(let i = 0; i < symbols.length; i++){
        const item = state.items.find(x => x.symbol === symbols[i] && x.yahooSymbol)
        if (item) {
          symbols[i] += '|' + item.yahooSymbol
        }
      }
    }
    let filter = !header ? {symbols} : {}
    if (sector) filter['sector'] = sector
    if (industry) filter['industry'] = industry
    const result = await apiGetList({
      model: 'company', filter, sort: { symbol: 1 },
      select: { description: 0, imageUrl: 0, longName: 0, website: 0 }, 
      skip, limit, fetchFirst, range, searchText, searchFields, totalCount, source
    })
    if (result?.error) toast.error(result.error)
    else if (fetchFirst) toast(`${result.fetchCount} quotes fetched.`)
    setState({ ...state, items: processQuotes(result?.items), totalCount: result?.totalCount, working: false })
  }

  const refreshQuotes = async (range = '1d', source) => {
    await fetchData(null, true, range, source)
    // setState({ ...state, working: true })
    // let { updatedSymbols } = await apiPost('fetch/quotes', { symbols: watchlist.tickers, range })
    // if (updatedSymbols) {
    //   toast(`${updatedSymbols.split('+').length} quotes refreshed.`)
    //   await fetchData()
    // } else {
    //   toast.error(`Refreshing quotes failed.`)
    //   setState({ ...state, working: false })
    // }
  }

  const refreshFromYahoo = async (range) => {
    await refreshQuotes(range, 'ysparkline')
  }

  // const onSet = (name, value) => {
  //   localStorage.setItem(`${prefix}_${name}`, JSON.stringify(value))
  //   setState({ ...state, [name]: value })
  // }

  const setPage = (skip, limit) => {
    localStorage.setItem(`${prefix}_limit`, JSON.stringify(limit))
    setState({ ...state, skip, limit })
  }

  const onSearch = (searchText) => {
    setState({ ...state, searchText })
  }

  const onToggle = (name) => {
    localStorage.setItem(`${prefix}_${name}`, JSON.stringify(!state[name]))
    setState({ ...state, [name]: !state[name] })
  }

  const onResize = (size) => {
    let { height } = state
    height = Math.max(height + size, 0)
    localStorage.setItem(`${prefix}_height`, JSON.stringify(height))
    setState({ ...state, height })
  }

  const goYahoo = (id) => {
    window.open(`https://finance.yahoo.com/${id}`)
  }

  const dropdown = () => {
    return (
      <>
        <hr className="my-1" />
        <div><LinkButton text="Refresh daily quotes from Yahoo" onClick={e => refreshFromYahoo('1d')} className="ps-0" /></div>
        <div><LinkButton text="Refresh yearly quotes from Yahoo" onClick={e => refreshFromYahoo('1y')} className="ps-0" /></div>
        <hr className="my-1" />
        <Pin pinned={pinSearch} onClick={e => onToggle('pinSearch')} className="my-2"><SearchBox searchText={searchText} onSearch={onSearch} show={true} searchOnEnter={true} /></Pin>
        {totalCount > 10 && <Pin pinned={pinPaging} onClick={e => onToggle('pinPaging')} className="my-2"><PagingBox skip={state.skip} limit={state.limit} total={totalCount} setPage={setPage} className="ms-0" /></Pin>}
        <hr className="my-1" />
        <div className="my-2">
            Window height:
            <Link onClick={e => onResize(100)} className='ms-3'><FaChevronDown/></Link>
            <Link onClick={e => onResize(-100)} className='ms-3'><FaChevronUp/></Link>
        </div>
        {dropdownRight}
      </>
    )
  }

  const { items, searchText, pinSearch, pinPaging } = state
  const totalCount = state.totalCount || watchlist?.tickers?.length//watchlist.symbols?.split(/,\s*|\s+|\s*\+\s*/).length
  return (
    <div className="clear-both">
      <div className="float-end mt-2">
        {pinSearch && <SearchBox searchText={searchText} onSearch={onSearch} searchOnEnter={true} className="me-2" />}
        {pinPaging && totalCount > 10 && <PagingBox skip={state.skip} limit={state.limit} total={totalCount} setPage={setPage} className="me-2" />}
        <AutoRefresh key="quoteView" id="quoteView" working={state.working} doRefresh={e => refreshQuotes()} dropdown={dropdown()} className="ms-2" />
      </div>
      <h5 className="d-inline-block mt-2">{header || <>{watchlist?.name} {watchlist?.category && <>[{watchlist.category}]</>}</>}</h5> {dropdownMenu}

      <div className="clear-both">
        {items?.length ? <Quotes quotes={items} skip={state.skip} searchText={searchText} watchlist={watchlist} height={state.height} onRemoveSymbol={onRemoveSymbol} /> : state.working ? <Loading /> : <NoData />}
      </div>
      <div className="pt-2">
        {totalCount > state.limit && <PagingBox skip={state.skip} limit={state.limit} total={totalCount} setPage={setPage} className="float-end" />}
        <LinkButton text="News" onClick={e => goYahoo('news')} />
        <LinkButton text="Trending" onClick={e => goYahoo('trending-tickers')} />
        <LinkButton text="Most actives" onClick={e => goYahoo('most-active')} />
        <LinkButton text="Top gainers" onClick={e => goYahoo('gainers')} />
        <LinkButton text="Top losers" onClick={e => goYahoo('losers')} />
        <LinkButton text="Currencies" onClick={e => goYahoo('currencies')} />
        <LinkButton text="Top ETFs" onClick={e => goYahoo('etfs')} />
        <LinkButton text="Top MFs" onClick={e => goYahoo('mutualfunds')} />
        <LinkButton text="TD MF" onClick={e => window.open('https://www.td.com/ca/en/asset-management/funds/solutions/mutual-funds/')} />
        <LinkButton text="RBC MF" onClick={e => window.open('https://www.rbcgam.com/en/ca/products/mutual-funds/?series=a,t&tab=prices')} />
      </div>
    </div>
  )
}
