const toXY = (arr, xx, toDigits = 4) => {
  if (!arr || !xx) return null
  return xx.map((x, i) => ([x, toDigits ? Number(arr[i].toFixed(toDigits)): arr[i]]))
}

const getSum = (arr) => {
  return arr.reduce((r, v) => (r + v), 0)
}

const getMean = (arr) => {
  return getSum(arr) / arr.length
}

const getStdDev = (arr) => {
  let mean = getMean(arr)
  let variance = arr.reduce((r, v) => {
    r += (v - mean) * (v - mean)
    return r
  }, 0)
  return Math.sqrt(variance / arr.length)
}

const getAvg = (arr1, arr2, offset = 0) => {
  let results = Array(arr1.length + offset).fill(NaN)
  for (let i = 0; i < arr1.length; i++) {
    if (!isNaN(arr1[i] && !isNaN(arr2[i]))) results[i+offset] = (arr1[i] + arr2[i]) / 2
  }
  return results
}

const getHL = (hists, period) => {
  let results = Array(hists.length).fill(NaN)
  for (let i = period; i < hists.length; i++){
    let h = hists[i-1].high, l = hists[i-1].low
    for (let j = 2; j <= period; j++) {
      if (h < hists[i-j].high) h = hists[i-j].high
      if (l > hists[i-j].low) l = hists[i-j].low
    }
    results[i] = (h + l) / 2
  }
  return results
}

const getShift = (arr, offset) => {
  let results = Array(arr.length + Math.max(0, offset)).fill(NaN)
  let from = offset > 0 ? 0 : -offset
  let to = offset > 0 ? arr.length - offset : arr.length
  for (let i = from; i < to; i++) {
    results[i+offset] = arr[i]
  }  
  return results
}

const getSlope = (arr, multi = 1) => {
  let results = [0]
  for (let i = 1; i < arr.length; i++) {
    let v = arr[i] - arr[i-1]
    results.push(v * multi)
  }
  return results
}

//------------------------------------------------------------------------------
const SMA = ({arr, period, xx}) => {
  let results = []
  let sum = 0
  for (let i = 0; i < period; i++) {
    sum += arr[i]
    results.push(sum / (i+1))
  }
  for (let i = period; i < arr.length; i++) {
    sum += arr[i] - arr[i-period]
    results.push(sum / period)
  }
  return xx ? toXY(results, xx) : results
}

const WMA = ({arr, period, xx}) => {
  let results = []
  let sum = 0
  let m = 0
  for (let i = 0; i < period; i++) {
    sum += arr[i] * (i + 1)
    m += i + 1
    results.push(sum / m)
  }
  for (let i = period; i < arr.length; i++) {
    sum = 0
    for (let j = 1; j <= period; j++) {
      sum += arr[i-period+j] * j 
    }
    results.push(sum / m)
  }
  return xx ? toXY(results, xx) : results
}

const EMA = ({arr, period, smooth = 2, xx}) => {
  if (!arr?.length) return []
  let k = 0
  // Use SMA for first peiod points
  // let results = SMA({arr: arr.slice(0, period), period})
  let results = [arr[0]]
  for (let i = 1; i < period; i++) {
    k = smooth / (1.0 + i)
    results.push(arr[i] * smooth / (i + 1) + results[i-1] * (1.0 - smooth / (i + 1)))
  }
  k = smooth / (1.0 + period)
  for (let i = period; i < arr.length; i++) {
    results.push(arr[i] * k + results[i-1] * (1.0 - k))
  }
  return xx ? toXY(results, xx) : results
}

const SMAs = ({arr, shortPeriod, longPeriod, xx}) => {
  const fast = SMA({arr, period: shortPeriod})
  const slow = SMA({arr, period: longPeriod})
  const hist = fast.map((x, i) => x - slow[i])
  return xx ? [toXY(fast, xx), toXY(slow, xx), toXY(hist, xx)] : [fast, slow, hist]
}

const WMAs = ({arr, shortPeriod, longPeriod, xx}) => {
  const fast = WMA({arr, period: shortPeriod})
  const slow = WMA({arr, period: longPeriod})
  const hist = fast.map((x, i) => x - slow[i])
  return xx ? [toXY(fast, xx), toXY(slow, xx), toXY(hist, xx)] : [fast, slow, hist]
}

const EMAs = ({arr, shortPeriod, longPeriod, smooth=2, xx}) => {
  const fast = EMA({arr, period: shortPeriod, smooth})
  const slow = EMA({arr, period: longPeriod, smooth})
  const hist = fast.map((x, i) => x - slow[i])
  return xx ? [toXY(fast, xx), toXY(slow, xx), toXY(hist, xx)] : [fast, slow, hist]
}

//------------------------------------------------------------------------------
const MACD = ({arr, shortPeriod=12, longPeriod=26, signalPeriod=9, xx}) => {
  const short = EMA({arr, period: shortPeriod})
  const long = EMA({arr, period: longPeriod})
  const fast = short.map((x, i) => x - long[i])
  const slow = EMA({arr: fast, period: signalPeriod})
  const hist = fast.map((x, i) => x - slow[i])
  return xx ? [toXY(fast, xx), toXY(slow, xx), toXY(hist, xx)] : [fast, slow, hist]
}

const RSI = ({arr, period=14, xx}) => {
  // console.log('RSI', period)
  let uu = Array(arr.length).fill(0)
  let dd = Array(arr.length).fill(0)
  for (let i = 1; i < arr.length; i++) {
    let d = arr[i] - arr[i-1]
    if (d > 0) uu[i] = d
    else if (d < 0) dd[i] = -d
  }
  let ua = EMA({arr: uu, period})
  let da = EMA({arr: dd, period})
  let results = ua.map((u, i) => 100.0 - 100.0 /(1.0 + u / da[i]))
  results[0] = results[1] // to avoid big jump
  // console.log('RSI', signalPeriod, results)
  return xx ? [toXY(results, xx)] : [results]
}

const ROC = ({arr, period=9, xx}) => {
  console.log('ROC', period)
  let results = Array(arr.length).fill(NaN)
  for (let i = period; i < arr.length; i++) {
    results[i] = (arr[i] - arr[i-period]) * 100 / arr[i-period]
  }
  return xx ? [toXY(results, xx)] : [results]
}

const BB = ({hists, period=20, factor=2, xx}) => {
  console.log('BB', period, factor)
  let bu = Array(hists.length).fill(NaN), bl = Array(hists.length).fill(NaN)
  // let arr = hists.map(x => ((x.high+x.low+x.close)/3))
  let arr = hists.map(x => x.close)
  let ma = SMA({arr, period})
  for (let i = period; i < hists.length; i++) {
    let stdDev = getStdDev(arr.slice(i-period, i))
    bu[i] = ma[i] + factor * stdDev
    bl[i] = ma[i] - factor * stdDev
  }
  return xx ? [toXY(ma, xx), toXY(bu, xx), toXY(bl, xx)] : [ma, bu, bl]
}

const ATR = ({hists: hh, period=14, xx}) => {
  console.log('ATR', period)
  let arr = Array(hh.length).fill(0)
  for (let i = 1; i < hh.length; i++) {
    arr[i] = Math.max(hh[i].high - hh[i].low, Math.abs(hh[i].high - hh[i-1].close), Math.abs(hh[i].low - hh[i-1].close))
  }
  let results = SMA({arr, period})
  return xx ? [toXY(results, xx)] : [results]
}

const MOMENTUM = ({arr, period=14, xx}) => {
  console.log('MOMENTUM', period)
  let results = Array(arr.length).fill(0)
  for (let i = period; i < arr.length; i++) {
    results[i] = arr[i] - arr[i-period]
  }
  return xx ? [toXY(results, xx)] : [results]
}

const STOCH = ({hists, kPeriod=14, dPeriod=3, ddPeriod=3, xx}) => {
  console.log('STOCH', kPeriod)
  let k = Array(hists.length).fill(0)
  for (let i = kPeriod-1; i < hists.length; i++) {
    let h = hists[i].high, l = hists[i].low
    for (let j = i-kPeriod+1; j < i; j++) {
      if (h < hists[j].high) h = hists[j].high
      if (l > hists[j].low) l = hists[j].low
    }
    k[i] = (hists[i].close - l) * 100 / (h - l)
  }
  let d = SMA({arr: k, period: dPeriod})
  let dd = SMA({arr: d, period: ddPeriod})
  return xx ? [toXY(k, xx), toXY(d, xx), toXY(dd, xx)] : [k, d, dd]
}

//------------------------------------------------------------------------------
const IKH = ({hists, shortPeriod=9, longPeriod=26, xx}) => {
  console.log('IKH', shortPeriod, longPeriod)
  let hlShort = getHL(hists, shortPeriod)
  let hlLong = getHL(hists, longPeriod)
  let spanA = getAvg(hlShort, hlLong, longPeriod)
  let spanB = getShift(getHL(hists, 2*longPeriod), longPeriod)
  let lag = getShift(hists.map(x => x.close), -longPeriod)
  return xx ? [toXY(hlShort, xx), toXY(hlLong, xx), toXY(spanA, xx), toXY(spanB, xx), toXY(lag, xx)] : [hlShort, hlLong, spanA, spanB, lag]
}

export const mathLib = {
  toXY, getSum, getMean, getStdDev, getHL, getAvg, getShift, getSlope,
  SMA, WMA, EMA, SMAs, WMAs, EMAs, 
  MACD, RSI, ROC, BB, ATR, MOMENTUM, STOCH, IKH
}