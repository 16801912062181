import React, { useState, useContext } from 'react'
import { Modal, Form, Row, Col, Alert } from 'react-bootstrap'
import { TrimTo, TextInput, IconTip, CancelButton, SubmitButton } from '../widgets'
import { GlobalContext } from '../helpers'
import { toast } from 'react-toastify'
import { apiPost, apiPut } from '../../libs/apiLib'
import { useNavigate } from 'react-router-dom'

export const EditAccount = ({ account }) => {
  const navigate = useNavigate()
  const { gState } = useContext(GlobalContext)
  const [state, setState] = useState({
    ...account, 
    error: ''
  })

  const validate = () => {
    return state.name
  }

  const onChange = ({ target }) => {
    let {name, type, value} = target
    if (type === 'number') value = +value
    setState({ ...state, [name]: value, error: '' });
  }

  const onChangeBalance = ({ target }) => {
    let {name, type, value} = target
    if (type === 'number') value = +value
    setState({ ...state, [name]: value, balUpdated: new Date(), error: '' });
  }

  const onSubmit = async () => {
    setState({...state, working: true})
    let {_id, name, type, ownerName, bankName, accountNo, mask, balance, refAmount, matureDate, remarks, description, status, createdAt = new Date()} = state
    let account = {name, type, ownerName, bankName, accountNo, mask, balance, refAmount, matureDate, remarks, description, status, createdAt, userId: gState.loginUser?._id}
    if (state.balUpdated) account.balUpdated = state.balUpdated
    let result = _id ? (await apiPut('account', _id, account)) : (await apiPost('account', account))
    if (result && !result.error) {
      toast('Account updated successfully')
      setState({...state, working: false})
      navigate(-1);
    }
    else {
      setState({...state, error: result?.error || 'Server error', working: false})
    }
  }

  const onClose = () => {
    navigate(-1)
  }

  return (
    <Modal show={true} size="lg">
      <Form>
        <Modal.Header>
          <Modal.Title>{account._id ? 'Edit' : 'Add'} Account</Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-3">
          {state.error && <Alert variant="danger"><TrimTo text={state.error} to={200} /></Alert>}

          <Row>
            <Col md={6}>
              <TextInput type="text" header="Name" name="name" value={state.name} onChange={onChange}/>
            </Col>
            <Col md={6}>
              <TextInput type="text" header="Type" name="type" value={state.type} onChange={onChange}/>
            </Col>
            <Col md={6}>
              <TextInput type="text" header="Owner name" name="ownerName" value={state.ownerName} onChange={onChange} />
            </Col>
            <Col md={6}>
              <TextInput type="text" header="Bank name" name="bankName" value={state.bankName} onChange={onChange} />
            </Col>
            <Col md={6}>
              <TextInput type="text" header="Account No" name="accountNo" value={state.accountNo} onChange={onChange} />
            </Col>
            <Col md={6}>
              <TextInput type="text" header="Mask" name="mask" value={state.mask} onChange={onChange} />
            </Col>
            <Col md={6}>
              <TextInput header="Balance" name="balance" type="number" value={state.balance} onChange={onChangeBalance} />
            </Col>
            <Col md={6}>
              <TextInput type="text" header={<>Ref amount <IconTip tooltip="This is the account standing as your reference to see its performance since then." /></>} name="refAmount" value={state.refAmount} onChange={onChange} />
            </Col>
            <Col md={6}>
              <TextInput type="text" header="Mature date" name="matureDate" value={state.matureDate} onChange={onChange} />
            </Col>
            <Col md={6}>
              <TextInput type="text" header="Remarks" name="remarks" value={state.remarks} onChange={onChange} />
            </Col>
            <Col md={6}>
              <TextInput type="text" header="Status" name="status" value={state.status} onChange={onChange} />
            </Col>
            <Col md={6}>
              <TextInput type="text" header="Description" name="description" value={state.description} onChange={onChange} />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <CancelButton onClick={onClose} />
          <SubmitButton disabled={!validate()} working={state.working} onClick={onSubmit} className="ms-2"/>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
