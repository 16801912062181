import React from 'react'
import { EditNews } from '.'

export const AddNewsPage = () => {
  const news = { 
    headline: '', 
    sourceUrl: '',
    symbols: '',
    remarks: '',
  }

  return (
    <>
      <EditNews news={news}/>
    </>
  );
}
