import { millisecondsToText } from './utilLib'
import { localAPIs } from './localAPIs'
import { cloudAPIs } from './cloudAPIs'
const config = process.env.REACT_APP_STAGE === "dev" ? {...cloudAPIs, ...localAPIs} : {...localAPIs, ...cloudAPIs};
// const config = {...cloudAPIs}

export const apiGetList = async (args) => {
    try {
        console.log(`apiGetList()...`, args)
        const startTime = Date.now()
        // const result = await API.get(apiName, `/api/list?args=${encodeURIComponent(JSON.stringify(args))}`)
        const result = await config.apiGetList(args)
        console.log(`apiGetList() success in ${millisecondsToText(Date.now() - startTime)}`, result)
        return result
    } catch (err) {
        console.log('apiGetList() error', err)
        // throw err
    }
}

export const apiGetOne = async (args) => {
    try {
        console.log(`apiGetOne()...`, args)
        const startTime = Date.now()
        // const result = await API.get(apiName, `/api/one?args=${encodeURIComponent(JSON.stringify(args))}`)
        const result = await config.apiGetOne(args)
        console.log(`apiGetOne() success in ${millisecondsToText(Date.now() - startTime)}`, result)
        return result
    } catch (err) {
        console.log('apiGetOne() error', err)
        // throw err
    }
}

export const apiGetDistinct = async (args) => {
    try {
        console.log(`apiGetDistinct()...`, args)
        const startTime = Date.now()
        // const result = await API.get(apiName, `/api/distinct?args=${encodeURIComponent(JSON.stringify(args))}`)
        const result = await config.apiGetDistinct(args)
        console.log(`apiGetDistinct() success in ${millisecondsToText(Date.now() - startTime)}`, result)
        return result
    } catch (err) {
        console.log('apiGetDistinct() error', err)
        // throw err
    }
}

export const apiPost = async (table, body) => {
    try {
        console.log(`apiPost()...`, table)
        const startTime = Date.now()
        // const result = await API.post(apiName, `/api/${table}`, { body })
        const result = await config.apiPost(table, body)
        console.log(`apiPost() success in ${millisecondsToText(Date.now() - startTime)}`, result)
        return result
    } catch (err) {
        console.log('apiPost() error', err)
        // throw err
    }
}

export const apiPut = async (table, id, body) => {
    try {
        console.log(`apiPut()...`, table, id)
        const startTime = Date.now()
        // const result = await API.put(apiName, `/api/${table}/${id}`, { body })
        const result = await config.apiPut(table, id, body)
        console.log(`apiPut() success in ${millisecondsToText(Date.now() - startTime)}`, result)
        return result
    } catch (err) {
        console.log('apiPut() error', err)
        // throw err
    }
}

export const apiDelete = async (table, id, args) => {
    try {
        // console.log(`apiDelete()...`, table, id)
        const startTime = Date.now()
        // const result = await API.del(apiName, `/api/${table}/${id}` + (args ? `?args=${encodeURIComponent(JSON.stringify(args))}` : ''))
        const result = await config.apiDelete(table, id, args)
        console.log(`apiDelete() success in ${millisecondsToText(Date.now() - startTime)}`, table, id, result)
        return result
    } catch (err) {
        console.log('apiDelete() error', err)
        // throw err
    }
}
