import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { EditNews } from '.'
import { Loading } from '../widgets'
import { apiGetOne } from '../../libs/apiLib'

export const EditNewsPage = () => {
  const { id: newsId } = useParams()
  const [state, setState] = useState({
    loading: true,
    news: null
  })

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchData = async () => {
    const news = await apiGetOne({ model: 'news', filter: { _id: newsId } })
    setState({ ...state, news, loading: false })
  }

  if (state.loading) return <Loading/>
  return (
    <>
      <EditNews news={state.news}/>
    </>
  );
}
