import { API } from 'aws-amplify'

const apiName = 'assetman'
const apiGetList = async (args) => {
    return await API.get(apiName, `/api/list?args=${encodeURIComponent(JSON.stringify(args))}`)
}

const apiGetOne = async (args) => {
    return await API.get(apiName, `/api/one?args=${encodeURIComponent(JSON.stringify(args))}`)
}

const apiGetDistinct = async (args) => {
    return await API.get(apiName, `/api/distinct?args=${encodeURIComponent(JSON.stringify(args))}`)
}

const apiPost = async (table, body) => {
    return await API.post(apiName, `/api/${table}`, { body })
}

const apiPut = async (table, id, body) => {
    return await API.put(apiName, `/api/${table}/${id}`, { body })
}

const apiDelete = async (table, id, args) => {
    return await API.del(apiName, `/api/${table}/${id}` + (args ? `?args=${encodeURIComponent(JSON.stringify(args))}` : ''))
}

export const cloudAPIs = {
    apiGetList, apiGetOne, apiGetDistinct, apiPost, apiPut, apiDelete
}