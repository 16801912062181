import { useState, useEffect, useContext } from 'react'
import { withAuthenticator } from '@aws-amplify/ui-react'
import { Container } from "react-bootstrap"
import { apiGetList, apiDelete } from '../../libs/apiLib'
import { Loading, Unauthorized } from '../widgets'
import { Accounts } from '.'
import { processAccounts } from '../../libs/dataLib'
import { toast } from 'react-toastify'
import { GlobalContext } from '../helpers'
import { cloneDeep } from 'lodash'
import { useParams } from 'react-router-dom'

const AccountsPage = () => {
    const { id: userId } = useParams()
    const { gState } = useContext(GlobalContext)
    const [state, setState] = useState({
        accounts: null
    })

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const fetchData = async () => {
        if (!gState.isAdmin() && gState.loginUser?._id !== userId) return
        const { items: accounts } = await apiGetList({
            model: 'account',
            filter: { userId },
            populates: [
                { path: 'holdings', populate: { path: 'company', select: 'price change yahooSymbol' } },
                { path: 'transactions', select: 'createdAt category amount', match: { createdAt: { $gt: '2021-07-11T00:00' }, category: { $in: ['Transfer', 'Paycheck', 'Expense'] } } },
                { path: 'transCount' }
            ]
        })
        setState({ ...state, accounts: processAccounts(cloneDeep(accounts)) })
    }

    const onDelete = async (account) => {
        let result = await apiDelete('account', account._id)
        console.log(result)
        toast(`Account '${account.name}' deleted successfully.`)
        await fetchData()
    }

    if (!gState.isAdmin() && gState.loginUser?._id !== userId) return <Unauthorized />
    if (!state.accounts) return <Loading />
    document.title = "Accounts - " + global.appName
    return (
        <Container fluid>
            <Accounts accounts={state.accounts} onRefresh={fetchData} onDelete={onDelete} />
        </Container>
    )
}

export default withAuthenticator(AccountsPage)
