import React, { useState } from 'react'
import { Modal, Form, Row, Col, Alert } from 'react-bootstrap'
import { TrimTo, TextInput, CancelButton, SubmitButton } from '../widgets'
import { toast } from 'react-toastify'
import { apiPost, apiPut } from '../../libs/apiLib'
import { useNavigate } from 'react-router-dom'

export const EditCompany = ({ company }) => {
  const navigate = useNavigate()
  const [state, setState] = useState({
    ...company, 
    error: ''
  })

  const validate = () => {
    return state.name
  }

  const onChange = ({ target }) => {
    let {name, type, value} = target
    if (type === 'number') value = +value
    setState({ ...state, [name]: value, error: '' });
  }

  const onSubmit = async () => {
    setState({...state, working: true})
    let {_id, symbol, yahooSymbol, name, price, change, changePercent, volume, divFreq, dayLow, dayHigh, yearLow, yearHigh, sector, industry, year, country, hq, buyPrice, remarks} = state
    let company = {symbol, yahooSymbol, name, price, change, changePercent, volume, divFreq, dayLow, dayHigh, yearLow, yearHigh, sector, industry, year, country, hq, buyPrice, remarks}
    if (!_id) company._id = symbol
    if (company.price) company.lastTraded = new Date().getTime()
    let result = _id ? (await apiPut('company', _id, company)) : (await apiPost('company', company))
    if (result && !result.error) {
      toast('Company updated successfully')
      setState({...state, working: false})
      navigate(-1);
    }
    else {
      setState({...state, error: result?.error || 'Server error', working: false})
    }
  }

  const onClose = () => {
    navigate(-1)
  }

  return (
    <Modal.Dialog size="lg">
      <Form>
        <Modal.Header>
          <Modal.Title>{company._id ? 'Edit' : 'Add'} Company</Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-3">
          {state.error && <Alert variant="danger"><TrimTo text={state.error} to={200} /></Alert>}

          <Row>
            <Col md={3}><TextInput type="text" header="Symbol" name="symbol" value={state.symbol} onChange={onChange}/></Col>
            <Col md={3}><TextInput type="text" header="Yahoo symbol" name="yahooSymbol" value={state.yahooSymbol} onChange={onChange}/></Col>
            <Col md={6}><TextInput type="text" header="Name" name="name" value={state.name} onChange={onChange}/></Col>
            <Col md={3}><TextInput type="text" header="Price" name="price" value={state.price} onChange={onChange} /></Col>
            <Col md={3}><TextInput type="text" header="Change" name="change" value={state.change} onChange={onChange} /></Col>
            <Col md={3}><TextInput type="text" header="Change %" name="changePercent" value={state.changePercent} onChange={onChange} /></Col>
            <Col md={3}><TextInput type="text" header="Volume" name="volume" value={state.volume} onChange={onChange} /></Col>
            <Col md={3}><TextInput type="text" header="Day low" name="dayLow" value={state.dayLow} onChange={onChange} /></Col>
            <Col md={3}><TextInput type="text" header="Day high" name="dayHigh" value={state.dayHigh} onChange={onChange} /></Col>
            <Col md={3}><TextInput type="text" header="Year low" name="yearLow" value={state.yearLow} onChange={onChange} /></Col>
            <Col md={3}><TextInput type="text" header="Year high" name="yearHigh" value={state.yearHigh} onChange={onChange} /></Col>
            <Col md={3}><TextInput type="text" header="Div freq" name="divFreq" value={state.divFreq} onChange={onChange} /></Col>
            <Col md={3}><TextInput type="text" header="Est year" name="year" value={state.year} onChange={onChange} /></Col>
            <Col md={3}><TextInput type="text" header="Country" name="country" value={state.country} onChange={onChange} /></Col>
            <Col md={3}><TextInput type="text" header="Headquarter" name="hq" value={state.hq} onChange={onChange} /></Col>
            <Col md={6}><TextInput type="text" header="Sector" name="sector" value={state.sector} onChange={onChange} /></Col>
            <Col md={6}><TextInput type="text" header="Industry" name="industry" value={state.industry} onChange={onChange} /></Col>
            <Col md={6}><TextInput type="text" header="Buy price" name="buyPrice" value={state.buyPrice} onChange={onChange} /></Col>
            <Col md={6}><TextInput type="text" header="Remarks" name="remarks" value={state.remarks} onChange={onChange} /></Col>
            {/* <Col md={12}><TextInput type="text" as="textarea" header="Description" name="description" value={state.description} onChange={onChange} /></Col> */}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <CancelButton onClick={onClose} />
          <SubmitButton disabled={!validate()} working={state.working} onClick={onSubmit} className="ms-2"/>
        </Modal.Footer>
      </Form>
    </Modal.Dialog>
  );
}
