import { useState, useEffect, useContext } from 'react'
import { withAuthenticator } from '@aws-amplify/ui-react'
import { useParams } from 'react-router-dom'
import { apiGetOne } from '../../libs/apiLib'
import { Loading, Unauthorized } from '../widgets'
import { Holdings } from '.'
import { Container } from 'react-bootstrap'
import { GlobalContext } from '../helpers'

const HoldingsPage = () => {
  const { id: accountId } = useParams()
  const { gState } = useContext(GlobalContext)
  const [state, setState] = useState({
    loading: true,
    account: null
  })

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchData = async () => {
    const account = await apiGetOne({
      model: 'account', filter: { _id: accountId },
      populates: [
        { path: 'holdings', populate: { path: 'company', select: { dailyQuotes: 0, yearlyQuotes: 0 } } },
        { path: 'orders', populate: { path: 'company', select: { dailyQuotes: 0, yearlyQuotes: 0 } } }
      ]
    })
    setState({ ...state, account, loading: false })
  }

  if (state.loading && !state.account) return <Loading />
  document.title = `Holdings [${state.account.name}] - ${global.appName}`
  if (!gState.isAdmin() && gState.loginUser?._id !== state.account.userId) return <Unauthorized />
  // console.log(state.account)
  return (
    <Container fluid>
      <Holdings account={state.account} onRefresh={fetchData} />
    </Container>
  )
}

export default withAuthenticator(HoldingsPage)
