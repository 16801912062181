import { Fragment, useState } from 'react'
import { FaChevronDown, FaChevronUp, FaCopy } from "react-icons/fa"
import { toCurrency } from "../../libs/utilLib"
import { LinkButton, Checkbox } from "../widgets"
import { Table } from 'react-bootstrap'
import { ReportTrades } from '.'
import { getTradesGrouppedBySymbol } from '../../libs/dataLib'
import { toast } from 'react-toastify'

export const ReportTradesByCategory = ({ header, items }) => {
    const [state, setState] = useState({
        expanded: false,
        summarized: false,
    })

    const onToggle = (e, name, item) => {
        e.preventDefault()
        if (item) {
            item[name] = !item[name]
            setState({...state})
        } else {
            setState({...state, [name]: !state[name]})
        }
    }

    const onCopy = (e, items1) => {
        e.preventDefault()
        let str = '#\tShares sold\tSymbol\tDate acquired\tDate sold\tProceeds\tCost basis\tCode\tAdjustment\tGain or loss\tBuy price\tSell price\tHold days\tGain %\tGain $\tBank\n'
        items.filter(x => !items1 || x === items1).forEach((x, i) => {
            x.items1?.forEach((y, j) => {
                str += `${j+1}\t${y.qty}\t${y.symbol}\t${y.dateAcquired}\t${y.dateSold}\t${y.proceeds}\t${y.cost}\t\t\t\t${y.buyPrice}\t${y.sellPrice}\t${y.holdDays}\t${y.winPercent}\t${y.winAmount}\t${x.accountName.replace(/ Trading| Hui/g, '')}\n`
            })
        })
        if (navigator.clipboard.writeText(str)) toast(`Table copied into clipboard.`)
    }

    if (!items?.length) return null
    items.forEach(x => {
        x.total = x.items?.reduce((t, y) => t += (y.winAmount||0), 0)
        x.items1 = x.summarized ? getTradesGrouppedBySymbol(x.items) : x.items
    })
    const total = items.reduce((t, x) => t += x.total, 0)
    return (
        <div>
            <div className="float-end bold">
                <span className={total < 0 ? 'red':''}>{toCurrency(total) || '$0'}</span>
            </div>
            <b className='blueviolet'>
                {header} [{items?.length}] <LinkButton icon={state.expanded ? <FaChevronUp /> : <FaChevronDown />} onClick={e => onToggle(e, 'expanded')}/>
                {state.expanded && <LinkButton icon={<FaCopy />} text="Copy all tables" onClick={e=>onCopy(e)} />}
            </b>
            {state.expanded && <div className="clear-both" style={{ maxWdith: "100%", overflow: 'auto' }}>
                <Table size="sm" hover className="nowrap f-sm">
                    <tbody>
                        {items?.map((x, i) => <Fragment key={i}>
                            <tr>
                                <td colSpan={5}>
                                    <span className="bold">{x.accountName}</span> [{x.items1.length}]
                                    <LinkButton icon={x.expanded ? <FaChevronUp /> : <FaChevronDown />} onClick={e => onToggle(e, 'expanded', x)} />
                                    {x.expanded && <LinkButton icon={<FaCopy />} text="Copy table" onClick={e=>onCopy(e, x.items1)} />}
                                </td>
                                <td colSpan={20} className='text-end'>
                                    {x.expanded && <Checkbox type="switch" label="Group by symbol" checked={x.summarized} onCheck={e => onToggle(e, 'summarized', x)}/>}
                                    <span className={`bold ${total < 0 ? 'red' : ''}`}>{toCurrency(x.total) || '$0'}</span>
                                </td>
                            </tr>
                            {x.expanded && <ReportTrades items={x.items1} summarizedView={x.summarized}/>}
                        </Fragment>)}
                    </tbody>
                </Table>
            </div>}
        </div>
    )
}