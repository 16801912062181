import React, { useEffect, useState } from 'react'
import { GlobalContext } from '.'
import { Auth } from 'aws-amplify'
import { apiPut, apiPost, apiGetOne, apiGetList } from '../../libs/apiLib'
import { InfoModal, InputModal } from '../widgets'
// import { fetch, Query_User } from '../../libs/gqlLib' 

export const GlobalState = ({ children }) => {
  const prefix = 'GlobalState'
  const [watchlists, setWatchlists] = useState()
  const [state, setState] = useState({
    // loading: false,
    // error: '',
    loginUser: JSON.parse(localStorage.getItem(`${prefix}_loginUser`)),
    // loginUser: null,
    // localUser: JSON.parse(localStorage.getItem('localUser')) || { id: 'localUser' },
    adminMode: JSON.parse(localStorage.getItem(`${prefix}_adminMode`)) || false,
    sidebarSize: JSON.parse(localStorage.getItem(`${prefix}_sidebarSize`)) || 'M',
    showNews: JSON.parse(localStorage.getItem(`${prefix}_showNews`) || true),
    showNotes: JSON.parse(localStorage.getItem(`${prefix}_showNotes`)),
    showMarketCharts: JSON.parse(localStorage.getItem(`${prefix}_showMarketCharts`)),
  })
  global.appName = "Pinvestool"

  useEffect(() => {
    (async () => {
      try {
        // setState({ ...state, loading: true })
        if (!state.loginUser) {
          const session = await Auth.currentSession();
          if (session && session.idToken) {
            const { sub: cognitoId, email, email_verified, 'cognito:groups': groups, 'cognito:username': username } = session.idToken.payload
            // console.log('GlobalState', cognitoId, email, email_verified, groups, username, session.idToken.payload)  // groups is array: [ "admin"]
            let loginUser = { cognitoId, email, email_verified, groups, username }
            // Check if user by this cognitoId is already in db, if already in db, get the _id and done, if not already in db, put it to create and get _id, and done
            let user = await apiGetOne({ model: 'user', filter: { cognitoId } })
            console.log(user)
            // localStorage.setItem('GlobalState_user', JSON.stringify(user))
            if (user?._id) {
              loginUser = user
            } else {
              user = await apiPost('user', loginUser)
              if (user?._id) loginUser = user
            }
            updateLoginUser(loginUser, true)
          }
        }
      }
      catch (e) {
        if (e !== 'No current user') {
          alert(JSON.stringify(e));
        }
      }
      // setState({ ...state, loading: false })
    })()
    // fetchWatchlists()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //--------------------------------------------------------------------------
  // const onState = (name, value) => {
  //   localStorage.setItem(`${prefix}_${name}`, JSON.stringify(value))
  //   setState({ ...state, [name]: value })
  // }
  const onToggle = (name) => {
    localStorage.setItem(`${prefix}_${name}`, !state[name])
    setState({ ...state, [name]: !state[name] })
  }
  const onSet = (name, value) => {
    localStorage.setItem(`${prefix}_${name}`, JSON.stringify(value))
    setState({ ...state, [name]: value })
  }
  //--------------------------------------------------------------------------
  const updateLoginUser = (loginUser, noDbUpdate) => {
    console.log('loginUser', loginUser)
    if (loginUser) localStorage.setItem(`${prefix}_loginUser`, JSON.stringify(loginUser))
    else localStorage.removeItem(`${prefix}_loginUser`)
    if (loginUser && !noDbUpdate) {
      if (loginUser._id) {
        apiPut('user', loginUser._id, loginUser).then((resp, error) => {
          if (error) console.error('updateLoginUser() post error', error)
          // else {
          //   localStorage.setItem(`${prefix}_loginUser`, JSON.stringify(resp))
          //   setState({...state, loginUser: resp})
          // }
        })
      }
    }
    setState({ ...state, loginUser })
  }
  //   const updateLoginUser = loginUser => {
  //     if (!loginUser) { // logout
  //       localStorage.removeItem('loginUser')
  //       setState({...state, loginUser: undefined})
  //     } else if (loginUser.cognitoId && (!state.loginUser || !state.loginUser.id || state.loginUser.cognitoId !== loginUser.cognitoId)) {
  //       fetch({query: Query_User, variables: { id: loginUser.cognitoId }}).then(res => {
  //         if (res.error || res.errors) console.log('res', res)
  //         else {
  //           loginUser = {...loginUser, ...res.data.user}
  //           localStorage.setItem('loginUser', JSON.stringify(loginUser))
  //           setState({...state, loginUser })
  //         }
  //       })
  //     }
  //   }

  // const fetchWatchlists = async () => {
  //   const { items: watchlists } = await apiGetList({ model: 'watchlist', filter: { userId: state.loginUser?._id }, sort: { name: 1 } })
  //   setWatchlists(watchlists)
  //   // setState({ ...state, watchlists })
  // }

  const getWatchlists = async () => {
    if (!watchlists) {
      const { items } = await apiGetList({ model: 'watchlist', filter: { userId: state.loginUser?._id }, sort: { name: 1 } })
      setWatchlists(items)
      return items
    } 
    return watchlists
  }

  const isFavor = (symbol, kind) => {
    return state.loginUser?.favTickers?.find(x => x.symbol === symbol && x.kind === kind)
  }

  const onFavor = (symbol, kind) => {
    let { loginUser } = state
    if (loginUser) {
      let ticker = loginUser.favTickers?.find(x => x.symbol === symbol)
      if (ticker) {
        if (ticker.kind === kind) loginUser.favTickers = loginUser.favTickers.filter(x => x.symbol !== symbol)
        else ticker.kind = kind
      } else loginUser.favTickers = [...loginUser.favTickers || [], { symbol, kind }]
      console.log(loginUser)
      updateLoginUser(loginUser)
    }
  }

  const isAdmin = (checkAdminMode = true) => {
    let isAdmin = state.loginUser?.groups?.includes('admin')
    if (checkAdminMode) isAdmin &= state.adminMode
    return !!isAdmin
  }

  const showInfoModal = (props) => {
    setState({ ...state, showInfoModal: true, modalProps: props })
  }

  const showInputModal = (props) => {
    setState({ ...state, showInputModal: true, modalProps: props })
  }
  //==========================================================================
  const getContext = () => ({
    gState: {
      ...state, isAdmin, onToggle, onSet, isFavor, onFavor, updateLoginUser,
      watchlists, getWatchlists,
      showInfoModal, showInputModal
    }
  })

  // if (state.loading) return null
  return (
    <GlobalContext.Provider value={getContext()}>
      {children}
      {state.showInfoModal && <InfoModal {...state.modalProps} onClose={e => onToggle('showInfoModal')} />}
      {state.showInputModal && <InputModal {...state.modalProps} onClose={e => onToggle('showInputModal')} />}
    </GlobalContext.Provider>
  )
}
