import { useState, useEffect } from 'react'
import { withAuthenticator } from '@aws-amplify/ui-react'
import { useParams, useNavigate } from 'react-router-dom'
import { Loading } from '../widgets'
import { apiGetOne } from '../../libs/apiLib'
import { ImportTransactions } from '.'

const ImportTransactionsPage = () => {
  const { id: accountId } = useParams()
  const navigate = useNavigate()
  const [state, setState] = useState({
    loading: true,
    account: null
  })

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchData = async () => {
    setState({ ...state, loading: true })
    const account = await apiGetOne({ model: 'account', filter: { _id: accountId }, populate: [{ path: 'transactions' }] })
    if (!account.transactions) account.transactions = []
    setState({ ...state, account, loading: false })
  }

  const onClose = () => {
    navigate(-1)
  }

  if (state.loading) return <Loading />
  return (
    <>
      <ImportTransactions account={state.account} onClose={onClose} />
    </>
  );
}

export default withAuthenticator(ImportTransactionsPage)
