import { orderBy, assign, omit, groupBy, cloneDeep } from 'lodash'
import { toCurrency, toPercent, toNum, toDate } from './utilLib'
import { differenceInDays } from 'date-fns'

export const prepTrans = (trans, quote, sellPref) => {
    if (!trans) return trans
    if (sellPref === 'none') {
        trans?.forEach(x => {
            x.accountName = x.account?.name
            x.hold = 0
        })
        return trans
    }
    let trans1 = cloneDeep(trans)
    trans1.filter(x => x.type === 'Bought').forEach(x => {
        x.hold = x.quantity
    })
    trans1.filter(x => x.type === 'Sold').forEach(x => {
        x.left = x.quantity
    })
    for (let i = trans1.length-1; i >= 0; i--){
        let sold = trans1[i]
        if (sold.type === 'Sold'){
            if (sellPref === 'lowestPriceFirst') {
                sellLowestPriceFirst(trans1, sold)
            } else if (sellPref === 'firstInFirstOut') {
                sellFirstInFirstOut(trans1, sold, i)
            }
        }
    }
    if (quote) {
        trans1.filter(x => x.symbol === quote.symbol && x.type === 'Bought' && x.hold > 0).forEach(x => {
            x.soldDate = 'now'
            x.soldPrice = quote.price
            x.holdDays = differenceInDays(new Date(), new Date(x.date))
            x.winAmount = x.hold * (quote.price - x.price)
            x.winPercent = -x.winAmount / x.amount
        })
    }
    return trans1

    function sellLowestPriceFirst(trans1, sold) {
        while (true) {
            let bought = orderBy(trans1.filter(x => x.symbol === sold.symbol && x.date <= sold.date && x.type === 'Bought' && x.hold > 0), ['price', 'date'])[0]
            if (!bought) break
            let j = trans1.findIndex(x => x.id === bought.id)
            if (bought.type === 'Bought' && bought.hold > 0){
                // If sold qty is less than bought, we have to split the bought
                if (-sold.left < bought.quantity){
                    let bought1 = cloneDeep(bought)
                    bought1.fee = 0
                    bought1.quantity += sold.left
                    bought1.amount = -bought1.quantity * bought1.price
                    bought1.hold = bought1.quantity
                    bought1.remarks = 'Split'
                    trans1.splice(j, 0, bought1)
                    bought.quantity = -sold.left
                    bought.amount += -bought1.amount
                    bought.remarks = 'Split'
                }
                bought.soldDate = sold.date
                bought.soldPrice = sold.price
                bought.holdDays = differenceInDays(new Date(sold.date), new Date(bought.date))
                bought.hold = 0
                bought.winAmount = bought.quantity * (bought.soldPrice - bought.price)
                bought.winPercent = -bought.winAmount / bought.amount
                sold.left += bought.quantity
                sold.winAmount = (sold.winAmount || 0) + bought.winAmount
                sold.cost = (sold.cost || 0) - bought.amount
                sold.winPercent = sold.winAmount / sold.cost
            }
            if (sold.left === 0) break
        }
    }

    function sellFirstInFirstOut(trans1, sold, i) {
        let j = trans1.length
        while (--j > i) {
            let bought = trans1[j]
            if (bought.type === 'Bought' && bought.hold > 0){
                // If sold qty is less than bought, we have to split the bought
                if (-sold.left < bought.quantity){
                    let bought1 = cloneDeep(bought)
                    bought1.fee = 0
                    bought1.quantity += sold.left
                    bought1.amount = -bought1.quantity * bought1.price
                    bought1.hold = bought1.quantity
                    bought1.remarks = 'Split'
                    trans1.splice(j++, 0, bought1)
                    bought.quantity = -sold.left
                    bought.amount += -bought1.amount
                    bought.remarks = 'Split'
                }
                bought.soldDate = sold.date
                bought.soldPrice = sold.price
                bought.holdDays = differenceInDays(new Date(sold.date), new Date(bought.date))
                bought.hold = 0
                bought.winAmount = bought.quantity * (bought.soldPrice - bought.price)
                bought.winPercent = -bought.winAmount / bought.amount
                sold.left += bought.quantity
                sold.winAmount = (sold.WinAmount || 0) + bought.winAmount
                sold.cost = (sold.cost || 0) - bought.amount
                sold.winPercent = sold.winAmount / sold.cost
            }
            if (sold.left === 0) break
        }
    }
}

export const prepTaxReport = (items, groupBy = 'bank') => {
    items?.forEach(x => {
        x.data.forEach(y => {
            y.totalShort = y.shortTerms?.reduce((t, z) => t += z.winAmount || 0, 0)
            y.totalLong = y.longTerms?.reduce((t, z) => t += z.winAmount || 0, 0)
            y.total = (y.totalInterest || 0) + (y.totalDividend||0) + (y.totalShort||0) + (y.totalLong||0)
            y.count = (y.totalInterest ? 1 : 0) + (y.totalDividend ? 1 : 0) + (y.shortTerms?.length ? 1 : 0) + (y.longTerms?.length ? 1 : 0)
        })
        x.totalInterest = x.data.reduce((t, y) => t += y.totalInterest || 0, 0)
        x.totalDividend = x.data.reduce((t, y) => t += y.totalDividend || 0, 0)
        x.totalShort = x.data.reduce((t, y) => t += y.totalShort || 0, 0)
        x.totalLong = x.data.reduce((t, y) => t += y.totalLong || 0, 0)
        x.total = x.totalInterest + x.totalDividend + x.totalShort + x.totalLong
    })
    return items
}

export const getTradesGrouppedBySymbol = (items) => {
    let grp = groupBy(items, x => (x.accountName||'') + '_' + x.symbol)
    return Object.keys(grp).map(k => {
        let items = grp[k]
        let item = {accountName: items[0].accountName, symbol: items[0].symbol}
        item.qty = items.reduce((t, x) => t += x.qty, 0)
        item.proceeds = items.reduce((t, x) => t += x.proceeds, 0)
        item.cost = items.reduce((t, x) => t += x.cost, 0)
        item.winAmount = items.reduce((t, x) => t += x.winAmount, 0)
        item.winPercent = item.winAmount / item.cost
        item.dateAcquired = getMinMax(items, 'dateAcquired')
        item.dateSold = getMinMax(items, 'dateSold')
        item.buyPrice = item.cost / item.qty
        item.sellPrice = item.proceeds / item.qty
        // item.buyPrice = items.reduce((t, x) => t += x.buyPrice, 0) / items.length
        // item.sellPrice = items.reduce((t, x) => t += x.sellPrice, 0) / items.length
        item.holdDays = items.reduce((t, x) => t += x.holdDays, 0) / items.length
        item.sumCount = items.length
        return item
    })    
    // -------------------------------------------------------
    function getMinMax(items, fieldName, toStr = true) {
        let res = items.reduce((t, x) => {
            if (!t.min || t.min > x[fieldName]) t.min = x[fieldName]
            if (!t.max || t.max < x[fieldName]) t.max = x[fieldName]
            return t
        }, {min: '', max: ''})
        if (toStr) return res.min + (res.min !== res.max ? ' - ' + res.max : '')
        else return res
    }
}

export const genAccountsReport = (items, summary) => {
    let html = `<p>Your total asset in ${items.length} accounts is <b>${toCurrency(summary.balance, 2, 2)}</b>, where cash is ${toCurrency(summary.cash, 2, 2)} (${toPercent(summary.cashPercent, 1)}) and security ${toCurrency(summary.security, 2, 2)}. </p>`
    html += `<p>Compared to the value of ${toCurrency(summary.refAmount, 2, 2)} on 2021-07-11 (when first snapshot was captured), your total asset has ${summary.refDiff > 0 ? 'increased' : 'descreased'} by ${toCurrency(summary.refDiff)}. This difference comes from paychecks of ${toCurrency(summary.paycheck, 2, 2)}, less ${toCurrency(summary.expense, 2, 2,)} expenses, plus ${toCurrency(summary.transfer)} external transfers, and an investment ${summary.refGain > 0 ? 'gain' : 'loss'} of ${toCurrency(summary.refGain, 2, 2)}. </p>`
    html += getDetailsTable(items, summary)
    html += `<div/><div style="font-weight: 'bold'">Accounts grouped by type</div><div/>`
    html += getAccountsSummaryByType(items)
    return html
}

const getDetailsTable = (items, summary) => {
    let html = `<table border="1" style="width: 100%; white-space: nowrap; font-size: 75%; border-collapse: collapse;">`
    html += `<thead><tr><th>#</th><th>Account name</th><th>Mask</th><th>Type</th><th>Balance</th><th>Cash $</th><th>Cash %</th><th>Security</th><th>Ref $</th><th>Ref Diff</th><th>Paycheck</th><th>Expense</th><th>Transfer</th><th>Win/Loss</th><th>Status</th><th>Updated</th></tr></thead><tbody>`
    html += '<tr style="font-weight: bold; background-color: #eee;">'
    html += `<td></td><td colspan="3">All accounts</td>`
    html += `<td>${toNum(summary.balance, 2, true)}</td>`
    html += `<td>${toNum(summary.cash, 2, true)}</td>`
    html += `<td>${toNum(summary.cashPercent * 100, 1, true)}</td>`
    html += `<td>${toNum(summary.security, 2, true)}</td>`
    html += `<td>${toNum(summary.refAmount, 2, true)}</td>`
    html += `<td>${toNum(summary.refDiff, 2, true)}</td>`
    html += `<td>${toNum(summary.paycheck, 2, true)}</td>`
    html += `<td>${toNum(summary.expense, 2, true)}</td>`
    html += `<td>${toNum(summary.transfer, 2, true)}</td>`
    html += `<td>${toNum(summary.refGain, 2, true)}</td>`
    html += `<td></td>`
    html += `</tr>`
    items.forEach((x, i) => {
        html += `<tr>`
        html += `<td>${i + 1}</td>`
        html += `<td>${x.name}</td>`
        html += `<td>${x.mask}</td>`
        html += `<td>${x.type}</td>`
        html += `<td>${toNum(x.balance, 2, true)}</td>`
        html += `<td>${toNum(x.cash, 2, true)}</td>`
        html += `<td>${toNum(x.cashPercent * 100, 1, true)}</td>`
        html += `<td>${toNum(x.security, 2, true)}</td>`
        html += `<td>${toNum(x.refAmount, 2, true)}</td>`
        html += `<td>${toNum(x.refDiff, 2, true)}</td>`
        html += `<td>${toNum(x.paycheck, 2, true)}</td>`
        html += `<td>${toNum(x.expense, 2, true)}</td>`
        html += `<td>${toNum(x.transfer, 2, true)}</td>`
        html += `<td>${toNum(x.refGain, 2, true)}</td>`
        html += `<td>${x.status || ''}</td>`
        html += `<td>${x.balUpdated ? toDate(x.balUpdated).toISOString().slice(2, 10) : ''}</td>`
        html += '</tr>'
    })
    html += `</tbody></table>`
    return html
}

const getAccountsSummaryByType = (items) => {
    let html = `<table border="1" style="width: 100%; white-space: nowrap; font-size: 75%; border-collapse: collapse;">`
    html += `<thead><tr><th>#</th><th>Account name</th><th>Type</th><th>Balance</th><th>Run Sum</th><th>Cash $</th><th>Cash %</th><th>Security</th><th>Updated</th></tr></thead><tbody>`
    var grp = groupBy(items.filter(x => !x.status?.includes('Closed')), x => x.type)
    Object.keys(grp).sort().forEach((k, i) => {
        let runSum = 0
        grp[k].forEach((y, j) => {
            runSum += y.balance || 0
            html += `<tr>`
            html += `<td>${i + 1}.${j + 1}</td>`
            html += `<td>${y.name}</td>`
            html += `<td>${y.type}</td>`
            html += `<td>${toNum(y.balance, 2, true)}</td>`
            html += `<td>${toNum(runSum, 2, true)}</td>`
            html += `<td>${toNum(y.cash, 2, true)}</td>`
            html += `<td>${toNum(y.cashPercent * 100, 1, true)}</td>`
            html += `<td>${toNum(y.security, 2, true)}</td>`
            html += `<td>${y.balUpdated ? toDate(y.balUpdated).toISOString().slice(2, 10) : ''}</td>`
            html += '</tr>'
        })
        html += `<tr><td col-span="12">&nbsp;</td></tr>`
    })
    html += `</tbody></table>`
    return html
}

// const usToHK = 7.83
export const processAccount = (x, hideMF, useAfterMarket) => {
    x.holdCount = x.dayChange = x.dayChangePercent = x.paid = x.quantity = x.avgPrice = x.marketValue = x.winAmount = 0
    if (hideMF) x.holdings = x.holdings?.filter(y => y.company?.kind !== 'MF')
    x.holdings?.forEach(y => {
        if (y.company) {
            // assign(y, pick(y.company, ['yahooSymbol', 'name', 'price', 'change', 'changePercent', 'lastTraded', 'prePrice', 'preChange', 'preChangePercent', 'preLastTraded', 'volume', 'open', 'dayHigh', 'dayLow', 'updatedAt', 'beta', 'pe', 'eps', 'targetPrice', 'yearHigh', 'yearLow', 'divYield', 'divDate', 'earnDate', 'tags']))
            assign(y, omit(y.company, ['_id', 'symbol', 'description', 'createdAt']))
            // if (y.symbol.endsWith('.HK')) {
            //     y.price = y.price / usToHK
            //     y.change = y.change / usToHK
            //     y.pPrice = y.pPrice / usToHK
            //     y.pChange = y.pChange / usToHK
            // }
            if (useAfterMarket && y.pPrice){
                [y.price, y.change, y.changePercent, y.lastTraded] = [y.pPrice, y.pChange, y.pChangePercent, y.pLastTraded]
            }
            y.marketValue = y.quantity * y.price
            y.paid = y.cost
            y.avgPrice = y.paid / y.quantity
            y.winAmount = y.quantity * (y.price - y.avgPrice)
            y.winPercent = y.winAmount / y.paid
            y.dayChange = y.quantity * (y.change||0)
            x.dayChange += y.dayChange || 0
            if (y.dayLow > 0 && y.dayLow < y.yearLow) y.yearLow = y.dayLow
            if (y.dayHigh > 0 && y.dayHigh > y.yearHigh) y.yearHigh = y.dayHigh
            if (y.yearLow && y.yearHigh && y.price){
                y.position = (y.price - y.yearLow) / (y.yearHigh - y.yearLow)
                y.downPercent = (y.price - y.yearHigh) / y.yearHigh
                y.upPercent = (y.price - y.yearLow) / y.yearLow
            }
            }
        x.paid += y.paid
        x.quantity += y.quantity
        x.avgPrice = x.paid / x.quantity
        x.marketValue += y.marketValue
        x.winAmount += y.winAmount
        // y.dayRange = `${toNumber(y.dayLow, 2)} - ${toNumber(y.dayHigh, 2)}`
        // y.yearRange = `${toNumber(y.yearLow, 2)} - ${toNumber(y.yearHigh, 2)}`
    })
    // x.clientName = x.client ? x.client.name : null
    x.holdCount = (x.holdings || []).length
    x.holds = orderBy(x.holdings, ['symbol']).map(y => y.symbol).join('+')
    // x.security = sumBy(x.holdings, y => y.marketValue)
    x.transfer = x.paycheck = x.expense = x.refGain = x.percentage = 0
    x.transactions?.forEach(y => {  // assuming transactions are already filtered e.g. after 2021-07-11
        y.accountName = x.name
        switch (y.category) {
            case 'Transfer': x.transfer += y.amount; break;
            case 'Paycheck': x.paycheck += y.amount; break;
            case 'Expense': x.expense += y.amount; break;
            // case 'Investment': x.refGain += y.amount; break;
            default: break;
        }
    })
    x.cash = x.cash || 0
    // processOrders(x.orders, x.cash, useAfterMarket)
    // x.security = x.security || 0
    x.security = x.marketValue = x.marketValue || 0
    x.totalValue = x.cash + x.marketValue
    x.cashPercent = Math.abs(x.totalValue) > 0.0001 ? (x.cash / x.totalValue) : 0
    x.refDiff = x.totalValue - (x.refAmount || 0)
    x.inactive = x.status && (x.status.includes('Close') || x.status.includes('Idle'))
    x.holdings?.forEach(y => {
        y.percentage = y.marketValue / x.marketValue
        x.percentage += y.percentage
    })
    x.dayChangePercent = !x.marketValue ? 0 : x.dayChange / x.marketValue
    // x.winAmount = x.marketValue - x.invested
    x.winPercent = !x.paid ? 0 : (x.winAmount / x.paid)
    x.refGain = x.refDiff - (x.paycheck || 0) - (x.expense || 0) - (x.transfer || 0)
    x.diff = x.totalValue - (x.invested || 0)
    x.pct = x.invested ? x.diff / x.invested : 0
    // x.transCount = (x.transactions || []).length
    return x
}

export const processAccounts = (accounts, hideMF) => {
    accounts.forEach(x => {
        processAccount(x, hideMF)
    })
    return accounts
}

export const getSummary = (accounts) => {
    let summary = { name: 'All accounts', balance: 0, cash: 0, security: 0, marketValue: 0, totalValue: 0, invested: 0, winAmount: 0, winPercent: 0, fee: 0, refAmount: 0, refDiff: 0, percentage: 0, dayChange: 0, holdCount: 0, transCount: 0, assets: 0, debts: 0, transfer: 0, paycheck: 0, expense: 0, refGain: 0 }
    accounts.forEach(x => {
        summary.balance += x.balance || 0
        summary.cash += x.cash || 0
        summary.security += x.security || 0
        summary.marketValue += x.marketValue || 0
        summary.totalValue += x.totalValue || 0
        summary.invested += x.invested || 0
        summary.refAmount += x.refAmount || 0
        summary.fee += x.fee || 0
        summary.dayChange += x.dayChange || 0
        summary.holdCount += x.holdCount || 0
        summary.winAmount += x.winAmount || 0
        summary.transCount += x.transCount || 0
        summary.transfer += x.transfer || 0
        summary.paycheck += x.paycheck || 0
        summary.expense += x.expense || 0
        summary.refGain += x.refGain || 0
    })
    summary.refDate = "2021-07-11"
    summary.dayChangePercent = summary.dayChange / summary.totalValue
    summary.cashPercent = summary.cash / summary.totalValue
    summary.winPercent = summary.invested ? (summary.winAmount / summary.invested) : 0
    summary.refDiff = summary.totalValue - summary.refAmount
    summary.diff = summary.totalValue - summary.invested
    summary.pct = summary.invested ? summary.diff / summary.invested : 0
    accounts.forEach(x => {
        x.percentage = x.totalValue / summary.totalValue
        summary.percentage += x.percentage
    })
    return summary
}

export const processOrders = (items, cash, useAfterMarket) => {
    orderBy(items, ['orderNumber'])?.forEach(x => {
        // assign(x, pick(x.company, ['yahooSymbol', 'name', 'price', 'change', 'changePercent', 'lastTraded', 'prePrice', 'preChange', 'preChangePercent', 'preLastTraded', 'volume', 'open', 'dayHigh', 'dayLow', 'updatedAt', 'yearHigh', 'yearLow', 'targetPrice', 'earnDate', 'divDate', 'divYield', 'tags']))
        assign(x, omit(x.company, ['_id', 'symbol', 'description', 'createdAt']))
        if (useAfterMarket && x.pPrice){
            [x.price, x.change, x.changePercent, x.lastTraded] = [x.pPrice, x.pChange, x.pChangePercent, x.pLastTraded]
        }
        if (x.orderPrice) {
            x.diffAmount = x.orderType === 'Buy' ? (x.price - x.orderPrice) : (x.orderPrice - x.price)
            x.diffPercent = x.diffAmount / x.price
        }
        if (x.yearLow && x.yearHigh && x.price){
            x.position = (x.price - x.yearLow) / (x.yearHigh - x.yearLow)
            x.downPercent = (x.yearHigh - x.price) / x.yearHigh
            x.upPercent = (x.price - x.yearLow) / x.yearLow
        }
        x.amount = - x.quantity * x.orderPrice
        // x.dayRange = `${toNumber(x.dayLow, 2)} - ${toNumber(x.dayHigh, 2)}`
        // x.yearRange = `${toNumber(x.yearLow, 2)} - ${toNumber(x.yearHigh, 2)}`
        if (x.amount < 0) {
            cash += x.amount
            x.cashLeft = cash
        }
    })
    return items
}

export const processQuotes = (items) => {
    items?.forEach(x => {
        if (x.yearLow && x.yearHigh && x.price){
            x.position = (x.price - x.yearLow) / (x.yearHigh - x.yearLow)
            x.downPercent = (x.yearHigh - x.price) / x.yearHigh
            x.upPercent = (x.price - x.yearLow) / x.yearLow
        }
        if (x.buyPrice && x.price && !x.diffAmount){
            x.diffAmount = x.price - x.buyPrice
            x.diffPercent = x.diffAmount / x.price
        }
    })
    return items
}
